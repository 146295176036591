import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import GroupVariable from "./GroupVariablesModal";
import { CustomButton } from "../../widgets";
import { deleteGroupVariable, getGroupVariable } from "./apiController";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";

export default function GroupVariableTable(props) {
    const [popupType, SetPopupType] = useState<any>("");
    const [selectedItem, SetSelectedItem] = useState<any>({});
    const [groupVariables, SetgroupVariables] = useState<any>([]);
    const { group, formData } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(props)
        fetchIntialData();
    }, [])

    /**
     * 
     */
    const fetchIntialData = async () => {
        try {
            dispatch(setLoading(true))
            let groupVaraibleResponse = await getGroupVariable(formData.id);
            console.log(groupVaraibleResponse)
            if (groupVaraibleResponse['data'].length > 0) {
                SetgroupVariables(groupVaraibleResponse['data'])
            }
            dispatch(setLoading(false))
        } catch (error: any) {
            dispatch(setLoading(false))
        }
    }
    /**
    * actions to table buttons
    * @param type 
    * @param data 
    */
    const onAction = async (type, data) => {
        switch (type) {
            case 4:
                SetPopupType("configure");
                break;
            case 2:
                console.log(data)
                SetSelectedItem(data)
                SetPopupType("configure");
                break;
            default:
                SetPopupType("")
        }
    }

    const onClose = () => {
        SetPopupType("");
        SetSelectedItem([]);
        fetchIntialData();
    }

    /**
     * delete group variable with formid and fieldid
     */
    const deleteVariable = async (data) => {
        console.log(data)
        let deleteResponse = await deleteGroupVariable(formData.id,data.id);
        if(deleteResponse){
            fetchIntialData();
        }
    }

    return (
        <>
            {
                popupType == "group" ?
                    <GroupVariable data={props.data} {...props} onClose={() => onClose()} />
                    :
                    <div className="group-variable-container">
                        <div className="header-container">
                            <Row className='m-0'>
                                <Col className='pl-3 mt-3'>
                                    <div className='form-name'>
                                        <img className='profile-image' alt='Profile' src={`${process.env.PUBLIC_URL}/images/logo.png`} />
                                        Grouping Variables
                                    </div>
                                </Col>
                                <Col className='pr-4 mt-4 text-right'>
                                    <i className='icon icon-close' onClick={() => props.onClose()} />
                                </Col>
                            </Row>
                        </div>
                        <div className="p-3 create-section">
                            <CustomButton className="create-btn" type={"primary"} text={"Create"} onClick={() => SetPopupType("group")} />
                            <div className="p-2 count">Variables | {groupVariables && groupVariables.length}</div>

                        </div>
                        <div className="variable-container">
                            {
                                groupVariables && groupVariables.map((items, index) => {
                                    return (<>
                                        <div className="p-3 variables">
                                            <Row>
                                                <Col md={3} xs={4}>
                                                    <div className=" ml-3 variable-text">
                                                        {items?.field_name}
                                                    </div>
                                                </Col>
                                                <Col md={7} xs={4}>
                                                    <div className=" ml-3 variable-expression">
                                                        {/* {
                                                            items && items.expression[0].conditions.map(valueitem => {
                                                                return <span style={{margin:"0px 5px"}}>{valueitem.value}</span>
                                                            })
                                                        }
                                                        {items?.field_name} */}
                                                    </div>
                                                </Col>
                                                <Col className='p-0' md={2}>
                                                    <div className='row m-0 justify-content-around'>
                                                        {/* <i className='icon icon-eye' onClick={() => onAction(2,items)} /> */}
                                                        <i className='icon icon-close' onClick={() => {deleteVariable(items)}} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>)
                                })
                            }
                        </div>
                    </div>
            }
        </>
    )
}