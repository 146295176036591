import { Menu } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { setLoading } from '../../store/slices/auth';
import { showToaster, toasterTypes } from '../../widgets';
import Tabs from '../sampleSize/Tabs';
import PeopleInvite from './PeopleInvite';
import PeopleInviteMobile from './PeopleInviteMobile';
import { ArrowLeftOutlined } from '@ant-design/icons';
// import { deleteAward, deleteEducation, deleteResearchProfile, deleteSkillSet, deleteWorkExperience, getProfileDetails } from './apiController';

export default function People(props) {
	const dispatch = useDispatch();
	const [profile, setprofile] = useState<any | null>(null);
	const [popupType, setpopupType] = useState('');
	const [selectedIndex, setselectedIndex] = useState(null);
	const [projectData, setprojectData] = useState<any | null>(null);
	const location = useLocation();
	const navigate = useNavigate();
	const tabData = [
		{
		  title: "Project Details",
		  id: 1,
		  tab: "project details"
		},
		{
		  title: "Study Design",
		  id: 2,
		  tab: "study design"
		},
		{
		  title: "Data Collection",
		  id: 3,
		  tab: "Data collection"
	
		},
		{
		  title: "Data Analysis",
		  id: 4,
		  tab: "Data analysis"
		},
		{
		  title: "People",
		  id: 5,
		  tab: "people"
		},
		{
		  title: "Registered Events",
		  id: 6,
		  tab: "register events"
		},
		{
		  title: "Mentors",
		  id: 7,
		  tab: "mentors"
		}
	   
	  ];


	useEffect(() => {
		let tempState: any = location.state;
		console.log(tempState)
        setprojectData(tempState);
	}, []);



	return (
		<>
		{window.innerWidth > 700 ?
		<div className='people-screen'>
			
			<>
			<Row className="page-header m-0">
            <Col className="p-0" md={12}>
              <ArrowLeftOutlined style={{color:"#fff",fontSize:"24px",margin:"0px 10px"}} onClick={() => navigate("/project")}/>
              <span className="project-title">
			  {projectData?.TopicDesc || projectData?.topicdesc}
              </span>
            </Col>
          </Row>
				<Tabs data={tabData} selectedTab={4} projectData={projectData} />
			</>
			
			<PeopleInvite/>
			

		</div>
		:
		<div className='people-screen-mob'>		
		   <PeopleInviteMobile data={location.state}/>
   		</div>
		}


		</>
	)
}
