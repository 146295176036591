import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { setLoading } from "../../store/slices/auth";
import { copyData } from "../../utils/Utils";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalPopup from "../../widgets/modal";
import CustomTable from "../../widgets/table";
import Tabs from "../sampleSize/Tabs";
import { deletForm, getFormData, getForms, getGroups, updateStrictMode } from "./apiController";
import CreateForm from "./CreateForm";
import ViewFormData from "./ViewFormData";
import { ArrowLeftOutlined, BackwardOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
import ModalComponent from "../common/Modal/modal";

export default function DataCollection(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [projectData, setprojectData] = useState<any | null>(null);
  const [userData, setuserData] = useState<any | null>(null);
  const [groups, setgroups] = useState<any | null>([]);
  const [selectedGroup, setselectedGroup] = useState<any | null>(null);
  const [forms, setforms] = useState<any | null>([]);
  const [action, setaction] = useState<any | "">("");
  const [lockData, SetLockData] = useState<any>({});
  const [formData, setformData] = useState<any | null>(null);
  const [formCount, SetFormCount] = useState(0);
  const [permissionParams, SetPermissionParams] = useState<any>({});
  const tabData = [
    {
      title: "Project Details",
      id: 1,
      tab: "project details"
    },
    {
      title: "Study Design",
      id: 2,
      tab: "study design"
    },
    {
      title: "Data Collection",
      id: 3,
      tab: "Data collection"

    },
    {
      title: "Data Analysis",
      id: 4,
      tab: "Data analysis"
    },
    {
      title: "People",
      id: 5,
      tab: "people"
    },
    {
      title: "Registered Events",
      id: 6,
      tab: "register events"
    },
    {
      title: "Mentors",
      id: 7,
      tab: "mentors"
    }
   
  ];
  const FormElements = {
    search: {
      name: "search",
      placeholder: "Search",
      label: "",
      type: FIELD_TYPES.SEARCH,
      isMandatory: false,
    },
     lockmode: {
        name: "lockmode",
        placeholder: "Please enter remarks",
        label: "Remarks to Unlock",
        type: FIELD_TYPES.TEXT_AREA,
        isMandatory: true,
    }
  };

  /**
   * argument permissions data
   * @param data 
   */
  const getPermissions = async (data) => {
    await data.permissions && data.permissions.map(items => {
      if (Object.keys(items)[0].replace(/([^\w]+|\s+)/g,'') == "Tabs"){
        items[Object.keys(items)[0]].map(item => {
          if (item.permissions.filter(itm => itm.title == "allowed" && itm.is_selected == true).length != 0) {
            if (item.action == "project details ,") {
              SetPermissionParams(prevState => ({ ...prevState, projectdetail: "allowed" }))
            } else if (item.action == "study design,") {
              SetPermissionParams(prevState => ({ ...prevState, studydesign: "allowed" }))
            } else if (item.action == "Data collection,") {
              SetPermissionParams(prevState => ({ ...prevState, datacollection: "allowed" }))
            } else if (item.action == "Data analysis,") {
              SetPermissionParams(prevState => ({ ...prevState, dataanalysis: "allowed" }))
            } else if (item.action == "mentors,") {
              SetPermissionParams(prevState => ({ ...prevState, mentors: "allowed" }))
            } else if (item.action == "register events") {
              SetPermissionParams(prevState => ({ ...prevState, registerevents: "allowed" }))
            }
          } else {
            if (item.action == "project details ,") {
              SetPermissionParams(prevState => ({ ...prevState, projectdetail: "forbidden" }))
            } else if (item.action == "study design,") {
              SetPermissionParams(prevState => ({ ...prevState, studydesign: "forbidden" }))
            } else if (item.action == "Data collection,") {
              SetPermissionParams(prevState => ({ ...prevState, datacollection: "forbidden" }))
            } else if (item.action == "Data analysis,") {
              SetPermissionParams(prevState => ({ ...prevState, dataanalysis: "forbidden" }))
            } else if (item.action == "mentors,") {
              SetPermissionParams(prevState => ({ ...prevState, mentors: "forbidden" }))
            } else if (item.action == "register events") {
              SetPermissionParams(prevState => ({ ...prevState, registerevents: "forbidden" }))
            }
          }

        })
      }
      if (Object.keys(items)[0] == "Forms") {

        items.Forms.map(item => {
          if (item.permissions.filter(itm => itm.title == "allowed" && itm.is_selected == true).length != 0) {
            if (item.action == "delete form") {
              SetPermissionParams(prevState => ({ ...prevState, delete: "allowed" }))
            } else if (item.action == "edit form"){
              SetPermissionParams(prevState => ({ ...prevState, edit: "allowed" }))
            } else if (item.action == "update form button") {
              SetPermissionParams(prevState => ({ ...prevState, update: "allowed" }))
            } else if (item.action == "view data"){
              SetPermissionParams(prevState => ({ ...prevState, view: "allowed" }))
            } else if (item.action == "Add data button"){
              SetPermissionParams(prevState => ({ ...prevState, adddata: "allowed" }))
            } else if (item.action == "create forms button") {
              SetPermissionParams(prevState => ({ ...prevState, createform: "allowed" }))
            } else if (item.action == "survey link button") {
              SetPermissionParams(prevState => ({ ...prevState, surveylink: "allowed" }))
            } else if (item.action == "Criteria") {
              SetPermissionParams(prevState => ({ ...prevState, criteria: "allowed" }))
            }
          } else {
            if (item.action == "delete form") {
              SetPermissionParams(prevState => ({ ...prevState, delete: "forbidden" }))
            } else if (item.action == "edit form") {
              SetPermissionParams(prevState => ({ ...prevState, edit: "forbidden" }))
            }else if (item.action == "update form button") {
              SetPermissionParams(prevState => ({ ...prevState, update: "forbidden" }))
            } else if (item.action == "view data") {
              SetPermissionParams(prevState => ({ ...prevState, view: "forbidden" }))
            } else if (item.action == "Add data button") {
              SetPermissionParams(prevState => ({ ...prevState, adddata: "forbidden" }))
            } else if (item.action == "create forms button") {
              SetPermissionParams(prevState => ({ ...prevState, createform: "forbidden" }))
            } else if (item.action == "survey link button") {
              SetPermissionParams(prevState => ({ ...prevState, surveylink: "forbidden" }))
            } else if (item.action == "Criteria") {
              SetPermissionParams(prevState => ({ ...prevState, criteria: "forbidden" }))
            }
          }

        })
      }

    })
  }

  useEffect(() => {
    console.log(Object.keys(permissionParams).length)
  }, [permissionParams])

  const [values, fields, handleChange] = useFieldItem(FormElements, {}, {});
  const columns = [
    {
      id: "form_name",
      label: "Form Name",
      accessor: "form_name",
      sort: "asc",
    },
    {
      id: "total_responses",
      label: "Total Records",
      Cell: ({ row, data }) => {
        const { original } = row;
        return (
          permissionParams.adddata == "allowed" || Object.keys(permissionParams).length == 0 ?
            <>
              <span>{original.total_responses}</span>
              {!original.survey_form && (
                <span className="add-icon" onClick={() => addData(original)}>
                  <i className="icon icon-add_circle"></i>
                  <span className="icon-text">Add</span>
                </span>
              )}
            </>
            : ""
        );
      },
    },
    {
      id: "variables",
      label: "Variables",
      width: "50",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return (
          console.log(original?.permission),
          permissionParams.update == "allowed" || Object.keys(permissionParams).length == 0 ?
          !original?.permission || original?.permission == "write" ?
            !original.locked ?
            <span className="add-icon" onClick={() => addVariable(original)}>
              <span className="icon-text">Update</span>
            </span>
            : "-"
            : ""
            :""
        );
      },
    },
    {
      id: "survey",
      label: "Survey",
      width: "50",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return permissionParams.surveylink == "allowed" || Object.keys(permissionParams).length == 0 ? original.survey_form ? (
          <i
            className="icon icon-copy"
            onClick={() => onAction(5, original)}
          ></i>
        ) : "" : (
          <>-</>
        );
      },
    },
    {
      id: "strict",
      label: "IsLocked",
      width: "50",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return (
          projectData?.strict_mode == true ?
          original.locked ?
            <i className="icon icon-lock" onClick={() => onAction("unlock", original)}></i>
            :
            <i className="icon icon-lock_open" onClick={() => onAction("lock", original)}></i>
            :"-"
          );
      },
    },
    {
      id: "view",
      label: "View",
      width: "50",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return (
          permissionParams.view == "allowed" || Object.keys(permissionParams).length == 0 ?
            <i
              className="icon icon-eye"
              onClick={() => onAction(1, original)}
            ></i>
            : ""

        );
      },
    },
    {
      id: "edit",
      label: "Edit",
      width: "50",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return (
          permissionParams.edit == "allowed" || Object.keys(permissionParams).length == 0 ?
            !original?.permission || original?.permission == "write" ?
              !original.locked ?
              <i
                className="icon icon-edit"
                onClick={() => onAction(2, original)}
              ></i>
              : "-"
              : ""
            : ""
        );
      },
    },
    {
      id: "delete",
      label: "Delete",
      width: "50",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return (
          permissionParams.delete == "allowed" || Object.keys(permissionParams).length == 0 ?
            !original?.permission || original?.permission == "write" ?
            !original.locked ?
              <i
                className="icon icon-delete"
                onClick={() => onAction(3, original)}
              ></i>
              :"-"
              : ""
            :
            ""
        );
      },
    },
  ];

  useEffect(() => {
    console.log(location.state)
    if (location.state) {
      let tempState: any = location.state;
      console.log(tempState)
      getPermissions(tempState);
      setprojectData(tempState);
      let temp: any = localStorage.getItem("coguide_user");
      if (temp) {
        temp = JSON.parse(temp);
        setuserData(temp);
        fetchGroups(tempState.TopicID || tempState.TopicCode);
      }
    } else {
      navigate("/project");
    }
  }, [location.state, navigate]);

  const addVariable = (data) => {
    data.formsList = forms;
    data.projectData = projectData;
    navigate("/addVariables", { state: { data, projectData } });
  };

  const addData = (data) => {
    data.formsList = forms;
    data.projectData = projectData;
    data.isPartialModify = false;
    navigate("/dataEntry", { state: { data, projectData } });
  };

  const fetchGroups = async (id) => {
    try {
      dispatch(setLoading(true));
      let tempGroups = await getGroups(id);
      tempGroups?.data.forEach((obj) => {
        obj.label = obj.name;
        obj.value = obj.id;
      });
      setgroups(tempGroups?.data || []);
      let groupIndex = tempGroups?.data.findIndex((obj) => obj.is_default);
      if (groupIndex >= 0) {
        fetchForms(groupIndex, tempGroups?.data[groupIndex].id);
      } else {
        fetchForms(0, tempGroups?.data[0].id);
      }
      dispatch(setLoading(false));
    } catch (e: any) {
      setgroups([]);
      dispatch(setLoading(false));
    }
  };

  const fetchForms = async (index, id = "") => {
    try {
      dispatch(setLoading(true));
      let tempForms = await getForms(id || groups[index].id);
      setforms(tempForms || []);
      setselectedGroup(index);
      dispatch(setLoading(false));
    } catch (e: any) {
      setgroups([]);
      dispatch(setLoading(false));
    }
  };

  const onAction = async (type, data: any = null) => {
    console.log(type, data);
    switch (type) {
      case 1:
        if (data?.total_responses === 0) {
          showToaster(toasterTypes.ERROR, "No Records Found");
        } else {
          dispatch(setLoading(true));
          let response = await getFormData(data?.id);
          setformData(response?.data);
          SetFormCount(data.total_responses);
          dispatch(setLoading(false));
          setaction(type);
        }
        break;
      case 2:
        try {
          dispatch(setLoading(true));
          let response = await getFormData(data?.id);
          setformData(response?.data);
          dispatch(setLoading(false));
          console.log(type)
          setaction(type);
        } catch (e: any) {
          setgroups([]);
          dispatch(setLoading(false));
        }
        break;
      case 3:
        setformData(data);
        setaction(type);
        break;
      case 5:
        copyData(
          `${window.location.href}?topicId=${projectData.TopicID || projectData.TopicCode
          }&formId=${data?.id}`,
          true
        );
        showToaster(toasterTypes.SUCCESS, "Link Copied Successfully");
        break;
      case "unlock":
        SetLockData(data)
        setaction(type);
        break;
      case "lock":
        updateStrictModeAction(data,"lock")
        break;
      case 8:
        navigate("/dataanalysis",{state:projectData})
        break; 
      default:
        setaction(type);
        break;
    }
  };

  /**
   * on From crete get all the forms list
   */
  const onFormCreation = () => {
    setaction("");
    setforms([]);
    fetchForms(selectedGroup);
  };

  //delete action
  const onDelete = async () => {
    try {
      dispatch(setLoading(true));
      let response = await deletForm(formData?.id);
      setaction("");
      fetchForms(selectedGroup);
      showToaster(toasterTypes.SUCCESS, response.message);
    } catch (e: any) {
      setgroups([]);
      dispatch(setLoading(false));
    }
  };

  //onclose
  const onCloseView = (type = "") => {
    if (type === "forms") {
      onFormCreation();
    }
    setaction("");
  };

  /**
   * unlock and lock
   * on lock update the status of form to strict mode true
   * on unlock update the status to false with remarks
   */
  const updateStrictModeAction = async(data,type) => {    
       try{
        dispatch(setLoading(true))
        let id = data["id"];
        let datavalue = {
          remark: type == "lock" ? " " : values.lockmode
        }
        let UpdateResponse = await updateStrictMode(id,datavalue);
        if(UpdateResponse){
          onFormCreation();
        }else{
          dispatch(setLoading(false))
        }
        dispatch(setLoading(false))
       }catch(e:any){
        throw e
        dispatch(setLoading(false))
       }
   
  }

  const createCriteria = () => {
    navigate("/createCriteria",{state:projectData});
  };

  return (
    <div className="data-collection">
      {(action === 4 || action === 2) && (
        <CreateForm
          {...props}
          formData={formData}
          isEdit={action === 2}
          projectData={projectData}
          groups={groups}
          group={groups[selectedGroup]}
          onClose={() => setaction("")}
          onFormCreation={() => onFormCreation()}
        />
      )}
      {
        action == "unlock" && (
          <ModalComponent
           width={window.innerWidth > 700 ? "500px" : "350px"}
            header={"Unlock the Form"}
           isOpen={true}
           onClose={() => setaction("")}
           customClass='invite-modal remark-modal'
           body={
            <>
            <div className="lock-remarks">
              <FieldItem
                  {...FormElements.lockmode}
                  value={values.lockmode}
                  values={values.lockmode}
                  onChange={(...e) => handleChange(FormElements.lockmode.name, ...e)}
                  touched={fields.lockmode && fields?.lockmode.hasError}
                  error={fields.lockmode && fields?.lockmode.errorMsg}
              />
              </div>
            </>
           }
           footer={
            <CustomButton text="Save" className="save-btn" onClick={() => updateStrictModeAction(lockData,"unlock")}></CustomButton>
           }
           />
        )
      }
      {action === 3 && (
        <ModalPopup
          modalType="delete"
          modalText="Are you sure, you want to delete?"
          actionText="This action cannot be undone"
          closeModal={() => {
            setaction("");
            setformData(null);
          }}
          onAction={() => onDelete()}
        />
      )}
      {action === 1 ? (
        <ViewFormData
          {...props}
          forms={forms}
          formData={formData}
          formPageCount={formCount}
          projectData={projectData}
          groups={groups}
          group={groups[selectedGroup]}
          selectedGroup={selectedGroup}
          onClose={(type = "") => onCloseView(type)}
        />
      ) : (
        <>
          <Row className="page-header m-0">
            <Col className="p-0" md={12}>
              <ArrowLeftOutlined style={{color:"#fff",fontSize:"24px",margin:"0px 10px"}} onClick={() => navigate("/project")}/>
              <span className="project-title">
               {projectData?.TopicDesc || projectData?.topicdesc}
              </span>
            </Col>
          </Row>
          <Tabs data={tabData} selectedTab={2} projectData={projectData} />

          {
            window.innerWidth < 750 ?
              <div className="m-3 groups-container">
                <div className="group-header row m-0">
                  <div className="col p-0 group-text">Groups</div>
                  <i className="icon icon-add_circle"></i>
                </div>
                <div className="p-2">
                  <FieldItem
                    {...FormElements.search}
                    value={values.search}
                    onChange={(...e) =>
                      handleChange(FormElements.search.name, ...e)
                    }
                    touched={fields.search && fields.search.hasError}
                    error={fields.search && fields.search.errorMsg}
                  />
                </div>
                <div className="group-scroll">
                  {groups.map((obj, index) => (
                    <div
                      className={`group-row ${selectedGroup === index ? "selected-group" : ""
                        }`}
                      key={index}
                      onClick={() => fetchForms(index)}
                    >
                      <div className="group-label">{obj.name}</div>
                    </div>
                  ))}
                </div>
              </div> : ""
          }
          <Row className="m-0 page-height">
            {
              window.innerWidth > 700 ?
                <Col className="p-0 col-md-3 groups-container">
                  <div className="group-header row m-0">
                    <div className="col p-0 group-text">Groups</div>
                    <i className="icon icon-add_circle"></i>
                  </div>
                  <div className="p-2">
                    <FieldItem
                      {...FormElements.search}
                      value={values.search}
                      onChange={(...e) =>
                        handleChange(FormElements.search.name, ...e)
                      }
                      touched={fields.search && fields.search.hasError}
                      error={fields.search && fields.search.errorMsg}
                    />
                  </div>
                  <div className="group-scroll">
                    {groups.map((obj, index) => (
                      <div
                        className={`group-row ${selectedGroup === index ? "selected-group" : ""
                          }`}
                        key={index}
                        onClick={() => fetchForms(index)}
                      >
                        <div className="group-label">{obj.name}</div>
                      </div>
                    ))}
                  </div>
                </Col>
                :
                ""
            }
            <Col className="p-0">
              <div className="p-3 d-flex justify-content-between ">
                <span className="font-weight-bold">Group1</span>
                {permissionParams.criteria == "allowed" || Object.keys(permissionParams).length == 0 ?
                  <Button onClick={createCriteria} style={{width:"fit-content"}}>
                    <span>Criterias:02</span>{" "}
                    <i className="icon icon-add_circle"></i>
                  </Button>
                  : ""
                }
              </div>
              <div className="p-3">
                {selectedGroup !== null && forms.length > 0 ? (
                  <CustomTable
                    header="Records"
                    data={forms}
                    tableProps={columns}
                    isSearch={true}
                    isFilter={true}
                    isSelect={false}
                    isSort={false}
                    isView={false}
                    isSummarise={true}
                    isCreate={permissionParams.createform == "allowed" || Object.keys(permissionParams).length == 0 ? true : false}
                    onAction={(type, data = null) => onAction(type, data)}
                  ></CustomTable>
                ) : (
                  <div className="group-center">
                    <div>
                      {selectedGroup !== null && forms.length === 0
                        ? "No Forms"
                        : "Select a group to view the forms"}
                    </div>
                    {selectedGroup !== null && forms.length === 0 && (
                      <CustomButton
                        className="mt-2"
                        type="alert-primary"
                        text="Create"
                        onClick={() => onAction(4)}
                      ></CustomButton>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
