import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

const PieChart = ({ data, chartStyle, width, height, title, titlecolor, legends }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    // D3 code to create the pie chart
    const radius = Math.min(width, height) / 2;

    const svg = d3
      .select(svgRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 3},${height / 2 + chartStyle.height})`);


    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const pie = d3.pie().value((d) => d.percentage);
    const data_ready = pie(data);
    const arc = d3.arc().innerRadius(0).outerRadius(radius);

    svg.selectAll('path')
      .data(data_ready)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d) => color(d.data.variable))
      .on("mouseenter",function(d){
        d3.select(d.currentTarget)
          .transition()
          .duration(100)
          .attr('opacity', 0.6);

        // Show tooltip on mouseover
        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip.html(`${d.target.__data__.data.variable}: ${d.target.__data__.value}`)
          .style('left', `${d.pageX/2}px`)
          .style('top', `${d.pageY}px`);
           
        })
        .on("mouseleave",function(d){
          d3.select(d.currentTarget)
            .transition()
            .duration(200)
            .attr('opacity',1)
             //remove tooltip
             tooltip.transition().duration(500).style('opacity', 0);

          });

           // Create tooltip
        const tooltip = d3
        .select(svgRef.current)
        .append('div')
        .style('position', 'absolute')
        .style('background', '#f4f4f4')
        .style('padding', '5px')
        .style('border', '1px solid #ccc')
        .style('border-radius', '5px')
        .style('color',"red")
        .style('opacity', 0);


    svg.selectAll('text')
      .data(data_ready)
      .enter()
      .append('text')
      .text((d) => `${d.data.percentage}`)
      .attr('transform', (d) => `translate(${d3.arc().innerRadius(0).outerRadius(radius).centroid(d)})`)
      .style('text-anchor', 'middle')
      .style('fill', chartStyle.color)
      .style('font-size', chartStyle.fontsize)
      .style('font-weight', chartStyle.fontweight);



    // Legends
    const legend = svg.selectAll('.legend')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'legend')
      .attr('transform', (d, i) => `translate(0,${i * 20})`);

    legend.append('rect')
      .attr('x', width / 4 + 40)
      .attr('y', -height/2)
      .attr('width', 14)
      .attr('height', 14)
      .style('fill', (d) => color(d.variable));

    legend.append('text')
      .attr('x', width / 4 + 60)
      .attr('y', -height/2 +5)
      .attr('dy', '.35em')
      .style('text-anchor', 'start')
      .style('font-size', legends.fontsize)
      .style('font-weight', legends.fontweight)
      .style('fill', legends.color)
      .text(d => d.variable);


    //title of the chart
    svg.append('text')
      .attr('class', 'title')
      .attr('x', width / 10 - 20)
      .attr('y', -height / 2 - 10)
      .attr('text-anchor', 'middle')
      .style('font-size', titlecolor.fontsize)
      .style('font-weight', titlecolor.fontweight)
      .style('fill', titlecolor.color)
      .text(title)

    return () => {
      d3.select(svgRef.current).selectAll('*').remove();
    };

  }, [data]);

  return (
    <>
      <div ref={svgRef}></div>
      {/* <PieLegends data={data} colorScale={d3.scaleOrdinal(d3.schemeCategory10)} /> */}
    </>
  );
};

PieChart.prototype = {
  data: PropTypes.oneOfType([PropTypes.array]),
  width: PropTypes.number,
  heigth: PropTypes.number,
  chartstyle: PropTypes.object,
  title: PropTypes.string,
  titlecolor: PropTypes.object,
  legends: PropTypes.object
}

/**
* from data replace label as variable and value as percentage
*/
PieChart.defaultProps = {
  data: [
    { label: 'Category A', value: 30 },
    { label: 'Category B', value: 50 },
    { label: 'Category C', value: 20 }
  ],
  width: "600",
  height: "400",
  chartStyle: { hover: "#ccc", fontsize: "14px", fontweight: "500", color: "#fff" },
  title: "Pie Chart",
  titlecolor: { fontsize: "24px", fontweight: "600", color: "#000" },
  legends: { fontsize: "24px", fontweight: "600", color: "#000" }
}

export default PieChart;
