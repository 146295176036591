export const roundOf = (number, roundof) => {
    return Number(Math.round(Number(number + "e+" + roundof)) + "e-" + roundof);
}

export const calcCCS_P = async (inputData) => {
    let finalArray = [];
    let P1;
    let P2;
    let OR;
    let R;
    let U;
    let V;
    inputData.forEach((data) => {
        let placeHolder = data.PlaceHolder;
        if (placeHolder === "${P1}") {
            P1 = parseFloat(data.Value);
        } else if (placeHolder === "${P2}") {
            P2 = parseFloat(data.Value);
        } else if (placeHolder === "${OR}") {
            OR = parseFloat(data.Value);
        } else if (placeHolder === "${R}") {
            R = parseFloat(data.Value);
        } else if (placeHolder === "${U}") {
            U = parseFloat(data.Value);
        } else if (placeHolder === "${V}") {
            V = parseFloat(data.Value);
        }
    });
    let SampleSize = await calcSampleSIzeCCS_P(P1, P2, OR, R, U, V);
    let SampleSizeWithR = SampleSize * R;

    SampleSize = roundOf(SampleSize, 0);
    finalArray.push({ 'PlaceHolder': "${SampleSize}", 'Value': SampleSize || 0 });
    let SampleSize5per = await calcPercentOfValue(5, SampleSize);
    SampleSize5per = roundOf(SampleSize5per, 0);
    finalArray.push({ 'PlaceHolder': "${5PerOfSampleSize}", 'Value': SampleSize5per || 0 });
    let spanderr = SampleSize + SampleSize5per;
    spanderr = roundOf(spanderr, 0);
    finalArray.push({ 'PlaceHolder': "${SampleSizeSum}", 'Value': spanderr || 0 });

    SampleSizeWithR = roundOf(SampleSizeWithR, 0);
    finalArray.push({ 'PlaceHolder': "${SampleSizeWithR}", 'Value': SampleSizeWithR || 0 });
    let SampleSizeWithR5per = await calcPercentOfValue(5, SampleSizeWithR);
    SampleSizeWithR5per = roundOf(SampleSizeWithR5per, 0);
    finalArray.push({ 'PlaceHolder': "${SampleSizeWithR5per}", 'Value': SampleSizeWithR5per || 0 });

    let SampleSizeWithRSum = SampleSizeWithR + SampleSizeWithR5per;
    SampleSizeWithRSum = roundOf(SampleSizeWithRSum, 0);
    finalArray.push({ 'PlaceHolder': "${SampleSizeWithRSum}", 'Value': SampleSizeWithRSum || 0 });
    return finalArray;
}

export const calcDCSS_P = async (inputData) => {
    let finalArray = [];
    let N;
    let Z;
    let ExpProportion;
    let D;
    inputData.forEach((data) => {
        let placeHolder = data.PlaceHolder;
        if (placeHolder === "${N}") {
            N = parseFloat(data.Value);
        } else if (placeHolder === "${Z}") {
            Z = parseFloat(data.Value);
        } else if (placeHolder === "${ExpProportion}") {
            ExpProportion = parseFloat(data.Value);
        } else if (placeHolder === "${D}") {
            D = parseFloat(data.Value);
        }
    });
    let D_P = await calcPercent(D);
    finalArray.push({ 'PlaceHolder': "${D_P}", 'Value': D_P || 0 });
    let ExpProportion_P = await calcPercent(ExpProportion);
    finalArray.push({ 'PlaceHolder': "${ExpProportion_P}", 'Value': ExpProportion_P || 0 });
    let SampleSize = await calcSampleSIzeDCSS_P(N, Z, ExpProportion_P, D_P);
    SampleSize = roundOf(SampleSize, 0);
    finalArray.push({ 'PlaceHolder': "${SampleSize}", 'Value': SampleSize || 0 });
    let SampleSize5per = await calcPercentOfValue(5, SampleSize);
    SampleSize5per = roundOf(SampleSize5per, 0);
    finalArray.push({ 'PlaceHolder': "${5PerOfSampleSize}", 'Value': SampleSize5per || 0 });
    let spanderr = SampleSize + SampleSize5per;
    spanderr = roundOf(spanderr, 0);
    finalArray.push({ 'PlaceHolder': "${sunSampleSizeSampleSizef}", 'Value': spanderr || 0 });
    return finalArray;
}

export const calcDCSS1_P = async (inputData) => {
    let finalArray = [];
    let N;
    let Z;
    let ExpProportion;
    let D;
    inputData.forEach((data) => {
        let placeHolder = data.PlaceHolder;
        if (placeHolder === "${N}") {
            N = parseFloat(data.Value);
        } else if (placeHolder === "${Z}") {
            Z = parseFloat(data.Value);
        } else if (placeHolder === "${ExpProportion}") {
            ExpProportion = parseFloat(data.Value);
        } else if (placeHolder === "${D}") {
            D = parseFloat(data.Value);
        }
    });
    let D_P = await calcPercent(D);
    finalArray.push({ 'PlaceHolder': "${D_P}", 'Value': D_P || 0 });
    let ExpProportion_P = await calcPercent(ExpProportion);
    finalArray.push({ 'PlaceHolder': "${ExpProportion_P}", 'Value': ExpProportion_P || 0 });
    
    let SampleSize = await calcSampleSIzeDCSS1_P(Z, ExpProportion_P, D_P);
    SampleSize = roundOf(SampleSize, 0);
    finalArray.push({ 'PlaceHolder': "${SampleSize}", 'Value': SampleSize || 0 });
    let SampleSize5per = await calcPercentOfValue(5, SampleSize);
    SampleSize5per = roundOf(SampleSize5per, 0);
    finalArray.push({ 'PlaceHolder': "${5PerOfSampleSize}", 'Value': SampleSize5per || 0 });
    let spanderr = SampleSize + SampleSize5per;
    spanderr = roundOf(spanderr, 0);
    finalArray.push({ 'PlaceHolder': "${sunSampleSizeSampleSizef}", 'Value': spanderr || 0 });
    //console.log(finalArray)
    return finalArray;
}

export const calcICSCBA_P = async (inputData) => {
    let finalArray = [];
    let U;
    let V;
    let PiA;
    let PiB;
    inputData.forEach((data) => {
        let placeHolder = data.PlaceHolder;
        if (placeHolder === "${U}") {
            U = parseFloat(data.Value);
        } else if (placeHolder === "${V}") {
            V = parseFloat(data.Value);
        } else if (placeHolder === "${PiA}") {
            PiA = parseFloat(data.Value);
        } else if (placeHolder === "${PiB}") {
            PiB = parseFloat(data.Value);
        }
    });
    let PiA_P = await calcPercent(PiA);
    finalArray.push({ 'PlaceHolder': "${PiA_P}", 'Value': PiA_P || 0 });
    let PiB_P = await calcPercent(PiB);
    finalArray.push({ 'PlaceHolder': "${PiB_P}", 'Value': PiB_P || 0 });
    let SampleSize = await calcSampleSIzeICSCBA_P(U, V, PiA_P, PiB_P);
    SampleSize = roundOf(SampleSize, 0);
    finalArray.push({ 'PlaceHolder': "${SampleSize}", 'Value': SampleSize || 0 });
    let SampleSize5per = await calcPercentOfValue(5, SampleSize);
    SampleSize5per = roundOf(SampleSize5per, 0);
    finalArray.push({ 'PlaceHolder': "${5PerOfSampleSize}", 'Value': SampleSize5per || 0 });
    let spanderr = SampleSize + SampleSize5per;
    spanderr = roundOf(spanderr, 0);
    finalArray.push({ 'PlaceHolder': "${sunSampleSizeSampleSizef}", 'Value': spanderr || 0 });
    return finalArray;
}

export const calcICSCBA_N = async (inputData) => {
    let finalArray = [];
    let U;
    let V;
    let MeanDiff;
    let STDDiff;
    inputData.forEach(async (data) => {
        let placeHolder = data.PlaceHolder;
        if (placeHolder === "${U}") {
            U = parseFloat(data.Value);
        } else if (placeHolder === "${V}") {
            V = parseFloat(data.Value);
        } else if (placeHolder === "${MeanDiff}") {
            MeanDiff = parseFloat(data.Value);
        } else if (placeHolder === "${STDDiff}") {
            STDDiff = parseFloat(data.Value);
        }
    });
    let SampleSize = await calcSampleSIzeICSCBA_N(U, V, MeanDiff, STDDiff);
    SampleSize = roundOf(SampleSize, 0);
    finalArray.push({ 'PlaceHolder': "${SampleSize}", 'Value': SampleSize || 0 });
    let SampleSize5per = await calcPercentOfValue(5, SampleSize);
    SampleSize5per = roundOf(SampleSize5per, 0);
    finalArray.push({ 'PlaceHolder': "${5PerOfSampleSize}", 'Value': SampleSize5per || 0 });
    let spanderr = SampleSize + SampleSize5per;
    spanderr = roundOf(spanderr, 0);
    finalArray.push({ 'PlaceHolder': "${sunSampleSizeSampleSizef}", 'Value': spanderr || 0 });
    return finalArray;
}

export const calcRCTNRCT_P = async (inputData) => {
    let finalArray = [];
    let U;
    let V;
    let Pi1;
    let Pi0;
    // console.log(inputData)

    inputData.forEach(async (data) => {
        // console.log(data)
        let placeHolder = data.PlaceHolder;
        if (placeHolder === "${U}") {
            U = parseFloat(data.Value);
        } else if (placeHolder === "${V}") {
            V = parseFloat(data.Value);
        } else if (placeHolder === "${PropotionOutcomIntervention1}") {
            Pi1 = parseFloat(data.Value);

            Pi1 =  await calcPercent(Pi1);
        } else if (placeHolder === "${PropotionOutcomIntervention2}") {
            Pi0 = parseFloat(data.Value);
            Pi0 = await calcPercent(Pi0);
        }
    });
    let SampleSize =  await calcSampleSIzeRCTNRCT_P(U, V, Pi1, Pi0);
    SampleSize = roundOf(SampleSize, 0);
    finalArray.push({ 'PlaceHolder': "${SampleSize}", 'Value': SampleSize || 0 });
    let SampleSize5per = await calcPercentOfValue(5, SampleSize);
    SampleSize5per = roundOf(SampleSize5per, 0);
    finalArray.push({ 'PlaceHolder': "${5PerOfSampleSize}", 'Value': SampleSize5per || 0 });
    let spanderr = SampleSize + SampleSize5per;
    spanderr = roundOf(spanderr, 0);
    finalArray.push({ 'PlaceHolder': "${sunSampleSizeSampleSizef}", 'Value': spanderr || 0 });
    //console.log(finalArray)

    return finalArray;
}

export const calcRCTNRCT_N = async (inputData) => {
    let finalArray = [];
    let U;
    let V;
    let SDOfOutcome;
    let SDOfControl;
    let MeanOfOutcome;
    let MeanOfControl;
    inputData.forEach((data) => {
        let placeHolder = data.PlaceHolder;
        if (placeHolder === "${U}") {
            U = parseFloat(data.Value);
        } else if (placeHolder === "${V}") {
            V = parseFloat(data.Value);
        } else if (placeHolder === "${SDOfOutcome}") {
            SDOfOutcome = parseFloat(data.Value);
        } else if (placeHolder === "${SDOfControl}") {
            SDOfControl = parseFloat(data.Value);
        } else if (placeHolder === "${MeanOfOutcome}") {
            MeanOfOutcome = parseFloat(data.Value);
        } else if (placeHolder === "${MeanOfControl}") {
            MeanOfControl = parseFloat(data.Value);
        }
    });
    let SampleSize = await calcSampleSIzeRCTNRCT_N(U, V, SDOfControl, SDOfOutcome, MeanOfControl, MeanOfOutcome);
    SampleSize = roundOf(SampleSize, 0);
    finalArray.push({ 'PlaceHolder': "${SampleSize}", 'Value': SampleSize || 0 });
    let SampleSize5per = await calcPercentOfValue(5, SampleSize);
    SampleSize5per = roundOf(SampleSize5per, 0);
    finalArray.push({ 'PlaceHolder': "${5PerOfSampleSize}", 'Value': SampleSize5per || 0 });
    let spanderr = SampleSize + SampleSize5per;
    spanderr = roundOf(spanderr, 0);
    finalArray.push({ 'PlaceHolder': "${sunSampleSizeSampleSizef}", 'Value': spanderr || 0 });
    return finalArray;
}

export const calcSampleSizeForExpectedSensitivity = async (inputData) => {
    let finalArray = [];
    let SN;
    let P;
    let SNP;
    let SPP, SP;
    let SN100, SNP100;
    let P100;
    inputData.forEach(async (data) => {
        let placeHolder = data.PlaceHolder;
        console.log(placeHolder + ":::" + data.Value);
        if (placeHolder === "${SN}") {
            SN = parseFloat(data.Value);
        } else if (placeHolder === "${P}") {
            P = parseFloat(data.Value);
        } else if (placeHolder === "${SNP}") {
            SNP = parseFloat(data.Value);
        } else if (placeHolder === "${SPP}") {
            SPP = parseFloat(data.Value);
        } else if (placeHolder === "${SP}") {
            SP = parseFloat(data.Value);
        }

    });
    SN100 = await calcPercent(SN);

    finalArray.push({ 'PlaceHolder': "${SN100}", 'Value': SN100 || 0 });

    P100 = await calcPercent(P);
    P100 = roundOf(P100, 2);
    finalArray.push({ 'PlaceHolder': "${P100}", 'Value': P100 || 0 });
    SNP100 = await calcPercent(SNP);
    console.log("SNP100",SNP100)
    finalArray.push({ 'PlaceHolder': "${SNP100}", 'Value': SNP100 || 0 });
    let SampleSize = await calcSampleSIze(1.96, SN100, SNP100, P100);
    SampleSize = roundOf(SampleSize, 0);
    console.log(SampleSize)

    finalArray.push({ 'PlaceHolder': "${SampleSize}", 'Value': SampleSize || 0 });
    let SPP100 = await calcPercent(SPP);
    finalArray.push({ 'PlaceHolder': "${SPP100}", 'Value': SPP100 || 0 });
    let SP100 = await calcPercent(SP);
    finalArray.push({ 'PlaceHolder': "${SP100}", 'Value': SP100 || 0 });
    let SampleSizef = await calcSampleSIzeSp(1.96, SP100, SPP100, P100);
    SampleSizef = roundOf(SampleSizef, 0);
    finalArray.push({ 'PlaceHolder': "${SampleSizef}", 'Value': SampleSizef || 0 });
    let GTSampleSize = SampleSize > SampleSizef ? SampleSize : SampleSizef;
    GTSampleSize = roundOf(GTSampleSize, 0);
    finalArray.push({ 'PlaceHolder': "${GTSampleSize}", 'Value': GTSampleSize || 0 });
    let SampleSize5per = await calcPercentOfValue(5, GTSampleSize);
    SampleSize5per = roundOf(SampleSize5per, 0);
    finalArray.push({ 'PlaceHolder': "${5PerOfSampleSize}", 'Value': SampleSize5per || 0 });
    let spanderr = GTSampleSize + SampleSize5per;
    spanderr = roundOf(spanderr, 0);
    finalArray.push({ 'PlaceHolder': "${sunSampleSizeSampleSizef}", 'Value': spanderr || 0 });
    return finalArray;
}

export const calcVariance = (P) => {
    return P * (1 - P);
}

export const calcPercent = async (P) => {
    return await P / 100;
}

export const calcPercentOfValue = async (percet, value) => {
    return await value * percet / 100;
}

export const  calcSampleSIze = async (Z, P, D, Prev) => {
    console.log("Z=" + Z + "::P=" + P + "::D=" + D + "::Prev=" + Prev);
    let smaplesize = 0.00;
    let varience = await calcVariance(P);
    console.log("varience=" + varience);
    let div = D * D * Prev;
    console.log("div=" + div);
    smaplesize = Z * Z * varience;
    console.log("smaplesize=" + smaplesize);
    smaplesize = smaplesize / div;
    console.log("smaplesize=" + smaplesize);
    smaplesize = roundOf(smaplesize, 2);
    return smaplesize;
}

export const calcSampleSIzeSp = async(Z, P, D, Prev) => {
    console.log("Z=" + Z + "::P=" + P + "::D=" + D + "::Prev=" + Prev);
    let smaplesize = 0.00;
    let varience = await calcVariance(P);
    console.log("varience=" + varience);
    let devPrev = 1 - Prev;
    let div = D * D * devPrev;
    console.log("div=" + div);
    smaplesize = Z * Z * varience;
    console.log("smaplesize=" + smaplesize);
    smaplesize = smaplesize / div;
    console.log("smaplesize=" + smaplesize);
    smaplesize = roundOf(smaplesize, 2);
    return smaplesize;

}

export const calcSampleSIzeRCTNRCT_N = (U, V, sd0, sd1, me0, me1) => {
    let smaplesize = 0.00;
    console.log("U=" + U + "::V=" + V + "::sd0=" + sd0 + "::sd1=" + sd1 + "::me0=" + me0 + "::me1=" + me1);
    let UplsV = U + V;
    console.log("UplsV=" + UplsV);
    let meanDiff = me1 - me0;
    console.log("meanDiff=" + meanDiff);
    let meanDiffSqr = meanDiff * meanDiff;
    console.log("meanDiffSqr=" + meanDiffSqr);
    let sd1aq = sd1 * sd1;
    console.log("sd1aq=" + sd1aq);
    let sd0aq = sd0 * sd0;
    console.log("sd0aq=" + sd0aq);
    let sdplus = sd1aq + sd0aq;
    console.log("sdplus=" + sdplus);
    smaplesize = UplsV * UplsV;
    console.log("smaplesize=" + smaplesize);
    smaplesize = smaplesize * sdplus;
    console.log("smaplesize=" + smaplesize);
    smaplesize = smaplesize / meanDiffSqr;
    console.log("smaplesize=" + smaplesize);
    return smaplesize;
}

export const calcSampleSIzeRCTNRCT_P = async (U, V, Pi1, Pi0) => {
    let smaplesize = 0.00;
    console.log("U=" + U + ":::V=" + V + "::Pi1" + Pi1 + "::Pi0=" + Pi0);
    let PiBar = Pi0 + Pi1;
    PiBar = PiBar / 2;
    console.log("PiBar=" + PiBar);
    let Pi0Varient = await calcVariance(Pi0);
    console.log("Pi0Varient=" + Pi0Varient);
    let Pi1Varient = await calcVariance(Pi1);
    console.log("Pi1Varient=" + Pi1Varient);
    let PiBarVarient = await calcVariance(PiBar);
    console.log("PiBarVarient=" + PiBarVarient);
    PiBarVarient = PiBarVarient * 2;
    console.log("PiBarVarient=" + PiBarVarient);
    let PiPls = Pi0Varient + Pi1Varient;
    console.log("PiPls=" + PiPls);
    let nthPiPls = await nthroot(PiPls, 2);
    nthPiPls = nthPiPls * U;
    console.log(U + "nthPiPls=" + nthPiPls);
    let nthPiBarVarient = await nthroot(PiBarVarient, 2);
    nthPiBarVarient = nthPiBarVarient * V;
    console.log(V + "nthPiPls=" + nthPiBarVarient);
    smaplesize = nthPiPls + nthPiBarVarient;
    console.log("tomCalc=" + smaplesize);
    smaplesize = smaplesize * smaplesize;
    console.log("tomCalcsqr=" + smaplesize);
    let Pi0minsPi1 = Pi0 - Pi1;
    console.log("lowCalc=" + Pi0minsPi1);
    Pi0minsPi1 = Pi0minsPi1 * Pi0minsPi1;
    console.log("lowCalcsqr=" + Pi0minsPi1);
    smaplesize = smaplesize / Pi0minsPi1;
    console.log("SampleSize=" + smaplesize);
    return smaplesize;
}

export const calcSampleSIzeICSCBA_N = (U, V, MeanDiff, STDDiff) => {
    let smaplesize = 0.00;
    let u2div = U * U / 2;
    let vplsv = U + V;
    vplsv = vplsv * vplsv;
    vplsv = 2 * vplsv;
    let meansdev = MeanDiff / STDDiff;
    meansdev = meansdev * meansdev;
    smaplesize = vplsv / meansdev;
    smaplesize = smaplesize + u2div

    return smaplesize;
}

export const calcVarience = (P1, P2) => {
    return P1 * (1 - P2);
}

export const calcSampleSIzeICSCBA_P = async (U, V, PiA, PiB) => {
    let smaplesize = 0.00;
    let PiAB = await calcVarience(PiA, PiB);
    console.log("PiAB:" + PiAB);
    let PiBA = calcVarience(PiB, PiA);
    console.log("PiBA:" + PiBA);
    let y = PiAB / PiBA;
    console.log("Y:" + y);
    let PiDiss = PiAB + PiBA;
    let Vy = V * (y + 1);
    let ypls = (y + 1) * (y + 1);
    let ymins = (y - 1) * (y - 1) * PiDiss;
    let M = ypls - ymins;
    let uSqr = await nthroot(M, 2);
    uSqr = uSqr * U;
    smaplesize = (Vy + uSqr) * (Vy + uSqr) / ymins;
    return smaplesize;
}

export const calcP2 = (P1, OR) => {
    let P2;
    P2 = P1 * OR;
    P2 = P2 / (1 + P1 * (OR - 1))
    return roundOf(P2, 2);
}

export const calcOr = (P1, P2) => {
    let Or;
    let up = P2 * (P1 - 1);
    let dw = P1 * (P2 - 1);
    Or = up / dw;
    return roundOf(Or, 2);
}

export const calcSampleSIzeCCS_P = async(P1, P2, OR, R, U, V) => {
    if (P2 <= 0 && OR > 0) {
        P2 = calcP2(P1, OR);
    }
    let P = (P1 + P2) / 2;
    let Zalfa = (R + 1) * calcVariance(P);
    Zalfa = nthroot(Zalfa, 2);
    Zalfa = Zalfa * U;
    let Zbeta = R * await calcVariance(P1) + await calcVariance(P2);
    Zbeta = nthroot(Zbeta, 2);
    Zbeta = Zbeta * V;
    let upr = Zalfa + Zbeta;
    upr = upr * upr;
    let div = P2 - P1;
    div = div * div * R;
    let n = upr / div;
    upr = 0;
    upr = 2 * (R + 1);
    div = 0;
    div = n * R * (P2 - P1);
    let Nsc = upr / div;
    Nsc = 1 + Nsc;
    Nsc = nthroot(Nsc, 2);
    Nsc = 1 + Nsc;
    Nsc = Nsc * Nsc;
    Nsc = Nsc * n / 4
    return Nsc;
}

export const calcSampleSIzeDCSS_P = async (N, Z, ExpProportion, D) => {
    let smaplesize = 0.00;
    let varient = await calcVariance(ExpProportion);
    let zqap = Z * Z * varient;
    let dsqn = D * D * (N - 1);
    let div = dsqn + zqap;
    smaplesize = N * zqap / div;
    return smaplesize;
}

export const calcSampleSIzeDCSS1_P = async (Z, ExpProportion, D) => {
    console.log("Z=" + Z + ",ExpProportion=" + ExpProportion + ",D=" + D);
    let smaplesize = 0.00;
    let varient = await calcVariance(ExpProportion);
    let zqap = Z * Z * varient;
    let dsqr = D * D;
    smaplesize = zqap / dsqr;
    return smaplesize;
}

export const nthroot = (x, n) => {
    try {
        var negate = n % 2 === 1 || x < 0;
        if (negate)
            x = -x;
        var possible = Math.pow(x, 1 / n);
        n = Math.pow(possible, n);
        if (Math.abs(x - n) < 1 && (x > 0 === n > 0))
            return negate ? -possible : possible;
    } catch (e) { }
}


export const calcICSCBA_CC = async (inputData) => {
    let finalArray=[];
    let r;
    let beta;
    let alfa;
   
  
    inputData.forEach((data) => {
        console.log(data);
        let placeHolder=data.PlaceHolder;
        if(placeHolder==="${R}"){
            r=parseFloat(data.Value);
        }else if(placeHolder==="${U}"){
            beta=parseFloat(data.Value);
        }else if(placeHolder==="${V}"){
            alfa=parseFloat(data.Value.split(":")[1]);
        }
    });
    console.log(r,alfa,beta)

    let SampleSize = await calcSampleSIzeICSCBA_CC(r,alfa,beta)
   
    console.log(SampleSize)

    SampleSize = roundOf(SampleSize, 0);
    finalArray.push({'PlaceHolder':"${SampleSize}",'Value':SampleSize}); 
         
    let SampleSize5per=await calcPercentOfValue(5,SampleSize);
    SampleSize5per = roundOf(SampleSize5per, 0);
    finalArray.push({'PlaceHolder':"${5PerOfSampleSize}",'Value':SampleSize5per});
    let spanderr=SampleSize+SampleSize5per;
    spanderr = roundOf(spanderr, 0);
    finalArray.push({'PlaceHolder':"${SampleSizeSum}",'Value':spanderr});
    return finalArray;

}

export const  calcSampleSIzeICSCBA_CC = async (r,alfa,beta) => {
   
    let calcValue=0;
    //This is the calculated value of 10.5 *Log(1+r/1-r)
    let C= await Math.atanh(r)
    console.log(C);
    // console.log("**********");
    // let z_alfa_s=1-alfa/2;
    // let z_beta_s=1-beta;
    // if (z_beta_s < 0){
    //     z_beta_s = z_beta_s*-1 
    // }
    // let Z_alfa:any = await this.dataAnalysisService.getZcritical("two_tailed",z_alfa_s).toPromise()
    // let Z_beta:any = await this.dataAnalysisService.getZcritical("two_tailed",z_beta_s).toPromise()
   
    calcValue= await alfa+beta;
    calcValue= await calcValue/C;
    calcValue= await calcValue*calcValue;
    calcValue= await calcValue+3;
   
    return calcValue;
}