import { useEffect, useState } from "react"
import { Col, Row } from "reactstrap";
import BarChart from "../charts/BarChart";
import PieChart from "../charts/PieChart";
import StackedBarChart from "../charts/StackedBarchart";
import LineChart from "../charts/LineChart";
import GroupedBarChart from "../charts/GroupedBarchart";

const AnalysisChart = (props) =>{
    const [chartData,SetchartData] = useState<any>([]);

    useEffect(()=>{
        console.log(props.data)
       // SetchartData(props.data)
        console.log(props.data)
        if(props.type == 1){
           let data =  createChartData(props.data)
           data.then(data =>{
            SetchartData(data)
           })
        }else if(props.type == 2){
            let data =  createChartMeanMedian(props.data)
            data.then(data =>{
                SetchartData(data)
               })
        }else if(props.type == 3){
            let data =  createChartMedian(props.data)
            console.log(data)
            data.then(data =>{
                SetchartData(data)
               })
        }
    },[])


    /**
     * chart using median values
     * @param data 
     * @returns 
     */
async function createChartMedian(data) {
    let dataArray: any = [];
    let charttype = "";
    let xlabel = "";
    let ylabel = "Median";
    let chartArray: any = [];
    // data.charts && data.charts.map(item => {
    let item = data.charts;

    if (item == "bar") {
        dataArray = [];

        data?.rowvariablevalues.map(item => {
            dataArray.push({
                percentage: data.tabledata[item]["median"].toFixed(2).replace(/\.00$/, ''),
                frequency: data.tabledata[item]["median"].toFixed(2),
                variable: item
            })
        })
        charttype = item;
        xlabel = data?.rowvariablenames;
        ylabel = "Median"
        console.log(dataArray)
        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, yaxis:ylabel })
    } else if (item == "pie") {
        let totalMean = 0;
        dataArray = [];
        data?.rowvariablevalues.map(item => {
            totalMean += data.tabledata[item]["median"];
        })

        data?.rowvariablevalues.map(item => {
            dataArray.push({
                percentage: (data.tabledata[item]["median"] * 100 / totalMean).toFixed(2),
                frequency: data.tabledata[item]["median"].toFixed(2) * 100 / totalMean,
                variable: item
            })

        })
        charttype = item;
        xlabel = data?.rowvariablenames;

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel,yaxis: ylabel })
    } else if (item == "clusterBarChart") {
        let valueArray: any = [];
        dataArray = [];

        data?.rowvariablevalues.map(item => {
            valueArray.push({ name: item, value: data?.tabledata[item]["mean"] && data?.tabledata[item]["mean"].toFixed(2) })
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push({ group: 'Median', values: valueArray })

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel,yaxis: ylabel })

    } else if (item == "stackedBarChart") {
        let valueObject: any = {};
        dataArray = [];
        let categoriesArray: any = [];
        valueObject['label'] = data?.rowvariablenames[0];
        data?.rowvariablevalues.map(item => {
            categoriesArray.push(item)
            valueObject[item] = data?.tabledata[item]["median"].toFixed(2)
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push(valueObject)

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, categories: categoriesArray,yaxis: ylabel })
    } else if (item == "lineChart") {
        let valueArray: any = []; dataArray = [];
        data?.rowvariablevalues.map(item => {
            valueArray.push({ x: item, y: parseInt(data?.tabledata[item]["median"].toFixed(2)) })
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push(valueArray)

        chartArray.push({ charttype: charttype, data: valueArray, xaxis: xlabel,yaxis: ylabel })
    }
    //  })

    return chartArray;
}


    //chart for crosstabs
async function createChartData(data) {
    let dataArray: any = [];
    let charttype = "";
    let xlabel = "";
    let ylabel = "";
    let chartArray: any = [];


    //data && data?.charts.map(item => {
    if (data?.charts == "bar" || data?.charts == "pie") {
        data['charts'] = "clusterBarChart"
    }
    if (data?.charts == "clusterBarChart") {
        for (var val in data?.tabledata) {
            let valueArray: any = [];
            data?.colvariablevalues[data?.colvariablenames].map(item => {
                valueArray.push({ name: item, value: data.tabledata[val][item + "_per"].toFixed(2) })
            })
            charttype = data?.charts;
            xlabel = data?.rowvariablenames;
            dataArray.push({ group: val, values: valueArray })

        }

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel,yaxis: ylabel })
    } else if (data?.charts == "pie") {
        for (var val in data.tabledata) {
            data?.colvariablevalues[data?.colvariablenames].map(item => {
                dataArray.push({
                    percentage: data.tabledata[val][item + "_per"].toFixed(2),
                    frequency: data.tabledata[val][item + "_per"].toFixed(2),
                    variable: item
                })
            })
            charttype = data?.charts;
            xlabel = data?.rowvariablenames;
        }
        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel,yaxis: ylabel })
    } else if (data?.charts == "bar1") {
        data.rowvariablevalues[data?.rowvariablenames].map(item => {
            dataArray.push({
                percentage: data.tabledata[item]["mean"].toFixed(2).replace(/\.00$/, ''),
                frequency: data.tabledata[item]["mean"].toFixed(2),
                variable: item
            })
        })
    }
    //})
    return chartArray
}

/**
 * function can be used for all charts with mean values
 * @param data 
 * @returns 
 */
async function createChartMeanMedian(data) {
    let dataArray: any = [];
    let charttype = "";
    let xlabel = "";
    let ylabel = "Mean";
    let chartArray: any = [];
    // data.charts && data.charts.map(item => {
    let item = data.charts;

    if (item == "bar") {
        dataArray = [];
        await data?.rowvariablevalues.map(item => {
            dataArray.push({
                percentage: data.tabledata[item]["mean"].toFixed(2).replace(/\.00$/, ''),
                frequency: data.tabledata[item]["mean"].toFixed(2),
                variable: item
            })
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, yaxis: ylabel })
    } else if (item == "pie") {
        let totalMean = 0;
        dataArray = [];
        data?.rowvariablevalues.map(item => {
            totalMean += data.tabledata[item]["mean"];
        })

        data?.rowvariablevalues.map(item => {
            dataArray.push({
                percentage: (data.tabledata[item]["mean"] * 100 / totalMean).toFixed(2),
                frequency: data.tabledata[item]["mean"].toFixed(2) * 100 / totalMean,
                variable: item
            })

        })
        charttype = item;
        xlabel = data?.rowvariablenames;

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel,yaxis: ylabel })
    } else if (item == "clusterBarChart") {
        let valueArray: any = [];
        dataArray = [];

        data?.rowvariablevalues.map(item => {
            valueArray.push({ name: item, value: data?.tabledata[item]["mean"].toFixed(2) })
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push({ group: 'Mean', values: valueArray })

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel,yaxis: ylabel })

    } else if (item == "stackedBarChart") {
        let valueObject: any = {};
        dataArray = [];
        let categoriesArray: any = [];
        valueObject['label'] = data?.rowvariablenames[0];
        data?.rowvariablevalues.map(item => {
            categoriesArray.push(item)
            valueObject[item] = data?.tabledata[item]["mean"].toFixed(2)
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push(valueObject)

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, categories: categoriesArray,yaxis: ylabel })
    } else if (item == "lineChart") {
        let valueArray: any = []; dataArray = [];
        data?.rowvariablevalues.map(item => {
            valueArray.push({ x: item, y: parseInt(data?.tabledata[item]["mean"].toFixed(2)) })
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push(valueArray)

        chartArray.push({ charttype: charttype, data: valueArray, xaxis: xlabel,yaxis: ylabel })
    }
    //  })

    return chartArray;
}


    useEffect(()=>{
        console.log(chartData)
    },[chartData])

    return(
        <>
          <Row className="chartdata">
                {
                    chartData && chartData.map(item => {
                        return <Col md={6} className="p-1">{item?.charttype == "bar" ?
                            <BarChart
                                data={item.data}
                                width={400}
                                height={200}
                                interval={10}
                                barstyle={{ color: "blue", hover: "#ccc" }}
                                bardatastyle={{ fontsize: "12px", fontweight: "500", color: "#000" }}
                                gridcolor="#ccc"
                                gridstatus="hidden"
                                yaxistext={item.yaxis}
                                yaxislabelstyle={{ fontsize: "12px", fontweight: "200", color: "red" }}
                                yaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                                xaxistext={item.xaxis}
                                xaxislabelstyle={{ fontsize: "10px", fontweight: "400", color: "red", rotate: "-20" }}
                                xaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                                title={""}
                                titlecolor={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                            />

                            : item?.charttype == "pieChart" ?
                                <PieChart
                                    data={item.data}
                                    width={500}
                                    height={200}
                                    chartStyle={{ hover: "#ccc", fontsize: "14px", fontweight: "500", color: "#fff", height: 1 }}
                                    title={""}
                                    titlecolor={{ fontsize: "14px", fontweight: "600", color: "#000" }}
                                    legends={{ fontsize: "14px", fontweight: "300", color: "#000" }} />
                                : item?.charttype == "clusterBarChart" ?
                                    <GroupedBarChart
                                        yaxistext={"Percentage(%)"}
                                        xaxistext={item.xaxis}
                                        width={500}
                                        height={300}
                                        data={item.data}
                                        title={""}
                                        gridstatus={"visible"} />

                                    : item?.charttype == "stackedBarChart" ?
                                        <StackedBarChart
                                            data={item.data}
                                            width={300}
                                            height={200}
                                            categories={item.categories}
                                            barstyle={{ color: "#80cbc4", hover: "#ccc" }}
                                            bardatastyle={{ fontsize: "16px", fontweight: "500", color: "red", visible: "visible" }}
                                            gridcolor={"#cc1"}
                                            gridstatus={"visible"}
                                            yaxistext={"Mean"}
                                            yaxislabelstyle={{ fontsize: "14px", fontweight: "400", color: "#000" }}
                                            yaxisstyle={{ fontsize: "14px", fontweight: "600", color: "#000", dy: "1em" }}
                                            xaxistext={item.xaxis}
                                            xaxislabelstyle={{ fontsize: "14px", fontweight: "400", color: "red", rotate: "-45" }}
                                            xaxisstyle={{ fontsize: "18px", fontweight: "500", color: "#000", dy: "3em" }}
                                            title={" "}
                                            titlecolor={{ fontsize: "24px", fontweight: "600", color: "green" }} />
                                        : item?.charttype == "lineChart" ?
                                            <LineChart
                                               data={item.data}
                                                width={400}
                                                heigth={300}
                                                scaley={20}
                                                isarray={true}
                                                chartstyle={{ color: "royalblue", hover: "#ccc", linecolor: "#cc5", radius: 4 }}
                                                chartdatastyle={{ fontsize: "16px", fontweight: "500", color: "black", visible: "visible" }}
                                                gridcolor={"#ccc"}
                                                gridstatus={"hidden"}
                                                yaxistext={"Life Exp Y label"}
                                                yaxislabelstyle={{ fontsize: "18px", fontweight: "400", color: "#000" }}
                                                yaxisstyle={{ fontsize: "14px", fontweight: "600", color: "#000" }}
                                                xaxistext={" per capital values "}
                                                xaxislabelstyle={{ fontsize: "18px", fontweight: "400", color: "red", rotate: "-45" }}
                                                xaxisstyle={{ fontsize: "18px", fontweight: "500", color: "#000", dy: "2em" }}
                                                title={""}
                                                titlecolor={"#000"}
                                            />
                                            :
                                            ""}

                        </Col>


                    })
                }
            </Row>
        </>
    )
}

export default AnalysisChart;