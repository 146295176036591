import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

const ParetoChart = ({ data, barstyle,bardatastyle, linestyle,linedatastyle, width, height, title, titlecolor, yaxislabelstyle,xaxislabelstyle,gridcolor,gridstatus,yaxisstyle,xaxisstyle,xaxistext,yaxistext }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    // Function to create Pareto chart
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const widthvalue = width - margin.left - margin.right;
    const heightvalue = height ;


    const createParetoChart = () => {
     
      // Clear any existing chart
      d3.select(chartRef.current).selectAll('*').remove();

      // Create SVG container
      const svg = d3
        .select(chartRef.current)
        .append('svg')
        .attr('width', widthvalue + margin.left + margin.right)
        .attr('height', heightvalue + margin.top + margin.bottom)
        .append('g')
        .style('fill', 'none')  // Set fill to none
        .attr('transform', `translate(${margin.left + margin.top},${margin.bottom})`);

      // Sort data in descending order
      data.sort((a, b) => b.value - a.value);

      // Calculate cumulative percentage
      let cumulativePercentage = 0;
      data.forEach((d) => {
        d.percentage = (d.value / data.reduce((acc, cur) => acc + cur.value, 0)) * 100;
        cumulativePercentage += d.percentage;
        d.cumulativePercentage = cumulativePercentage;
      });

         // Draw gridlines
    svg
    .append('g')
    .attr('class', 'grid')
    .style('color',gridcolor)
    .attr('transform', `translate(2,${height})`)
    .style("stroke-dasharray", "5 5")
    .style("visibility",gridstatus)
    .call(d3.axisBottom(x).tickSize(-height).tickFormat(''));

      svg
            .append('g')
            .attr('class', 'grid')
            .attr("transform", `translate(0,0)`)   
            .style("stroke-dasharray", "5 5")
            .style("color",gridcolor)
            .style("visibility",gridstatus)
            .call(d3.axisLeft(y).tickSize(-width).tickFormat(''));


      // Create bars
      svg
        .selectAll('.bar')
        .data(data)
        .enter()
        .append('rect')
        .attr('class', 'bar')
        .attr('x', (d) => x(d.label) + margin.top)
        .attr('width', x.bandwidth()/2)
        .attr('y', (d) => y(d.value))
        .style('fill', barstyle.color)  // Set fill to none
        .attr('height', (d) => heightvalue - y(d.value));

      // Add values on top of bars
      svg
      .selectAll('.bar-value')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'bar-value')
      .attr('transform', `translate(${margin.left},0`)
      .attr('x', (d) => x(d.label) + x.bandwidth() / 2)
      .attr('y', (d) => y(d.value) + margin.top)
      .attr('text-anchor', 'middle')
      .style('fill',bardatastyle.color)
      .style('font-weight',bardatastyle.fontweight)
      .style('font-size',bardatastyle.fontsize)
      .style('visibility',bardatastyle.visible)
      .text((d) => d.value);


      // Create line for cumulative percentage
      const line = d3
        .line()
        .x((d) => x(d.label) + x.bandwidth() / 2)
        .y((d) => y2(d.cumulativePercentage))
       ;

       svg
        .append('path')
        .data([data])
        .attr('class', 'line')
        .attr('d', line)
        .style('fill', 'none')  // Set fill to none
        .style('stroke', linestyle.color);  // Set the stroke color as needed


        // Add dots along the line with values
        svg
        .selectAll('.dot')
        .data(data)
        .enter()
        .append('g')
        .attr('class', 'dot-group')
        .attr('transform', (d) => `translate(${x(d.label) + x.bandwidth() / 2},${y2(d.cumulativePercentage)})`)
        .append('circle')
        .attr('class', 'dot')
        .attr('r', linedatastyle.radius) // Adjust the radius of the circle as needed
        .style('fill', linedatastyle.color); // Set the fill color of the circle as needed

        // Add value labels for each dot
        svg
        .selectAll('.dot-group')
        .append('text')
        .attr('class', 'dot-label')
        .attr('x', 0)
        .attr('y', margin.top) // Adjust the vertical position of the label
        .attr('fill','black')
        .attr('text-anchor', 'middle')
        .text(d => Math.round(d.cumulativePercentage)+'%');


      // Add axes
      svg.append('g').call(d3.axisLeft(y));
      svg.append('g').attr('transform', `translate(0,${heightvalue})`).call(d3.axisBottom(x));

       //add x axis label
        svg.append('text')
        .attr("class", "x label")
        .attr("text-anchor", "middle")
        .attr("x", width/3)
        .attr("y", height - 2)
        .attr("dy", xaxisstyle.dy)
        .attr("dx",width/8 - margin.left)
        .style('fill',xaxisstyle.color)
        .style('font-weight',xaxisstyle.fontweight)
        .style('font-size',xaxisstyle.fontsize)
        .text(xaxistext);
   
        //add y axis label
        svg.append("text")
        .attr("class", "y label")
        .attr('transform', `translate(80)`)
        .attr("text-anchor", "middle")
        .attr("y", 6)
        .attr("dy", yaxisstyle.dy)
        .attr("x",-height/2)
        .attr("transform", "rotate(-90)")
        .style('fill',yaxisstyle.color)
        .style('font-weight',yaxisstyle.fontweight)
        .style('font-size',yaxisstyle.fontsize)
        .text(yaxistext);



        //title of the chart
        svg.append('text')
        .attr('class', 'title')
        .attr('x', width / 2 - margin.left)
        .attr('y', -10)
        .attr('text-anchor', 'middle')
        .style('font-size',titlecolor.fontsize)
        .style('font-weight',titlecolor.fontweight)
        .style('fill',titlecolor.color)
        .text(title)

    };

    // Set up scales
    const x = d3.scaleBand().range([0, widthvalue]).padding(0.1);
    const y = d3.scaleLinear().range([heightvalue, 0]);
    const y2 = d3.scaleLinear().range([heightvalue, 0]);

    x.domain(data.map((d) => d.label));
    y.domain([0, d3.max(data, (d) => d.value)]);
    y2.domain([0, 100]);

       

    // Create Pareto chart on initial render
    createParetoChart();
  }, [data]);

  return <div ref={chartRef}></div>;
};

ParetoChart.prototype={
    data:PropTypes.oneOfType([PropTypes.array,PropTypes.object]),
    width:PropTypes.number,
    heigth:PropTypes.number,
    barstyle:PropTypes.object,
    bardatastyle:PropTypes.object,
    linestyle:PropTypes.object,
    linedatastyle:PropTypes.object,
    gridcolor:PropTypes.string,
    gridstatus:PropTypes.string,
    yaxistext:PropTypes.string,
    yaxislabelstyle:PropTypes.object,
    yaxisstyle:PropTypes.object,
    xaxistext:PropTypes.string,
    xaxislabelstyle:PropTypes.object,
    xaxisstyle:PropTypes.object,
    title:PropTypes.string,
    titlecolor:PropTypes.object
  }
  
  ParetoChart.defaultProps ={
    data:[
        { label: 'Category 1', value: 20 },
        { label: 'Category 2', value: 15 }],
    width:"600", 
    height:"400", 
    barstyle:{color:"#000",hover:"#ccc"},
    bardatastyle:{fontsize:"16px",fontweight:"500",color:"#000"},
    linestyle:{color:"blue",hover:"#ccc"},
    linedatastyle:{fontsize:"16px",fontweight:"500",color:"black",radius:3,visible:"visible"},
    gridcolor:"#ccc",
    gridstatus:"hidden",
    yaxistext:"Y Label",
    yaxislabelstyle:{fontsize:"14px",fontweight:"400",color:"red"},
    yaxisstyle:{fontsize:"14px",fontweight:"600",color:"#000"},
    xaxistext:"X Label",
    xaxislabelstyle:{fontsize:"14px",fontweight:"400",color:"red"},
    xaxisstyle:{fontsize:"14px",fontweight:"600",color:"#000"},
    title:"Bar Chart",
    titlecolor:{fontsize:"24px",fontweight:"600",color:"#000"}
  }

export default ParetoChart;
