import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalComponent from "../common/Modal/modal";
import { CreateCriteriaElements } from "./FormElements";
import { setLoading } from "../../store/slices/auth";
import { getFormVariables, getForms, getGroups } from "./apiController";
import { useLocation } from "react-router-dom";
import CriteriaEditorComponent from "./CriteriaEditor";
import { MinusCircleFilled, MinusCircleOutlined, PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons";

export default function CriteriaForm(props) {
  const dispatch = useDispatch();
  const [action, setaction] = useState<any | "">("");
  const [showCodeEditor, setShowCodeEditor] = useState(false);
  const [formList, SetFormList] = useState<any>([]);
  const [groupData, SetGroup] = useState<any>([]);
  const [formvariables, setformVariables] = useState<any>([]);
  const [formvariablesValue, setformVariablesValue] = useState<any>([]);
  const [rules, Setrules] = useState<any>([]);
  const location = useLocation();

  const initialValues = useMemo(
    () =>
      props.previousState
        ? props.previousState
        : { name: "", source: "", destination: "", operator: "" },
    [props.previousState]
  );
  const [values, fields, handleChange, { validateValues, updateValue, updateError, removeErrors }] =
    useFieldItem(CreateCriteriaElements, initialValues, { onValueChange });

  

  /**
   * on value change for form fields
   * @param name 
   * @param value 
   * @param values 
   * @param fieldValues 
   * @returns 
   */
  function onValueChange(name, value, values, fieldValues = null) {
    let resetValue = {};
    console.log(values)
    if (name == "source") {
      fetchFormVariables(value);
    }
    
    /**
     * check if the current var type is variable
     * if yes get the variable data from formVariables state
     * update the rules state with the updated values
     */
    if (name.slice(0, -1) == "variable") {
      let variableData = formvariables.filter(items => items.label == value.label)
      let fieldArray : any = [];
      variableData[0].field_value && variableData[0].field_value.map(item =>{
        fieldArray.push({label:item,value:item})
      })
      variableData[0].fielddropDown = fieldArray;
      let idValue = name[name.length - 1]-1;

      rules[name[name.length - 1]] = {
        id:rules[name[name.length - 1]].id,
        variable: value,
        operator: "",
        uservalue: "",
        logicoperator: "",
        variabledata: variableData[0]
      }

    }else if (name.slice(0, -1) == "operator"){

      let idValue = name[name.length - 1]-1;
      rules[idValue] = {
        id:rules[idValue].id,
        variable: rules[idValue].variable,
        operator: value,
        uservalue: rules[idValue].uservalue,
        logicoperator: rules[idValue].logicoperator,
        variabledata: rules[idValue].variabledata
      }
    }else if (name.slice(0, -1) == "uservalue"){
      let idValue = name[name.length - 1]-1;

      rules[idValue] = {
        id:rules[idValue].id,
        variable: rules[idValue].variable,
        operator: rules[idValue].operator,
        uservalue: value,
        logicoperator: rules[idValue].logicoperator,
        variabledata: rules[idValue].variabledata
      }
    }else if (name.slice(0, -1) == "logic"){
      let idValue = name[name.length - 1]-1;

      rules[idValue] = {
        id:rules[idValue].id,
        variable: rules[idValue].variable,
        operator: rules[idValue].operator,
        uservalue: rules[idValue].logicoperator,
        logicoperator: value,
        variabledata: rules[idValue].variabledata
      }
    }

    return [
      {
        ...values,
        ...resetValue,
        [name]: value,
      },
    ];
  }

  /** onBlur check source and destination duplication */
  const onBlurAction = (type) => {
    console.log(values["destination"].label == values["source"].label)
    if (values["destination"].label == values["source"].label) {
      if (type != "source") {
        updateError("destination", { hasError: true, errorMsg: "Cannot be same as Source" });
      } else {
        updateError("source", { hasError: true, errorMsg: "Cannot be same as Destination" });
      }
    }
  }

  useEffect(() => {
    let temp: any = location.state;
    fetchGroups(temp?.TopicID)
    addRules();
  }, [])

  /**
    * get groups
    * @param id 
    */
  const fetchGroups = async (id) => {
    try {
      dispatch(setLoading(true));
      let tempGroups = await getGroups(id);
      SetGroup(tempGroups?.data || []);
      fetchForms(tempGroups?.data[0].id);
      dispatch(setLoading(false));
    } catch (e: any) {
      SetGroup([]);
      dispatch(setLoading(false));
    }
  };

  /**
 * fetch form list
 * @param index 
 * @param id 
 */
  const fetchForms = async (id) => {
    try {
      dispatch(setLoading(true));
      let tempForms = await getForms(id);
      SetFormList([]);
      tempForms.map((item, i) => {
        let Obj = {
          label: item.form_name,
          value: item.id
        }
        SetFormList(prevState => [...prevState, Obj]);
      })
      dispatch(setLoading(false));
    } catch (e: any) {
      dispatch(setLoading(false));
    }
  };


  /**
   * get variables data
   * @param formData 
   */
  const fetchFormVariables = async (formData) => {
    try {
      dispatch(setLoading(true));
      let response = await getFormVariables(formData.value);
      setformVariables([]);
      setformVariablesValue(response.data);
      response.data.map((item, i) => {
        let Obj = {
          label: item.field_name,
          value: item.id,
          field_value: item.field_value,
          field_type: item.field_type
        }
        setformVariables(prevState => [...prevState, Obj]);
      })


      dispatch(setLoading(false));
    } catch (e: any) {
      setformVariables([]);
      dispatch(setLoading(false));
    }
  }

  /**
   * submit action
   */
  const onAdd = async () => {
    if (validateValues(["name", "source", "destination"])) {
      showToaster(
        toasterTypes.ERROR,
        "Please enter the proper values in the fields highlighted in red"
      );
    } else {
      props.onClose();
      props.addData(true);
      let reqObj: any = {
        topicid: props.projectData?.TopicID || props.projectData?.TopicCode,
        criteria_name: values.name,
        source_form_id: values.source.value,
        target_form_id: values.destination.value,
        criteria:{}
      };
      if (props.isEdit) {
        reqObj.id = props.formData?.id;
      }
      try {
        dispatch(setLoading(true));
        let response: any;
        console.log("reqObj:::", reqObj);
        if (props.isEdit) {
          // response = await updateForm(reqObj);
        } else {
          // response = await createForm(reqObj);
        }
        dispatch(setLoading(false));
        showToaster(toasterTypes.SUCCESS, response.message);
        props.onFormCreation();
      } catch (e: any) {
        dispatch(setLoading(false));
      }
    }
  };


  const onAction = async (type, data: any = null) => {
    switch (type) {
      case 1:
        if (data?.total_responses === 0) {
          showToaster(toasterTypes.ERROR, "No Records Found");
        } else {
          dispatch(setLoading(true));
          dispatch(setLoading(false));
          setaction(type);
        }
        break;
      case 2:
        try {
          dispatch(setLoading(true));
          dispatch(setLoading(false));
          setaction(type);
        } catch (e: any) {
          // setgroups([]);
          dispatch(setLoading(false));
        }
        break;
      case 3:
        // setformData(data);
        setaction(type);
        break;
      default:
        setaction(type);
        break;
    }
  };

  /**
   * add rules
   */
  const addRules = () => {
    let Obj = {
      id:rules.length == 0 ? 1 : rules[rules.length-1]["id"]+1,
      variable: "",
      operator: "",
      uservalue: "",
      logicoperator: "",
      variabledata: ""
    }
    Setrules(prevState => [...prevState, Obj])
  }

  /**
   * remove items 
   */
  const removeRules = (itemid) =>{
    console.log(itemid)
    let updatedArray = rules.filter(item => item.id != itemid+1)
    console.log(updatedArray)
    Setrules(updatedArray)
    
  }

  useEffect(()=>{

  },[rules])

  return (
    <ModalComponent
      width={"90%"}
      onClose={() => props.onClose()}
      isOpen={true}
      header={<b>Create Criteria</b>}
      centered={true}
      customClass="criteria-modal"
      body={
        <div>
          <Row>
            <Col md={12}>
              <FieldItem
                {...CreateCriteriaElements.name}
                value={values.name}
                onChange={(...e) =>
                  handleChange(CreateCriteriaElements.name.name, ...e)
                }
                touched={fields.name && fields.name.hasError}
                error={fields.name && fields.name.errorMsg}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FieldItem
                {...CreateCriteriaElements.source}
                value={values.source}
                values={formList && formList}
                onChange={(...e) =>
                  handleChange(CreateCriteriaElements.source.name, ...e)
                }
                touched={fields.source && fields.source.hasError}
                error={fields.source && fields.source.errorMsg}
                onBlur={() => onBlurAction("source")}
              />
            </Col>
            <Col md={4}>
              <FieldItem
                {...CreateCriteriaElements.destination}
                value={values.destination}
                values={formList && formList}
                onChange={(...e) =>
                  handleChange(CreateCriteriaElements.destination.name, ...e)
                }
                touched={fields.destination && fields.destination.hasError}
                error={fields.destination && fields.destination.errorMsg}
                onBlur={() => onBlurAction("destination")}
              />
            </Col>
          </Row>
          <div className="permissions-border">
            <Row className="m-0">
              <Col className="p-0">
                <div className="permissions-text">Rules</div>
              </Col>
              {/* <Col className="p-0">
                <div className="user-text" onClick={() => addRules()}>
                  Add Rules
                </div>
              </Col> */}
            </Row>
          </div>
          <div className="code-editor">
            {
              rules && rules.map((item, index) => {
                return <Row>
                  <Col md={3}>
                    <FieldItem
                      name={"variable" + item.id}
                      value={values["variable" + (item.id-1)]}
                      values={formvariables && formvariables}
                      type={FIELD_TYPES.DROP_DOWN}
                      label={CreateCriteriaElements.variable.label}
                      onChange={(...e) =>
                        handleChange("variable" + index, ...e)
                      }
                      isMandatory={true}
                      touched={fields["variable" + item.id] && fields["variable" + item.id].hasError}
                      error={fields["variable" + item.id] && fields["variable" + item.id].errorMsg}
                    />
                  </Col>

                  <Col md={3}>
                    <FieldItem
                      name={"operator" + item.id}
                      value={values["operator"+item.id]}
                      values={CreateCriteriaElements.operator.values}
                      type={FIELD_TYPES.DROP_DOWN}
                      isMandatory={true}
                      label={CreateCriteriaElements.operator.label}
                      onChange={(...e) =>
                        handleChange("operator" + item.id, ...e)
                      }
                      touched={fields["operator" + item.id] && fields["operator" + item.id].hasError}
                      error={fields["operator" + item.id] && fields["operator" + item.id].errorMsg}
                    />
                  </Col>
                  <Col md={3}>
                    <FieldItem
                      name={"uservalue" + item.id}
                      value={values["uservalue" +item.id]}
                      isMandatory={true}
                      type={rules && item.variabledata.field_type == "radioButton" || item.variabledata.field_type == "checkBox" || item.variabledata.field_type == "dropDown" ? FIELD_TYPES.DROP_DOWN : FIELD_TYPES.TEXT}
                      values={rules && item.variabledata.field_type == "radioButton" || item.variabledata.field_type == "checkBox" || item.variabledata.field_type == "dropDown" ? rules[index].variabledata?.fielddropDown : ""}
                      label={CreateCriteriaElements.uservalue.label}
                      onChange={(...e) =>
                        handleChange("uservalue" + item.id, ...e)
                      }
                      touched={fields["uservalue" + item.id] && fields["uservalue" + item.id].hasError}
                      error={fields["uservalue" + item.id] && fields["uservalue" + item.id].errorMsg}
                    />
                  </Col>
                  <Col md={2}>
                    <FieldItem
                      name={"logic" + item.id}
                      value={values["logic" +item.id]}
                      values={CreateCriteriaElements.logicoperator.values}
                      type={FIELD_TYPES.DROP_DOWN}
                      label={CreateCriteriaElements.logicoperator.label}
                      onChange={(...e) =>
                        handleChange("logic" + item.id, ...e)
                      }
                      touched={fields["logic" + item.id] && fields["logic" + item.id].hasError}
                      error={fields["logic" + item.id] && fields["logic" + item.id].errorMsg}
                    />
                  </Col>

                  <Col md={1}>
                    <div className="add-row" >
                      {rules && rules.length == index + 1 ?
                        <>
                          <MinusCircleOutlined className="remove" onClick={() => removeRules(index)}/>
                          <PlusCircleOutlined className="add" onClick={() => addRules()}/>
                        </>
                        :
                        <MinusCircleOutlined className="remove" onClick={() => removeRules(index)}/>
                      }
                    </div>
                  </Col>

                </Row>
              })
            }

          </div>
        </div>
      }
      footer={
        <div>
          <CustomButton
            type="primary"
            isDisabled={false}
            text="Add"
            onClick={() => onAdd()}
          ></CustomButton>
        </div>
      }
    />
  );
}
