import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useGlobalFilter, useTable, forwardRef, useRowSelect, useSortBy } from 'react-table';
import { Button, Col, Input, InputGroup, Row } from 'reactstrap';
import { CustomButton } from '../button';
import { Checkbox } from 'antd';
import { CaretDownFilled, CaretUpFilled, MergeCellsOutlined } from '@ant-design/icons';
import Sort from './sort';

interface Props {
    indeterminate?: boolean;
}

/**
 * create refs
 * @param refs 
 * @returns 
 */
const useCombinedRefs = (...refs): React.MutableRefObject<any> => {
    const targetRef = React.useRef();

    React.useEffect(() => {
        refs.forEach(ref => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            } else {
                ref.current = targetRef.current;
            }
        });
    }, [refs]);

    return targetRef;
};

/**
 * Create checkbox function
 */
const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, Props>(
    ({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
        const defaultRef = React.useRef(null);
        const combinedRef = useCombinedRefs(ref, defaultRef);

        useEffect(() => {
            if (combinedRef?.current) {
                combinedRef.current.indeterminate = indeterminate ?? false;
            }
        }, [combinedRef, indeterminate]);

        return (
            <React.Fragment>
                <input type="checkbox" ref={combinedRef} {...rest} />
            </React.Fragment>
        );
    }
);

function Table(props: any) {

    const { searchHelpText = "Search", columns, data, isSort, tableNum, isSelect, isSearch, isFilter, isCreate, isAdd, isGroup, isMerge, lastrow, isEditable, getdata, tableid } = props;
    const [editingCell, setEditingCell] = useState<any>([]);
    const [editHead,setEditHead] = useState(false);

    /**
     * state reducer to handle onclick checkbox 
     */
    const stateReducer = useCallback((newState, action, prevState) => {
        // intercept expand toggles and collapse other expanded row(s)
        if (action.type === 'toggleRowExpanded') {
            const prevTokens = Object.keys(prevState.expanded);
            const newTokens = Object.keys(newState.expanded);
            if (newTokens.length > 1) {
                const nextExpanded = {};
                for (const t of newTokens) {
                    if (!prevTokens.includes(t)) {
                        nextExpanded[t] = true;
                    }
                }
                return { ...newState, expanded: nextExpanded };
            }
        }
        isSelect && props.onAction(11, newState)
        return newState;
    }, []);

    const {
        header,
        getTableProps,
        getTableBodyProps,
        toggleAllRowsSelected,
        headerGroups,
        rows,
        prepareRow,
        allColumns,
        setGlobalFilter,
        state,
    } = useTable({ columns, data, stateReducer }, useGlobalFilter, useSortBy,
        useRowSelect,
        isSelect ?
            hooks => {
                hooks.visibleColumns.push(columns => [
                    // make a column for selection
                    {
                        "id": "select",
                        "label": ({ getToggleAllRowsSelectedProps }) => (
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} onClick={() => props.onAction(11, "all")} />
                        ),
                        accessor: elem => "-",
                        Cell: ({ value, row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />

                    },
                    ...columns,
                ])
            } : hooks => hooks.visibleColumns.push(columns => [...columns]));
    const { globalFilter } = state;
    const [showFilter, setshowFilter] = useState(false);
    const [showSearch, setshowSearch] = useState(false);


    let formContainerRef = useRef(null);
    useOutsideAlerter(formContainerRef);
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setshowFilter(false)
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const handleCellEdit = (rowIndex, columnId, value) => {
        // Implement your logic for handling cell edits (e.g., updating data)
        const updatedData = [...data];
        updatedData[rowIndex][columnId] = value;

        console.log(rowIndex);
        getdata(updatedData, tableid,tableNum)

        // Update the data source with the new data
        // For a more robust solution, you may want to use state management tools like Redux.
        // This example assumes that `data` is part of your component state.
        // You should update the state using the appropriate method (e.g., setState).
    };


    // Render the UI for your table
    return (
        <div style={{ maxWidth: '100%' }} className="custom-table-container">
            <Row className='options-container align-items-center'>
                <Col className='table-title row m-0 align-items-center'>
                {
                        isEditable && editHead ? 
                           <input style={{width:"100%"}} value={props.header} onChange={(e) => getdata(e.target.value,tableid)} onBlur={() => setEditHead(false)}/>
                           :
                        <span className='mr-3' onClick={() => setEditHead(true)}>{props.header || 'All Entities'}  {tableid ? "" : <>| <b>{data.length}</b></>}</span>

                    }
                    {isCreate &&
                        <CustomButton type="alert-primary" onClick={() => props.onAction(4)} className="w-100" text="Create"></CustomButton>
                    }
                </Col>
                {props.isSummarise &&
                    <Col>
                        <CustomButton type="alert-primary" onClick={() => props.onAction(8)} className="w-100" text="View Combined Data"></CustomButton>
                    </Col>
                }

                {isGroup &&
                    <>
                        <Col className='text-right'>
                            <CustomButton type="alert-primary" onClick={() => props.onAction(6)} text="Configure Grouping"></CustomButton>
                        </Col>
                        <Col className='text-right'>
                            <CustomButton type="alert-primary" onClick={() => props.onAction(9)} className="data-btn" text="Configure Analysis"></CustomButton>
                        </Col>

                    </>
                }

                {
                    isMerge &&
                    <Col className='text-right' style={{ display: "flex" }}>
                        <CustomButton type="alert-primary" onClick={() => props.onAction(10)} className="data-btn" text="Report"></CustomButton>
                        <CustomButton type="alert-primary" onClick={() => props.onAction(12)} className="data-btn" text="Merge"></CustomButton>
                    </Col>
                }
                {isAdd &&
                    <Col className='text-right'>
                        <div className='d-flex m-0 justify-content-end'>
                            {isAdd &&
                                <CustomButton type="alert-primary" onClick={() => props.onAction(5)} className="w-50" text="Add"></CustomButton>
                            }
                        </div>
                    </Col>
                }
                {isSearch &&
                    <Col className='text-right'>
                        <div className='d-flex m-0 justify-content-end'>
                            {isSearch &&
                                <div className='search-container'>
                                    {showSearch ?
                                        <InputGroup>
                                            <Input type="search" className="data-Table-common_search"
                                                placeholder={searchHelpText} bsSize="sm"
                                                onChange={(e) => setGlobalFilter(e.target.value)}
                                                value={globalFilter}
                                            />
                                            <Button addonType="append">
                                                <i className="icon icon-close search-button" onClick={() => { setGlobalFilter(""); setshowSearch(false) }}></i>
                                            </Button>
                                        </InputGroup> :
                                        <Button className='table-header-button' size="sm" onClick={() => setshowSearch(true)}>
                                            <i className="icon icon-search"></i>
                                        </Button>
                                    }
                                </div>
                            }
                            {isFilter &&
                                <Button className='table-header-button' size="sm" onClick={() => setshowFilter(true)}>
                                    <i className="icon icon-filter"></i>
                                </Button>
                            }
                        </div>
                    </Col>
                }
                {showFilter &&
                    <div>
                        <div ref={formContainerRef} className="filter-body">
                            {allColumns.map(column => (
                                <div key={column.id}>
                                    <label>
                                        <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                                        {column.label}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </Row>
            <div style={{ overflow: 'auto', position: 'relative' }}>
                <table {...getTableProps()} className="custom-table">
                    <thead className='table-header'>
                        {
                            headerGroups.map((headerGroup: any) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {/* <th className='text-center sticky-col-header'><Checkbox/></th> */}
                                    {headerGroup.headers.map((column: any, index) => (
                                        <th
                                            style={{ minWidth: column.width - 10 + 'px', textAlign: 'center', border: '1px solid #fff' }}
                                            className={column?.className || ''} {...column.getHeaderProps()}>
                                            {column.label}
                                            {/* {(typeof column.render('label') == "string") && isSort ? column.isSorted ? (!column.isSortedDesc ?
                                                <Sort label={column.render('label')} coloractive={'#fff'} colorinactive={'#ccc'} /> :
                                                <Sort label={column.render('label')} coloractive={'#ccc'} colorinactive={'#fff'} />
                                            ) : <Sort label={column.render('label')} coloractive={'#fff'} colorinactive={'#fff'} /> : column.render('label')} */}
                                        </th>
                                    ))}
                                    {(props.isView || props.isEdit || props.isDelete) && <th className='text-center sticky-col-header'>Actions</th>}
                                </tr>
                            ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="table-body">
                        {rows.map((row: any, i: any) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell: any, index: any) => {
                                        return (
                                            <td style={{ minWidth: cell.column.width - 10 + 'px', textAlign: 'center' }}
                                                {...cell.getCellProps()}
                                                className={cell.column?.className || ''}>
                                                {isEditable ? editingCell && editingCell.i === i && editingCell.columnId === cell.column.id ? (
                                                    <input
                                                        type="text"
                                                        value={cell.value}
                                                        onChange={e => handleCellEdit(i, cell.column.id, e.target.value)}
                                                        onBlur={() => setEditingCell([])}
                                                    />
                                                ) : (
                                                    <div
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => setEditingCell({ i, columnId: cell.column.id })}
                                                    >
                                                        {cell.render('Cell')}
                                                    </div>
                                                ) : cell.render('Cell')}
                                            </td>


                                        )
                                    })}
                                    {(props.isView || props.isEdit || props.isDelete) &&
                                        <td className='text-center sticky-col'>
                                            {props.isView && <i className="icon icon-eye" onClick={() => props.onAction(1, row, i)}></i>}
                                            {props.isEdit && <i className="icon icon-edit" onClick={() => props.onAction(2, row, i)}></i>}
                                            {props.isDelete && <i className="icon icon-delete" onClick={() => props.onAction(3, row, i)}></i>}
                                        </td>
                                    }
                                </tr>
                            )
                        })}

                        {
                            lastrow ?
                                <tr>
                                    <td colSpan={columns.length + 10} >
                                        {lastrow}
                                    </td>
                                </tr>
                                : ""
                        }

                    </tbody>
                </table>
            </div>
        </div>
    )
}

function MultiHeadCustomTable(props: any) {

    const columns = React.useMemo(
        () => props.tableProps,
        [props.tableProps]
    )

    return (
        <Table {...props} columns={columns} data={props.data} pagination={true} />
    )
}

export default MultiHeadCustomTable;
