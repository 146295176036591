import { useCallback, useEffect, useState } from "react"
import AnalysisChart from "./AnalysisCharts";
import { Col, Row } from "reactstrap";
import BarChart from "../charts/BarChart";
import PieChart from "../charts/PieChart";
import StackedBarChart from "../charts/StackedBarchart";
import LineChart from "../charts/LineChart";
import GroupedBarChart from "../charts/GroupedBarchart";

//chart for crosstabs
async function createChartData(data) {
    let dataArray: any = [];
    let charttype = "";
    let xlabel = "";
    let chartArray: any = [];


    //data && data?.charts.map(item => {
    if (data?.charts == "bar" || data?.charts == "pie") {
        data['charts'] = "clusterBarChart"
    }
    if (data?.charts == "clusterBarChart") {
        for (var val in data?.tabledata) {
            let valueArray: any = [];
            data?.colvariablevalues[data?.colvariablenames].map(item => {
                valueArray.push({ name: item, value: data.tabledata[val][item + "_per"].toFixed(2) })
            })
            charttype = data?.charts;
            xlabel = data?.rowvariablenames;
            dataArray.push({ group: val, values: valueArray })

        }

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel })
    } else if (data?.charts == "pie") {
        for (var val in data.tabledata) {
            data?.colvariablevalues[data?.colvariablenames].map(item => {
                dataArray.push({
                    percentage: data.tabledata[val][item + "_per"].toFixed(2),
                    frequency: data.tabledata[val][item + "_per"].toFixed(2),
                    variable: item
                })
            })
            charttype = data?.charts;
            xlabel = data?.rowvariablenames;
        }
        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel })
    } else if (data?.charts == "bar1") {
        data.rowvariablevalues[data?.rowvariablenames].map(item => {
            dataArray.push({
                percentage: data.tabledata[item]["mean"].toFixed(2).replace(/\.00$/, ''),
                frequency: data.tabledata[item]["mean"].toFixed(2),
                variable: item
            })
        })
    }
    //})
    return chartArray
}

/**
 * function can be used for all charts with mean values
 * @param data 
 * @returns 
 */
async function createChartMeanMedian(data) {
    let dataArray: any = [];
    let charttype = "";
    let xlabel = "";
    let chartArray: any = [];
    // data.charts && data.charts.map(item => {
    let item = data.charts;

    if (item == "bar") {
        dataArray = [];
        await data?.rowvariablevalues.map(item => {
            dataArray.push({
                percentage: data.tabledata[item]["mean"].toFixed(2).replace(/\.00$/, ''),
                frequency: data.tabledata[item]["mean"].toFixed(2),
                variable: item
            })
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel })
    } else if (item == "pie") {
        let totalMean = 0;
        dataArray = [];
        data?.rowvariablevalues.map(item => {
            totalMean += data.tabledata[item]["mean"];
        })

        data?.rowvariablevalues.map(item => {
            dataArray.push({
                percentage: (data.tabledata[item]["mean"] * 100 / totalMean).toFixed(2),
                frequency: data.tabledata[item]["mean"].toFixed(2) * 100 / totalMean,
                variable: item
            })

        })
        charttype = item;
        xlabel = data?.rowvariablenames;

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel })
    } else if (item == "clusterBarChart") {
        let valueArray: any = [];
        dataArray = [];

        data?.rowvariablevalues.map(item => {
            valueArray.push({ name: item, value: data?.tabledata[item]["mean"].toFixed(2) })
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push({ group: 'Mean', values: valueArray })

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel })

    } else if (item == "stackedBarChart") {
        let valueObject: any = {};
        dataArray = [];
        let categoriesArray: any = [];
        valueObject['label'] = data?.rowvariablenames[0];
        data?.rowvariablevalues.map(item => {
            categoriesArray.push(item)
            valueObject[item] = data?.tabledata[item]["mean"].toFixed(2)
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push(valueObject)

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, categories: categoriesArray })
    } else if (item == "lineChart") {
        let valueArray: any = []; dataArray = [];
        data?.rowvariablevalues.map(item => {
            valueArray.push({ x: item, y: parseInt(data?.tabledata[item]["mean"].toFixed(2)) })
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push(valueArray)

        chartArray.push({ charttype: charttype, data: valueArray, xaxis: xlabel })
    }
    //  })

    return chartArray;
}

async function createChartMedian(data) {
    let dataArray: any = [];
    let charttype = "";
    let xlabel = "";
    let chartArray: any = [];
    // data.charts && data.charts.map(item => {
    let item = data.charts;

    if (item == "bar") {
        dataArray = [];

        data?.rowvariablevalues.map(item => {
            dataArray.push({
                percentage: data.tabledata[item]["median"].toFixed(2).replace(/\.00$/, ''),
                frequency: data.tabledata[item]["median"].toFixed(2),
                variable: item
            })
        })
        charttype = item;
        xlabel = data?.rowvariablenames;
        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel })
    } else if (item == "pie") {
        let totalMean = 0;
        dataArray = [];
        data?.rowvariablevalues.map(item => {
            totalMean += data.tabledata[item]["median"];
        })

        data?.rowvariablevalues.map(item => {
            dataArray.push({
                percentage: (data.tabledata[item]["median"] * 100 / totalMean).toFixed(2),
                frequency: data.tabledata[item]["median"].toFixed(2) * 100 / totalMean,
                variable: item
            })

        })
        charttype = item;
        xlabel = data?.rowvariablenames;

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel })
    } else if (item == "clusterBarChart") {
        let valueArray: any = [];
        dataArray = [];

        data?.rowvariablevalues.map(item => {
            valueArray.push({ name: item, value: data?.tabledata[item]["mean"] && data?.tabledata[item]["mean"].toFixed(2) })
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push({ group: 'Median', values: valueArray })

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel })

    } else if (item == "stackedBarChart") {
        let valueObject: any = {};
        dataArray = [];
        let categoriesArray: any = [];
        valueObject['label'] = data?.rowvariablenames[0];
        data?.rowvariablevalues.map(item => {
            categoriesArray.push(item)
            valueObject[item] = data?.tabledata[item]["median"].toFixed(2)
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push(valueObject)

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, categories: categoriesArray })
    } else if (item == "lineChart") {
        let valueArray: any = []; dataArray = [];
        data?.rowvariablevalues.map(item => {
            valueArray.push({ x: item, y: parseInt(data?.tabledata[item]["median"].toFixed(2)) })
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push(valueArray)

        chartArray.push({ charttype: charttype, data: valueArray, xaxis: xlabel })
    }
    //  })

    return chartArray;
}

/**
 * cross tab row propotion
 */
export function CrossTabRow(props) {
    const [data, setData] = useState(props.data);
    const [chart, setchart] = useState();
    const [chartData, Setchartdata] = useState<any>([]);
    const [count, Setcount] = useState(0);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [ChiSqr, SetChiSqr] = useState("");
    const [PValue, SetPValue] = useState("");

    useEffect(() => {
        let countval = 0;
        data?.rowvariablevalues[data?.rowvariablenames].map(item => {
            if (data?.tabledata[item]['Chi square value']) {
                SetChiSqr(data?.tabledata[item]['Chi square value'].toFixed(2))
            }

            if (data?.tabledata[item]['P Value']) {
                SetPValue(data?.tabledata[item]['P Value'].toFixed(2))
            }
            data?.colvariablevalues[data.colvariablenames].map(itemkey => {
                countval += data?.tabledata[item][itemkey];
            })
        })
        Setcount(countval)
        setchart(data.charts)
        getData(props.data)
    }, [])

    const getData = async (data) => {
        //let chartObject = await createChartData(data);
        //Setchartdata(chartObject)

    }
    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData,count])

    const onEditHead = (data) => {
        setHead(data)
    }

    return (
        <>
            {data &&
                <div className="tablerol">
                    {
                        editHead ?
                            <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                            :
                            <div className="head" ><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                    }
                    {
                        <table>
                            <tr>
                                <th rowSpan={2}>Parameter</th>
                                {data?.colvariablevalues[data?.colvariablenames].length > 0 ?
                                    <th colSpan={data?.colvariablevalues[data?.colvariablenames].length} scope="colgroup">{data?.colvariablenames}</th>
                                    : ""}
                                <th scope="col" rowSpan={2}>Chi Square Value</th>
                                <th scope="col" rowSpan={2}>P Value</th>
                            </tr>
                            <tr>
                                {
                                    data?.colvariablevalues[data?.colvariablenames].map(item => {
                                        return <th scope="col">{item}</th>
                                    })
                                }
                            </tr>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: "left" }} colSpan={data?.colvariablevalues[data?.colvariablenames].length + 3}>
                                        {data?.rowvariablenames} with {data?.colvariablenames}
                                    </td>
                                </tr>
                                {
                                    data.rowvariablevalues[data.rowvariablenames].map((item, i) => {
                                        return <>
                                            <tr>

                                                <td>{item} (N={data?.colvariablevalues[data.colvariablenames].map(itemkey => {
                                                    let countN = 0;
                                                    countN += data?.tabledata[item][itemkey];
                                                    return countN
                                                })})</td>

                                                {
                                                    data?.colvariablevalues[data?.colvariablenames].map(itemval => {
                                                        return <><td>{data?.tabledata[item][itemval]} ({data?.tabledata[item][itemval + '_per'].toFixed(2)}%)</td>
                                                        </>
                                                    })
                                                }

                                                <td rowSpan={!ChiSqr ? 1 : Object.keys(data?.tabledata).length}>{ChiSqr}</td>
                                                <td rowSpan={!PValue ? 1 : Object.keys(data?.tabledata).length}>{PValue}</td>


                                            </tr>
                                        </>
                                    })

                                }
                                <tr>
                                    <td style={{ textAlign: "left" }} colSpan={data?.colvariablevalues[data?.colvariablenames].length + 3}>
                                        Note : {data?.Note}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    }
                </div>
            }
            {/* {
                chartData && chartData?.map(item => {
                    return item?.charttype == "clusterBarChart" ?
                        <GroupedBarChart
                            yaxistext={"Percentage(%)"}
                            xaxistext={item.xaxis}
                            width={500}
                            height={300}
                            data={item.data}
                            title={""}
                            gridstatus={"visible"} />
                        : ""
                })
            } */}

            <AnalysisChart data={data} type={1} />
            {/* 
            <Row className="chartdata">
                {
                    chartData && chartData.map(item => {
                        return <Col md={6} className="p-1">{item?.charttype == "bar" ?
                            <BarChart
                                data={item.data}
                                width={400}
                                height={200}
                                barstyle={{ color: "blue", hover: "#ccc" }}
                                bardatastyle={{ fontsize: "12px", fontweight: "500", color: "#000" }}
                                gridcolor="#ccc"
                                gridstatus="hidden"
                                yaxistext={"Mean"}
                                yaxislabelstyle={{ fontsize: "12px", fontweight: "200", color: "red" }}
                                yaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                                xaxistext={item.xaxis}
                                xaxislabelstyle={{ fontsize: "10px", fontweight: "400", color: "red", rotate: "-20" }}
                                xaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                                title={""}
                                titlecolor={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                            />

                            : item?.charttype == "pie" ?
                                <PieChart
                                    data={item.data}
                                    width={500}
                                    height={200}
                                    chartStyle={{ hover: "#ccc", fontsize: "14px", fontweight: "500", color: "#fff", height: 1 }}
                                    title={""}
                                    titlecolor={{ fontsize: "14px", fontweight: "600", color: "#000" }}
                                    legends={{ fontsize: "14px", fontweight: "300", color: "#000" }} />
                                : item?.charttype == "clusterBarChart" ?
                                    <GroupedBarChart
                                        yaxistext={"Percentage(%)"}
                                        xaxistext={item.xaxis}
                                        width={500}
                                        height={300}
                                        data={item.data}
                                        title={""}
                                        gridstatus={"visible"} />

                                    : item?.charttype == "stackedBarChart" ?
                                        <StackedBarChart
                                            data={item.data}
                                            width={300}
                                            height={200}
                                            categories={item.categories}
                                            barstyle={{ color: "#80cbc4", hover: "#ccc" }}
                                            bardatastyle={{ fontsize: "16px", fontweight: "500", color: "red", visible: "visible" }}
                                            gridcolor={"#cc1"}
                                            gridstatus={"visible"}
                                            yaxistext={"Mean"}
                                            yaxislabelstyle={{ fontsize: "14px", fontweight: "400", color: "#000" }}
                                            yaxisstyle={{ fontsize: "14px", fontweight: "600", color: "#000", dy: "1em" }}
                                            xaxistext={item.xaxis}
                                            xaxislabelstyle={{ fontsize: "14px", fontweight: "400", color: "red", rotate: "-45" }}
                                            xaxisstyle={{ fontsize: "18px", fontweight: "500", color: "#000", dy: "3em" }}
                                            title={" "}
                                            titlecolor={{ fontsize: "24px", fontweight: "600", color: "green" }} />
                                        : item?.charttype == "lineChart" ?
                                            <LineChart
                                                data={item.data}
                                                width={400}
                                                heigth={300}
                                                scaley={20}
                                                isarray={true}
                                                chartstyle={{ color: "royalblue", hover: "#ccc", linecolor: "#cc5", radius: 4 }}
                                                chartdatastyle={{ fontsize: "16px", fontweight: "500", color: "black", visible: "visible" }}
                                                gridcolor={"#ccc"}
                                                gridstatus={"hidden"}
                                                yaxistext={"Life Exp Y label"}
                                                yaxislabelstyle={{ fontsize: "18px", fontweight: "400", color: "#000" }}
                                                yaxisstyle={{ fontsize: "14px", fontweight: "600", color: "#000" }}
                                                xaxistext={" per capital values "}
                                                xaxislabelstyle={{ fontsize: "18px", fontweight: "400", color: "red", rotate: "-45" }}
                                                xaxisstyle={{ fontsize: "18px", fontweight: "500", color: "#000", dy: "2em" }}
                                                title={""}
                                                titlecolor={"#000"}
                                            />
                                            :
                                            ""}

                        </Col>


                    })
                }
            </Row> */}

        </>
    )
}

/**
 * cross tab col propotion
 */
export function CrossTabCol(props) {
    const [data, setData] = useState(props.data);
    const [chartData, Setchartdata] = useState<any>([]);
    const [count, Setcount] = useState(0);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [ChiSqr, SetChiSqr] = useState("");
    const [PValue, SetPValue] = useState("");


    useEffect(() => {
        let countval = 0;
        data?.rowvariablevalues[data?.rowvariablenames].map(item => {
            if (data?.tabledata[item]['Chi square value']) {
                SetChiSqr(data?.tabledata[item]['Chi square value'].toFixed(2))
            }

            if (data?.tabledata[item]['P Value']) {
                SetPValue(data?.tabledata[item]['P Value'].toFixed(2))
            }

            data?.colvariablevalues[data.colvariablenames].map(itemkey => {
                countval += data?.tabledata[item][itemkey];
            })
        })


        Setcount(countval)
        getData(props.data);
    }, [])

    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData,count])

    const getData = async (data1) => {
        let chartObject = await createChartData(data1);
        Setchartdata(chartObject)
    }

    const onEditHead = (data) => {
        setHead(data)
    }

    return (
        <>
            {data &&
                <div className="tablerol">
                    {
                        editHead ?
                            <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                            :
                            <div className="head" ><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                    }
                    {
                        <table>
                            <tr>
                                <th rowSpan={2}>Parameter</th>
                                {data?.colvariablevalues[data?.colvariablenames].length > 0 ?
                                    <th colSpan={data?.colvariablevalues[data?.colvariablenames].length} scope="colgroup">{data?.colvariablenames}</th>
                                    : ""}
                                <th scope="col" rowSpan={2}>Chi Square Value</th>
                                <th scope="col" rowSpan={2}>P Value</th>
                            </tr>
                            <tr>
                                {
                                    data?.colvariablevalues[data?.colvariablenames].map(item => {
                                        return <th scope="col">{item}</th>
                                    })
                                }
                            </tr>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: "left" }} colSpan={data?.colvariablevalues[data?.colvariablenames].length + 3}>
                                        {data?.rowvariablenames} with {data?.colvariablenames}
                                    </td>
                                </tr>
                                {
                                    Object.keys(data?.tabledata).map((item, i) => {
                                        return <>
                                            <tr>

                                                <td>{item} (N={data?.colvariablevalues[data.colvariablenames].map(itemkey => {
                                                    let countN = 0;
                                                    countN += data?.tabledata[item][itemkey];
                                                    return countN
                                                })})</td>
                                                {
                                                    data?.colvariablevalues[data?.colvariablenames].map(itemval => {
                                                        return <><td>{data?.tabledata[item][itemval]} ({data?.tabledata[item][itemval + '_per'].toFixed(2)}%)</td>
                                                        </>
                                                    })
                                                }
                                                <td rowSpan={!ChiSqr ? 1 : Object.keys(data?.tabledata).length}>{ChiSqr}</td>
                                                <td rowSpan={!PValue ? 1 : Object.keys(data?.tabledata).length}>{PValue}</td>


                                            </tr>
                                        </>
                                    })

                                }
                                <tr>
                                    <td style={{ textAlign: "left" }} colSpan={data?.colvariablevalues[data?.colvariablenames].length + 3}>
                                        Note : {data?.Note}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    }
                    {/* {
                        chartData && chartData?.map(item => {
                            return item?.charttype == "clusterBarChart" ?
                                <GroupedBarChart
                                    yaxistext={"Percentage(%)"}
                                    xaxistext={item.xaxis}
                                    width={500}
                                    height={300}
                                    data={item.data}
                                    title={""}
                                    gridstatus={"visible"} />
                                : ""
                        })
                    } */}

                    {/* <AnalysisChart data={chartData} /> */}
                    <Row className="chartdata">
                        {
                            chartData && chartData.map(item => {
                                return <Col md={6} className="p-1">{item?.charttype == "bar" ?
                                    <BarChart
                                        data={item.data}
                                        width={400}
                                        height={200}
                                        interval={5}
                                        barstyle={{ color: "blue", hover: "#ccc" }}
                                        bardatastyle={{ fontsize: "12px", fontweight: "500", color: "#000" }}
                                        gridcolor="#ccc"
                                        gridstatus="hidden"
                                        yaxistext={"Mean"}
                                        yaxislabelstyle={{ fontsize: "12px", fontweight: "200", color: "red" }}
                                        yaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                                        xaxistext={item.xaxis}
                                        xaxislabelstyle={{ fontsize: "10px", fontweight: "400", color: "red", rotate: "-20" }}
                                        xaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                                        title={""}
                                        titlecolor={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                                    />

                                    : item?.charttype == "pie" ?
                                        <PieChart
                                            data={item.data}
                                            width={500}
                                            height={200}
                                            chartStyle={{ hover: "#ccc", fontsize: "14px", fontweight: "500", color: "#fff", height: 1 }}
                                            title={""}
                                            titlecolor={{ fontsize: "14px", fontweight: "600", color: "#000" }}
                                            legends={{ fontsize: "14px", fontweight: "300", color: "#000" }} />
                                        : item?.charttype == "clusterBarChart" ?
                                            <GroupedBarChart
                                                yaxistext={"Percentage(%)"}
                                                xaxistext={item.xaxis}
                                                width={500}
                                                height={300}
                                                data={item.data}
                                                title={""}
                                                gridstatus={"visible"} />

                                            : item?.charttype == "stackedBarChart" ?
                                                <StackedBarChart
                                                    data={item.data}
                                                    width={300}
                                                    height={200}
                                                    categories={item.categories}
                                                    barstyle={{ color: "#80cbc4", hover: "#ccc" }}
                                                    bardatastyle={{ fontsize: "16px", fontweight: "500", color: "red", visible: "visible" }}
                                                    gridcolor={"#cc1"}
                                                    gridstatus={"visible"}
                                                    yaxistext={"Mean"}
                                                    yaxislabelstyle={{ fontsize: "14px", fontweight: "400", color: "#000" }}
                                                    yaxisstyle={{ fontsize: "14px", fontweight: "600", color: "#000", dy: "1em" }}
                                                    xaxistext={item.xaxis}
                                                    xaxislabelstyle={{ fontsize: "14px", fontweight: "400", color: "red", rotate: "-45" }}
                                                    xaxisstyle={{ fontsize: "18px", fontweight: "500", color: "#000", dy: "3em" }}
                                                    title={" "}
                                                    titlecolor={{ fontsize: "24px", fontweight: "600", color: "green" }} />
                                                : item?.charttype == "lineChart" ?
                                                    <LineChart
                                                        data={item.data}
                                                        width={400}
                                                        heigth={300}
                                                        scaley={20}
                                                        isarray={true}
                                                        chartstyle={{ color: "royalblue", hover: "#ccc", linecolor: "#cc5", radius: 4 }}
                                                        chartdatastyle={{ fontsize: "16px", fontweight: "500", color: "black", visible: "visible" }}
                                                        gridcolor={"#ccc"}
                                                        gridstatus={"hidden"}
                                                        yaxistext={"Life Exp Y label"}
                                                        yaxislabelstyle={{ fontsize: "18px", fontweight: "400", color: "#000" }}
                                                        yaxisstyle={{ fontsize: "14px", fontweight: "600", color: "#000" }}
                                                        xaxistext={" per capital values "}
                                                        xaxislabelstyle={{ fontsize: "18px", fontweight: "400", color: "red", rotate: "-45" }}
                                                        xaxisstyle={{ fontsize: "18px", fontweight: "500", color: "#000", dy: "2em" }}
                                                        title={""}
                                                        titlecolor={"#000"}
                                                    />
                                                    :
                                                    ""}

                                </Col>


                            })
                        }
                    </Row>
                </div>
            }


        </>
    )
}

/**
 * independednt T test
 */
export function IndependentTTest(props) {
    const [data, setData] = useState(props.data);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [count, Setcount] = useState(0);
    const [chartData, Setchartdata] = useState<any>([]);
    useEffect(() => {
        let countval = 0;
        data?.rowvariablevalues.map(item => {
            countval += data?.tabledata[item]['count'];
        })
        Setcount(countval)
       
        getChartData(data)
    }, [])

    const onEditHead = (data) => {
        setHead(data)
    }

    const getChartData = async (data) => {
        let chartdata = await createChartMeanMedian(data)
        Setchartdata(chartdata)
        props.updateComp(chartdata)
        console.log(chartData)
    }

    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData,count])


    return (
        <>
            {data &&
                <div className="tablerol">

                    {
                        editHead ?
                            <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                            :
                            <div className="head" ><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                    }
                    {
                        <table>
                            <tr>
                                <th rowSpan={3}>Parameter</th>
                                <th colSpan={data?.rowvariablevalues.length} scope="colgroup">{data?.rowvariablenames[0]}</th>
                                <th scope="col" rowSpan={3}>P Value</th>
                            </tr>
                            <tr>
                                {
                                    data?.rowvariablevalues.map(item => {
                                        return <th scope="col">{item} (N={data?.tabledata[item]['count']})</th>
                                    })
                                }
                            </tr>
                            <tr>
                                {
                                    data?.rowvariablevalues.map(item => {
                                        return <th scope="col">Mean ± SD</th>
                                    })
                                }
                            </tr>
                            <tbody>


                                <tr>

                                    <td>{data?.colvariablenames[0]}</td>
                                    {
                                        data?.rowvariablevalues.map(item => {
                                            return <>
                                                <td>{data?.tabledata[item]['mean'].toFixed(2)} ± {data?.tabledata[item]['std'].toFixed(2)}</td>
                                            </>
                                        })
                                    }

                                    {
                                        Object.keys(data?.tabledata).map((item, i) => {
                                            return <>
                                                {
                                                    i == 0 ? <td >{data?.tabledata[item]['P Value'].toFixed(2)}</td>
                                                        : ""
                                                }
                                            </>
                                        })
                                    }



                                </tr>

                                <tr>
                                    <td style={{ textAlign: "left" }} colSpan={data?.rowvariablevalues.length + 2}>
                                        Normality Check : {data?.normality_check}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    }
                </div>
            }
            <AnalysisChart data={data} type={2} />

        </>
    )
}

/**
 * independednt T test Row
 */
export function IndependentTTestRow(props) {
    const [data, setData] = useState(props.data);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [count, Setcount] = useState(0);
    const [chartData, Setchartdata] = useState<any>([]);

    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData,count])

    useEffect(() => {
        let countval = 0;
        data?.rowvariablevalues.map(item => {
            countval += data?.tabledata[item]['count'];
        })
        Setcount(countval)
        
        const fetchData = async () => {
            await getChartData(data)
        }

        fetchData();


    }, [])

    const onEditHead = (data) => {
        setHead(data)
    }

    const getChartData = async (data) => {
        let chartdata = await createChartMeanMedian(data)
        Setchartdata(chartdata)
    }

    useEffect(() => {
    }, [chartData,count])

    return (
        <>
            {data &&
                <div className="tablerol">

                    {
                        editHead ?
                            <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                            :
                            <div className="head" ><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                    }
                    {
                        <table>
                            <tr>
                                <th rowSpan={3}>Parameter</th>
                                <th colSpan={data?.colvariablenames.length} scope="colgroup">{data?.colvariablenames[0]}</th>
                                <th scope="col" rowSpan={3}>Mean Difference</th>
                                <th scope="col" rowSpan={3}>Lower CI</th>
                                <th scope="col" rowSpan={3}>Upper CI</th>
                                <th scope="col" rowSpan={3}>P Value</th>
                            </tr>

                            <tr>
                                <th scope="col">Mean ± SD</th>
                            </tr>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: "left" }} colSpan={6}>
                                        {data?.rowvariablenames[0]} with {data?.colvariablenames[0]}
                                    </td>
                                </tr>
                                {
                                    data?.rowvariablevalues.map((item, i) => {
                                        return <>

                                            <tr>

                                                <td>{item}({data?.tabledata[item]['count']})</td>

                                                <td>{data?.tabledata[item]['mean'].toFixed(2)} ± {data?.tabledata[item]['std'].toFixed(2)}</td>


                                                {
                                                    i == 0 ?
                                                        <>

                                                            <td rowSpan={2}>{data?.tabledata[item]['Mean Difference'].toFixed(2)}</td>
                                                            <td rowSpan={2}>{data?.tabledata[item]['Lower CI'].toFixed(2)}</td>
                                                            <td rowSpan={2}>{data?.tabledata[item]['Upper CI'].toFixed(2)}</td>
                                                            <td rowSpan={2}>{data?.tabledata[item]['P Value'].toFixed(2)}</td>


                                                        </>

                                                        : ""
                                                }



                                            </tr>
                                        </>
                                    })
                                }
                                <tr>
                                    <td style={{ textAlign: "left" }} colSpan={6}>
                                        Normality Check : {data?.normality_check}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    }
                </div>
            }

            <AnalysisChart data={data} type={2} />

            {/* <Row className="chartdata">
                {
                    chartData && chartData.map(item => {
                        return <Col md={6} className="p-1">{item?.charttype == "bar" ?
                            <BarChart
                                data={item.data}
                                width={400}
                                height={200}
                                barstyle={{ color: "blue", hover: "#ccc" }}
                                bardatastyle={{ fontsize: "12px", fontweight: "500", color: "#000" }}
                                gridcolor="#ccc"
                                gridstatus="hidden"
                                yaxistext={"Mean"}
                                yaxislabelstyle={{ fontsize: "12px", fontweight: "200", color: "red" }}
                                yaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                                xaxistext={item.xaxis}
                                xaxislabelstyle={{ fontsize: "10px", fontweight: "400", color: "red", rotate: "-20" }}
                                xaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                                title={""}
                                titlecolor={{ fontsize: "12px", fontweight: "600", color: "#000" }}
                            />

                            : item?.charttype == "pie" ?
                                <PieChart
                                    data={item.data}
                                    width={500}
                                    height={200}
                                    chartStyle={{ hover: "#ccc", fontsize: "14px", fontweight: "500", color: "#fff", height: 1 }}
                                    title={""}
                                    titlecolor={{ fontsize: "14px", fontweight: "600", color: "#000" }}
                                    legends={{ fontsize: "14px", fontweight: "300", color: "#000" }} />
                                : item?.charttype == "clusterBarChart" ?
                                    <GroupedBarChart
                                        yaxistext={"Percentage(%)"}
                                        xaxistext={item.xaxis}
                                        width={500}
                                        height={300}
                                        data={item.data}
                                        title={""}
                                        gridstatus={"visible"} />

                                    : item?.charttype == "stackedBarChart" ?
                                        <StackedBarChart
                                            data={item.data}
                                            width={300}
                                            height={200}
                                            categories={item.categories}
                                            barstyle={{ color: "#80cbc4", hover: "#ccc" }}
                                            bardatastyle={{ fontsize: "16px", fontweight: "500", color: "red", visible: "visible" }}
                                            gridcolor={"#cc1"}
                                            gridstatus={"visible"}
                                            yaxistext={"Mean"}
                                            yaxislabelstyle={{ fontsize: "14px", fontweight: "400", color: "#000" }}
                                            yaxisstyle={{ fontsize: "14px", fontweight: "600", color: "#000", dy: "1em" }}
                                            xaxistext={item.xaxis}
                                            xaxislabelstyle={{ fontsize: "14px", fontweight: "400", color: "red", rotate: "-45" }}
                                            xaxisstyle={{ fontsize: "18px", fontweight: "500", color: "#000", dy: "3em" }}
                                            title={" "}
                                            titlecolor={{ fontsize: "24px", fontweight: "600", color: "green" }} />
                                        : item?.charttype == "lineChart" ?
                                            <LineChart
                                                data={item.data}
                                                width={400}
                                                heigth={300}
                                                scaley={20}
                                                isarray={true}
                                                chartstyle={{ color: "royalblue", hover: "#ccc", linecolor: "#cc5", radius: 4 }}
                                                chartdatastyle={{ fontsize: "16px", fontweight: "500", color: "black", visible: "visible" }}
                                                gridcolor={"#ccc"}
                                                gridstatus={"hidden"}
                                                yaxistext={"Life Exp Y label"}
                                                yaxislabelstyle={{ fontsize: "18px", fontweight: "400", color: "#000" }}
                                                yaxisstyle={{ fontsize: "14px", fontweight: "600", color: "#000" }}
                                                xaxistext={" per capital values "}
                                                xaxislabelstyle={{ fontsize: "18px", fontweight: "400", color: "red", rotate: "-45" }}
                                                xaxisstyle={{ fontsize: "18px", fontweight: "500", color: "#000", dy: "2em" }}
                                                title={""}
                                                titlecolor={"#000"}
                                            />
                                            :
                                            ""}

                        </Col>


                    })
                }
            </Row> */}
        </>
    )
}


/**
 * Mann Whitney test
 */
export function MannWhitney(props) {
    const [data, setData] = useState(props.data);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [count, Setcount] = useState(0);
    const [chartData, Setchartdata] = useState<any>([]);


    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData,count])

    useEffect(() => {
        let countval = 0;
        data?.rowvariablevalues.map(item => {
            countval += data?.tabledata[item]['count'];
        })
        Setcount(countval)

     

        getChartData(data)
    }, [])

    const getChartData = async (data) => {
        let chartdata = await createChartMedian(data)
        Setchartdata(chartdata)
    }

    const onEditHead = (data) => {
        setHead(data)
    }
    return (
        <>
            {data &&
                <div className="tablerol">
                    {
                        editHead ?
                            <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                            :
                            <div className="head" ><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                    }
                    {
                        <table>
                            <tr>
                                <th rowSpan={3}>Parameter</th>
                                <th colSpan={data?.colvariablenames.length} scope="colgroup">{data?.colvariablenames[0]}</th>
                                <th scope="col" rowSpan={3}>P Value</th>
                            </tr>

                            <tr>
                                <th scope="col">Median(IQR)</th>
                            </tr>
                            <tbody>

                                {
                                    data?.rowvariablevalues.map((item, i) => {
                                        return <>
                                            <tr>
                                                <td>{item}(N={data?.tabledata[item]['count']})</td>
                                                <td>{data?.tabledata[item]['median'].toFixed(2)} {data?.tabledata[item]['Quartiles']}</td>
                                                {
                                                    i == 0 ?
                                                        <>
                                                            <td rowSpan={2}>{data?.tabledata[item]['P Value'].toFixed(4)}</td>
                                                        </>
                                                        : ""
                                                }
                                            </tr>
                                        </>
                                    })
                                }
                                <tr>
                                    <td style={{ textAlign: "left" }} colSpan={6}>
                                        Normality Check : {data?.normality_check}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    }
                </div>
            }

            <AnalysisChart data={data} type={3} />

        </>
    )
}


/**
 * One way Annova
 */
export function OneWayAnnova(props) {
    const [data, setData] = useState(props.data);
    const [chartData, Setchartdata] = useState<any>([]);
    const [count, Setcount] = useState(0);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [categories, SetCategories] = useState([]);

    useEffect(() => {
        let countval = 0;
        data?.rowvariablevalues.map(item => {
            countval += data?.tabledata[item]['count'];
        })
        Setcount(countval)
        
        getChartData(data)
    }, [data])

    const getChartData = async (data) => {
        let chartdata = await createChartMeanMedian(data)
        Setchartdata(chartdata)
    }

    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData,count])

    const onEditHead = (data) => {
        setHead(data)
    }

    return (
        <>
            {data &&
                <div className="tablerol">
                    {
                        editHead ?
                            <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                            :
                            <div className="head" ><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                    }
                    {
                        <table>
                            <tr>
                                <th rowSpan={3}>Parameter</th>
                                <th colSpan={data?.rowvariablevalues?.length} scope="colgroup">{data?.rowvariablenames[0]}</th>
                                <th scope="col" rowSpan={3}>P Value</th>
                            </tr>
                            <tr>
                                {
                                    data?.rowvariablevalues.map(item => {
                                        return <th scope="col">{item} (N={data?.tabledata[item]['count']})</th>
                                    })
                                }
                            </tr>
                            <tr>
                                {
                                    data?.rowvariablevalues.map(item => {
                                        return <th scope="col">Mean ± SD</th>
                                    })
                                }
                            </tr>
                            <tbody>


                                <tr>

                                    <td>{data?.colvariablenames[0]}</td>
                                    {
                                        data?.rowvariablevalues.map(item => {
                                            return <>
                                                <td>{data?.tabledata[item]['mean'].toFixed(2)} ± {data?.tabledata[item]['std'].toFixed(2)}</td>
                                            </>
                                        })
                                    }

                                    {
                                        Object.keys(data?.tabledata).map((item, i) => {
                                            return <>
                                                {
                                                    Object.keys(data?.tabledata).length == i + 1 ? <td>{data?.tabledata[item]?.['P Value (simple)'] && data?.tabledata[item]?.['P Value (simple)'].toFixed(4)}</td>
                                                        : ""
                                                }
                                            </>
                                        })
                                    }
                                </tr>

                                <tr>
                                    <td style={{ textAlign: "left" }} colSpan={data?.rowvariablevalues.length + 2}>
                                        Normality Check : {data?.normality_check}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    }
                </div>
            }

            <AnalysisChart data={data} type={2} />
       

        </>
    )
}

/**
 * One way onnova  Row
 */
export function OneWayAnnovaRow(props) {
    const [data, setData] = useState(props.data);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [chartData, Setchartdata] = useState<any>([]);
    const [count, Setcount] = useState(0);

    useEffect(() => {
        let countval = 0;
        data?.rowvariablevalues.map(item => {
            countval += data?.tabledata[item]['count'];
        })
        Setcount(countval)
      
        getChartData(data)
    }, [])

    const onEditHead = (data) => {
        setHead(data)
    }

    const getChartData = async (data) => {
        let chartdata = await createChartMeanMedian(data)
        Setchartdata(chartdata)
    }

    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)

    }, [chartData,count])

    return (
        <>
            {data &&
                <div className="tablerol">
                    {
                        editHead ?
                            <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                            :
                            <div className="head" ><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                    }
                    {
                        <table>
                            <tr>
                                <th rowSpan={3}>Parameter</th>
                                <th colSpan={data?.colvariablenames.length} scope="colgroup">{data?.colvariablenames[0]}</th>
                                <th scope="col" rowSpan={3}>Mean Difference</th>
                                <th scope="col" rowSpan={3}>Lower CI</th>
                                <th scope="col" rowSpan={3}>Upper CI</th>
                                <th scope="col" rowSpan={3}>P Value</th>
                            </tr>

                            <tr>
                                <th scope="col">Mean ± SD</th>
                            </tr>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: "left" }} colSpan={6}>
                                        {data?.rowvariablenames[0]} with {data?.colvariablenames[0]}
                                    </td>
                                </tr>
                                {
                                    data?.rowvariablevalues.map((item, i) => {
                                        return <>
                                            <tr>
                                                <td>{item}({data?.tabledata[item]['count']})</td>
                                                <td>{data?.tabledata[item]['mean'].toFixed(2)} ± {data?.tabledata[item]['std'].toFixed(2)}</td>
                                                <>
                                                    <td >{data?.tabledata[item]['Mean Difference'] && data?.tabledata[item]['Mean Difference'].toFixed(2)}</td>
                                                    <td>{data?.tabledata[item]['Lower CI'] && data?.tabledata[item]['Lower CI'].toFixed(2)}</td>
                                                    <td >{data?.tabledata[item]['Upper CI'] && data?.tabledata[item]['Upper CI'].toFixed(2)}</td>
                                                    <td >{data?.tabledata[item]['P Value (simple)'] && data?.tabledata[item]['P Value (simple)'].toFixed(2)}</td>
                                                </>
                                            </tr>
                                        </>
                                    })
                                }
                                <tr>
                                    <td style={{ textAlign: "left" }} colSpan={6}>
                                        Normality Check : {data?.normality_check}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    }
                </div>
            }

            <AnalysisChart data={data} type={2} />
          

        </>
    )
}

/**
 * Kruskal Wallis test
 */
export function KruskalWallis(props) {
    const [data, setData] = useState(props.data);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [count, Setcount] = useState(0);
    const [chartData, Setchartdata] = useState<any>([]);

    useEffect(() => {
        let countval = 0;
        data?.rowvariablevalues.map(item => {
            countval += data?.tabledata[item]['count'];
        })
        Setcount(countval)

        getChartData(data)
    }, [])

    const onEditHead = (data) => {
        setHead(data)
    }

    const getChartData = async (data) => {
        let chartdata = await createChartMedian(data)
        Setchartdata(chartdata)
    }

    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData,count])



    return (
        <>
            {data &&
                <div className="tablerol">
                    {
                        editHead ?
                            <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                            :
                            <div className="head" ><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                    }
                    {
                        <table>
                            <tr>
                                <th rowSpan={3}>Parameter</th>
                                <th colSpan={data?.colvariablenames.length} scope="colgroup">{data?.colvariablenames[0]}</th>
                                <th scope="col" rowSpan={3}>Kruskal Wallis Test(P Value)</th>
                            </tr>

                            <tr>
                                <th scope="col">Median(IQR)</th>
                            </tr>
                            <tbody>

                                {
                                    data?.rowvariablevalues.map((item, i) => {
                                        return <>

                                            <tr>

                                                <td>{item}(N={data?.tabledata[item]['count']})</td>

                                                <td>{data?.tabledata[item]['median'].toFixed(2)} {data?.tabledata[item]['Quartiles']}</td>

                                                {
                                                    i == 0 ?
                                                        <>
                                                            <td rowSpan={data?.rowvariablevalues.length}>{data?.tabledata[item]['P Value'].toFixed(4)}</td>
                                                        </>
                                                        : ""
                                                }

                                            </tr>
                                        </>
                                    })
                                }
                                <tr>
                                    <td style={{ textAlign: "left" }} colSpan={6}>
                                        Normality Check : {data?.normality_check}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    }
                </div>
            }

            <AnalysisChart data={data} type={3} />

        </>
    )
}


