import { FIELD_TYPES } from "../../widgets/fields";

export const FormElements = {
    otp: {
        name: "otp",
        placeholder: "Enter",
        label: "OTP",
        type: FIELD_TYPES.NUMBER,
        isMandatory: true
    },
    userName: {
        name: "userName",
        placeholder: "Enter",
        label: "Email/Mobile",
        isMandatory: true,
        icon: "profile",
        regex: /^(?:\d{10}|[a-zA-Z0-9.\_%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15})$/,
        messages: {
            regex: 'Invalid format'
        }
    },
    password: {
        name: "password",
        placeholder: "Enter Password",
        label: "Password",
        isMandatory: true
    },
    currentPassword: {
        name: "currentPassword",
        placeholder: "Enter Password",
        label: "Password",
        isMandatory: true
    },
    confirmPassword: {
        name: "confirmPassword",
        placeholder: "Confirm Password",
        label: "Confirm Password",
        isMandatory: true
    },
};