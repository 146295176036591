import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

const HorizontalBarChart = ({barstyle,data,bardatastyle,title,titlecolor,width,height,yaxislabelstyle,xaxislabelstyle,gridcolor,gridstatus,yaxisstyle,xaxisstyle,xaxistext,yaxistext}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!data || data.length === 0) return;

    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
   // const width = 1000 - margin.left - margin.right;
    //const height = 600 - margin.top - margin.bottom;

    const x = d3.scaleLinear().range([0, width]);

    const y = d3
      .scaleBand()
      .range([height, 0])
      .padding(0.1);

    const xAxis = d3.axisBottom(x);
    const yAxis = d3.axisLeft(y);

   
    const svg = d3
      .select(chartRef.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .style("overflow", "visible")
      .style('fill',barstyle.color)
      .attr('transform', `translate(${margin.left * 6},${margin.top + margin.top})`)
     ;
     
      // Draw gridlines
    svg
    .append('g')
    .attr('class', 'grid')
    .style('color',gridcolor)
    .attr('transform', `translate(0,${height})`)
    .style("stroke-dasharray", "5 5")
    .style("visibility",gridstatus)
    .call(d3.axisBottom(x).tickSize(-height).tickFormat(''));

    

    x.domain([0, d3.max(data, (d) => d.value)]);
    y.domain(data.map((d) => d.label));

      
      
    svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0,${height})`)
      .attr('style',`  white-space: nowrap; overflow: hidden;  text-overflow: ellipsis; color:${xaxislabelstyle.color};
      textfont-weight: ${xaxislabelstyle.fontweight}; font-size:${xaxislabelstyle.fontsize}`)
      .call(xAxis);

    const yaxistxt = svg.append('g')
      .attr('class', 'y-axis')
      .call(yAxis)
      ;

      yaxistxt
      .selectAll("text")
      .style("text-anchor", "middle") 
      .attr("x","-1em")
      .attr("transform",`rotate(${yaxislabelstyle.rotate})`)
      .attr('style',`  white-space: nowrap; overflow: hidden;  text-overflow: ellipsis; color:${yaxislabelstyle.color};
                        textfont-weight: ${yaxislabelstyle.fontweight}; font-size:${yaxislabelstyle.fontsize}`)


        // Create tooltip
          const tooltip = d3
          .select(chartRef.current)
          .append('div')
          .style('position', 'absolute')
          .style('background', '#f4f4f4')
          .style('padding', '5px')
          .style('border', '1px solid #ccc')
          .style('border-radius', '5px')
          .style('opacity', 0);
          
      //select bar
    svg
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('y', (d) => y(d.label))
      .attr('height', y.bandwidth())
      .attr('x', 0)
      .attr('width', (d) => x(d.value))
      .attr('transform',`translate(2)`)
      .on("mouseenter",function(d){
        d3.select(d.currentTarget)
        .style("fill", barstyle.hover)
          .transition()
          .duration(200)
          .attr('opacity', 0.6)
          .attr('y', (a) => y(a.label))
          .attr('height', y.bandwidth()+2)
          .attr("font-size","30")


          tooltip.transition().duration(200).style('opacity', 0.9);
          tooltip
            .html(`<strong>Value:</strong> ${d.target.__data__.value}`)
            .style('left', `${d.pageX}px`)
            .style('top', `${d.pageY - 28}px`);
  
        })
        .on("mouseleave",function(d){
          d3.select(d.currentTarget)
          .style("fill", barstyle.color)
            .transition()
            .duration(200)
            .attr('opacity', 1)
            .attr('y', (a) => y(a.label))
            .attr('height', y.bandwidth());
            
            //remove tooltip
            tooltip.transition().duration(500).style('opacity', 0);
          });


        //add x axis label
        svg.append('text')
        .attr("class", "x label")
        .attr("text-anchor", "middle")
        .attr("x", width/2)
        .attr("y", height - 2)
        .attr("dy", xaxisstyle.dy)
        .attr("dx",width/10 - margin.left - margin.left)
        .style('fill',xaxisstyle.color)
        .style('font-weight',xaxisstyle.fontweight)
        .style('font-size',xaxisstyle.fontsize)
        .text(xaxistext);
   
        //add y axis label
        svg.append("text")
        .attr("class", "y label")
        .attr('transform', `translate(20)`)
        .attr("text-anchor", "end")
        .attr("y", 6)
        .attr("dy", "-6em")
        .attr("x",-height/3)
        .attr("transform", "rotate(-90)")
        .style('fill',yaxisstyle.color)
        .style('font-weight',yaxisstyle.fontweight)
        .style('font-size',yaxisstyle.fontsize)
        .text(yaxistext);

   // Add values on top of bars
   const topBarValue =  svg
    .selectAll('.bar-value')
    .data(data)
    .enter()
    .append('text')
    .attr('class', 'bar-value')
    .attr('transform', `translate(${margin.left},${margin.bottom})`)
    .attr('y', (d) => y(d.label) - 10)
    .attr('x', (d) => x(d.value) - margin.left - margin.top)
    .attr('text-anchor', 'middle')
    .style('fill',bardatastyle.color)
    .style('font-weight',bardatastyle.fontweight)
    .style('font-size',bardatastyle.fontsize)
    .style('visibility',bardatastyle.visible)
    .text((d) => d.value);

     //title of the chart
     svg.append('text')
     .attr('class', 'title')
     .attr('x', width / 2)
     .attr('y', -margin.top)
     .attr('text-anchor', 'middle')
     .style('font-size',titlecolor.fontsize)
     .style('font-weight',titlecolor.fontweight)
     .style('fill',titlecolor.color)
     .text(title)
    return () => {
      d3.select(chartRef.current).selectAll('*').remove();
    };
  }, [data]);

  return <div ref={chartRef}></div>;
};

HorizontalBarChart.prototype={
  data:PropTypes.oneOfType([PropTypes.array,PropTypes.object]),
  width:PropTypes.number,
  heigth:PropTypes.number,
  barstyle:PropTypes.object,
  bardatastyle:PropTypes.object,
  gridcolor:PropTypes.string,
  gridstatus:PropTypes.string,
  yaxistext:PropTypes.string,
  yaxislabelstyle:PropTypes.object,
  yaxisstyle:PropTypes.object,
  xaxistext:PropTypes.string,
  xaxislabelstyle:PropTypes.object,
  xaxisstyle:PropTypes.object,
  title:PropTypes.string,
  titlecolor:PropTypes.object
}

HorizontalBarChart.defaultProps ={
  data:[
    { label: 'param1', value: 30 },
    { label: 'param2', value: 50 }],
  width:"600", 
  height:"400", 
  barstyle:{color:"#000",hover:"#ccc"},
  bardatastyle:{fontsize:"16px",fontweight:"500",color:"#000"},
  gridcolor:"#ccc",
  gridstatus:"hidden",
  yaxistext:"Y Label",
  yaxislabelstyle:{fontsize:"14px",fontweight:"400",color:"red"},
  yaxisstyle:{fontsize:"14px",fontweight:"600",color:"#000"},
  xaxistext:"X Label",
  xaxislabelstyle:{fontsize:"14px",fontweight:"400",color:"red"},
  xaxisstyle:{fontsize:"14px",fontweight:"600",color:"#000"},
  title:"Bar Chart",
  titlecolor:{fontsize:"24px",fontweight:"600",color:"#000"}
}

export default HorizontalBarChart;
