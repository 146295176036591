import { useEffect, useState } from "react"
import CustomTable from "../../widgets/table";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { getAnalysisList } from "./apiController";
import { Col, Row } from "reactstrap";
import { Pagination } from 'antd';
import ConfigureAnalysis from "./ConfigureAnalysis";
import { BarChartOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AnalysisReport from "./AnalysisReport";

export default function DataAnalysisTable(props) {
    const [tableData, SettableData] = useState<any>([]);
    const [popupType, SetPopupType] = useState<any>("");
    const [pageSize, SetPageSize] = useState(10);
    const [pageNum, SetPageNum] = useState(1);
    const [totalCount, SetCount] = useState(0);
    const [selectedItem, SetSelectedItem] = useState<any>({});
    const dispatch = useDispatch();
    const { data } = props;
    const navigate = useNavigate();

    useEffect(() => {
        getAnalysis(pageNum);
    }, [])


    /**
     * get analysis list
     */
    const getAnalysis = async (pagenum) => {
        try {
            dispatch(setLoading(true));
            let analysisListResponse = await getAnalysisList(props?.group.id, pageSize, pagenum);
            SettableData(analysisListResponse.data)
            if (!totalCount) {
                SetCount(analysisListResponse?.total_count);
            }
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(setLoading(false));
        }
    }

    /**
  * columns
  */
    const columns = [
        {
            id: "name",
            label: "Analysis Name",
            accessor: "name",
            sort: "asc",
        },
        {
            id: "created_on",
            label: "Created On",
            accessor: "created_on",
            sort: "asc",
        },
        {
            id: "type",
            label: "Analysis Type",
            accessor: "type",
            sort: "asc",
        },
        {
            id: "updated_on",
            label: "Updated On",
            accessor: "updated_on",
            sort: "asc",
        },
        {
            id: "view",
            label: "View",
            width: "50",
            className: "text-center",
            Cell: ({ row, data }) => {
                const { original } = row;
                return (
                    <i
                        className="icon icon-eye"
                        onClick={() => onAction(2, original)}
                    ></i>
                );
            },
        },
        {
            id: "analysis",
            label: "Analysis",
            width: "50",
            className: "text-center",
            Cell: ({ row, data }) => {
                const { original } = row;
                return (
                    <BarChartOutlined onClick={() => onAction(3, original)} />
                );
            },
        }
    ];

    /**
     * actions to table buttons
     * @param type 
     * @param data 
     */
    const onAction = async (type, data) => {
        switch (type) {
            case 4:
                SetPopupType("configure");
                break;
            case 2:
                console.log(data)
                SetSelectedItem(data)
                SetPopupType("configure");
                break;
            case 3:
                SetPopupType('analysis');
                break;
            default:
                SetPopupType("")
        }
    }

    function onShowSizeChange(e) {
        console.log(e);
        getAnalysis(e);
    }

    const handleSizeChange = (current, pagesize) => {
        console.log(current, pagesize);
        SetPageSize(pagesize);
        //getAnalysis();
    };

    const onClose = () => {
        SetPopupType("");
        SetSelectedItem([]);
        getAnalysis(pageNum);
    }


    return (
        <>
            {popupType == "configure" ?
                <ConfigureAnalysis data={data} selectedItem={selectedItem} {...props} onClose={() => onClose()} />
                :
                popupType == "analysis" ? 
                <AnalysisReport data={data} selectedItem={selectedItem} {...props} onClose={() => onClose()} />
                :
                <div className="analysis-table-container">
                    <div className="header-container">
                        <Row className='m-0'>
                            <Col className='pl-3 mt-3'>
                                <div className='form-name'>
                                    <img className='profile-image' alt='Profile' src={`${process.env.PUBLIC_URL}/images/logo.png`} />
                                    Data Analysis
                                </div>
                            </Col>
                            <Col className='pr-4 mt-4 text-right'>
                                <i className='icon icon-close' onClick={() => props.onClose()} />
                            </Col>
                        </Row>
                    </div>
                    <div className='p-4'>
                        <CustomTable
                            header="Records"
                            data={tableData && tableData || []}
                            tableProps={columns}
                            isSearch={false}
                            isFilter={false}
                            isView={false}
                            isMerge={false}
                            isCreate={true}
                            pagination={true}
                            onAction={(type, data = null) => onAction(type, data)}
                        ></CustomTable>

                    </div>
                    <div className='viewdata-pagination'><Pagination onChange={(e) => onShowSizeChange(e)} total={totalCount} onShowSizeChange={handleSizeChange} /></div>

                </div>
            }

        </>
    )
}