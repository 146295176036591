
import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import PropTypes from 'prop-types';

const LineChart = (props) => {
  const [data, setData] = useState(props.data);
  const [edit,Setedit] = useState(false)
  const {isarray,title,chartstyle,titlecolor,width,height,scaley,gridcolor,gridstatus,yaxistitlecolor,xaxistitlecolor,xaxistext,yaxistext} = props;
  const svgRef = useRef(null);

  useEffect(() => {

    console.log(data)
    // setting up svg
    const w = width;
    const h = height;
    const margin = { top: 20, right: 30, bottom: 30, left: 50 };

    // const svg = d3
    //   .select(svgRef.current)
    //   .attr("width", w)
    //   .attr("height", h)
    //   .style("overflow", "visible")
    //   .style("background", "#fff")
 // Create SVG container
 const svg = d3
 .select(svgRef.current)
 .append('svg')
 .attr('width', width + margin.left + margin.right)
 .attr('height', height + margin.top + margin.bottom)
 .append('g')
 .attr('transform', `translate(${width/2},${height/2})`);


      // Add title
      svg.append('text')
      .attr('y', margin.left/2)
      .attr('x', 0 - height / 5 + margin.left * 3 )
      .attr('dy','-3em')
      .attr('text-anchor', 'start')
      .style('font-size', '16px')
      .style('fill','#000')
      .style('text-decoration', 'underline')
      .text('Line Chart with Dots and Values');

      // setting the scaleing
      // xscales
      const xScale = isarray ? 
            d3
            .scaleBand()
            .domain(data?.map((d) => d.x))
            .range([0, w])
            .padding(0.1) 
      : 
            d3
            .scaleLinear()
            .domain([0, data.length - 1 + 1])
            .range([0, w]);

    //yscales
    const yScale = isarray ? 
                  d3.scaleLinear().domain([0, d3.max(data, (d) => d.y+scaley)]).range([h, 0])
                   : 
                  d3.scaleLinear().domain([0, h/2]).range([h, 0]);

    // Draw gridlines
    svg
    .append('g')
    .attr('class', 'grid')
    .style('color',gridcolor)
    .attr('transform', `translate(2,${h})`)
    .style("stroke-dasharray", "5 5")
    .style("visibility",gridstatus)
    .call(d3.axisBottom(xScale).tickSize(-h).tickFormat(''));

    svg
      .append('g')
      .attr('class', 'grid')
      .attr("transform", `translate(${1})`)   
      .style("stroke-dasharray", "5 5")
      .style("color",gridcolor)
      .style("visibility",gridstatus)
      .call(d3.axisLeft(yScale).tickSize(-w).tickFormat(''));

       //add x axis label
       svg.append('text')
       .attr("class", "x label")
       .attr("text-anchor", "middle")
       .attr("x", w/2)
       .attr("y", h - 2)
       .attr("dy", "3em")
       .attr("dx",- w / 5)
       .attr("fill", xaxistitlecolor) 
       .text(xaxistext);

      //add y axis label
      svg.append("text")
      .attr("class", "y label")
      .attr("text-anchor", "end")
      .attr("y", 6)
      .attr("dy", "-3em")
      .attr("x",-h/2)
      .attr("transform", "rotate(-90)")
      .attr("fill", yaxistitlecolor) 
      .text(yaxistext);

       
    //Setup functions to draw Lines
    const generateScaledLine = d3
      .line()
      .x((d, i) => xScale(i))
      .y(yScale)
      .curve(d3.curveCardinal);

    // setting the axes
    //change ticks values for updating the values between
    const xAxis = d3
      .axisBottom(xScale)
      .ticks(data.length)
      .tickFormat((i) => i);
    const yAxis = d3.axisLeft(yScale).ticks(7);

    svg.append("g").call(xAxis);
    svg.append("g").call(yAxis);
    


    const line = svg.append('line')
    .attr('stroke', chartstyle.linecolor)
    .style("stroke-dasharray", "5 5")
    .attr('stroke-width', 2);


    const linehorizontal = svg.append('line')
    .attr('stroke', chartstyle.linecolor)
    .style("stroke-dasharray", "5 5")
    .attr('stroke-width', 2);

     // Draw dots for data points
     //if json isarray get values from x and y
     svg.selectAll(".dot")
     .data(data)
     .enter().append("circle")
     .attr("class", "dot")
     .attr("cx", isarray ? (d) => xScale(d.x) + xScale.bandwidth() / 2 : (d, i) => xScale(i))
     .attr("cy", isarray ? d => yScale(d.y) : d => yScale(d))
     .attr("fill","red")
     .attr("r", chartstyle.radius)
     .on("mouseenter", (event, d) => {
       // Show tooltip on mouseover
       console.log(event)
       const [x, y] = d3.pointer(event);

        // Update line coordinates to follow the mouse
       // Update line position
       line.attr('x1', x)
       .attr('y1', 0)
       .attr('x2', x)
       .attr('y2', height)
       .style('display', 'block'); 
      
       linehorizontal.attr('x1', 0)
       .attr('y1', y)
       .attr('x2', width)
       .attr('y2', y)
       .style('display', 'block');

       d3.select('#tooltip')
         .style('display', 'block')
         .style('left', event.pageX + 'px')
         .style('top', event.pageY + 'px')
         .text(d);


     })
     .on("mouseout", () => {
       // Hide tooltip on mouseout
       tooltip.transition()
         .duration(500)
         .style("opacity", 0);
         line.style('display', 'none');
         linehorizontal.style('display', 'none');

         
     });

      // Create tooltip element
      const tooltip = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);


      /**
       * add values to data points
       */
        svg.selectAll(".text")
        .data(data)
        .enter().append("text")
        .attr("class", "text")
        .attr("x", isarray  ? (d) => xScale(d.x) + xScale.bandwidth() / 2 : (d, i) => xScale(i))
        .attr("y", isarray  ? d => yScale(d.y) - 10 : d => yScale(d) - 10)
        .text(d => isarray  ? d.y : d)
        .attr("text-anchor", "middle");
    
  //  Draw the line if array of object with x and y values 
  //  draw if statement if values are array of elements execute else
   if(isarray){
            const line = d3
            .line()
            .x((d) => xScale(d.x) + xScale.bandwidth() / 2)
            .y((d) => yScale(d.y));

          svg
            .append('path')
            .datum(data)
            .attr('fill', 'none')
            .attr('stroke', chartstyle.color)
            .attr('stroke-width', 2)
            .attr('d', line); 
    
    }else{    
          const line = d3
          .line()
          .x((d, i) => xScale(i))
          .y(yScale);
        
          // setting up the data for the svg
          svg
            .selectAll(".line")
            .data([data])
            .join("path")
            .attr("d", (d) => line(d))
            .attr('fill', 'none')
            .attr('stroke', chartstyle.color)
            .attr('stroke-width', 2);

      
   }

   return () => {
    d3.select(svgRef.current).selectAll('*').remove();
  };
}, [data]);
  
  return (
    <div ref={svgRef}></div>

    
  );
};

/**
 * prop types
 */
LineChart.propTypes = {
  data:PropTypes.oneOfType([PropTypes.array]),
  width:PropTypes.number,
  heigth:PropTypes.number,
  isarray:PropTypes.bool,
  scaley:PropTypes.number,
  chartstyle:PropTypes.object,
  chartdatastyle:PropTypes.object,
  yaxislabelstyle:PropTypes.object,
  yaxisstyle:PropTypes.object,
  xaxislabelstyle:PropTypes.object,
  xaxisstyle:PropTypes.object,
  gridcolor:PropTypes.string,
  gridstatus:PropTypes.string,
  yaxistext:PropTypes.string,
  xaxistext:PropTypes.string,
  yaxistitlecolor:PropTypes.string,
  xaxistitlecolor:PropTypes.string,
  title:PropTypes.string,
  titlecolor:PropTypes.string
}

/**
 * Default values for props
 * if isarray true : data format should be data = [
    { x: 1, y: 10 },
    { x: 2, y: 20 }]
    if isarray is false data format should be data = [25, 70, 11]
    isarray is used to select the data format either array of objects with x,y values or 
    array of elements
 */
LineChart.defaultProps = {
  data:[
    { x: 1, y: 10 },
    { x: 2, y: 20 },
    ],
  width:400,
  heigth:200,
  isarray:true,
  scaley:0,
  chartstyle:{color:"royalblue",hover:"#ccc",dotradius:5},
  chartdatastyle:{fontsize:"16px",fontweight:"500",color:"black",visible:"visible"},
  yaxislabelstyle:{ fontsize: "18px", fontweight: "400", color: "#000" },
  yaxisstyle:{fontsize: "14px", fontweight: "600", color: "#000" },
  xaxislabelstyle:{ fontsize: "18px", fontweight: "400", color: "#000" },
  xaxisstyle:{fontsize: "14px", fontweight: "600", color: "#000" },
  gridcolor:"#ccc",
  gridstatus:"visible",
  yaxistext:"",
  xaxistext:"",
  yaxistitlecolor:"#000",
  xaxistitlecolor:"#000",
  title:"Line Chart",
  titlecolor:"#000"
}
export default LineChart;