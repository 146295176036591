import { Pagination } from 'antd';
import _, { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap'
import { setLoading } from '../../store/slices/auth';
import CustomTable from '../../widgets/table';
import { downloadFieldTemplate, downloadFormData, getFormRecords, getFormVariables, uploadRecord } from '../dataCollection/apiController';
import { FileExcelFilled } from '@ant-design/icons';
// import {exportAsExcelFile} from '../../utils/Excel';
import { saveAs } from 'file-saver';
import moment from 'moment';
import GroupVariable from './GroupVariablesModal';
import ConfigureAnalysis from './ConfigureAnalysis';
import DataAnalysisTable from './DataAnalysisTable';
import GroupVariableTable from './GroupVariableTable';

export default function ViewFormData(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [action, setaction] = useState('');
    const [records, setrecords] = useState([]);
    const [columns, setcolumns] = useState([]);
    const [responseData, setresponseData] = useState<any | []>([]);
    const [formVariables, setformVariables] = useState<any | []>([]);
    const [isCallApi, setisCallApi] = useState(false);
    const [pageSize, SetPageSize] = useState(10);
    const [selectedIndex, SetselectedIndex] = useState<any>(null);
    const [projectData, setprojectData] = useState<any | null>(props.projectData);

    useEffect(() => {
        fetchData(props.formData.id, 1, pageSize);
    }, []);

    const fetchData = async (id, page, size) => {
        try {
            dispatch(setLoading(true));
            let count = props.formPageCount / page;
            if (count < 10) {
                count = props.formPageCount - (page - 1) * 10;
            }
            console.log(count);
            //Math.trunc(count)
            let response = await getFormRecords(id, page, size);
            if (response.data && response.data.length > 0) {
                let tempHeaders: any = [];
                setresponseData(response.data);
                response.data.forEach((obj, index) => {
                    obj.fields.forEach(subObj => {
                        if (index > 0) {
                            let check = tempHeaders.filter(elem => elem.value === subObj.id);
                            if (check.length === 0) {
                                tempHeaders.push({
                                    label: subObj.field_name,
                                    value: subObj.id,
                                    data: subObj,
                                    type: subObj.field_type
                                });
                            }
                        } else {
                            tempHeaders.push({
                                label: subObj.field_name,
                                value: subObj.id,
                                data: subObj,
                                type: subObj.field_type
                            });
                        }
                    });
                });
                console.log(tempHeaders)

                let tempColumns: any = [];
                tempHeaders.forEach(obj => {
                    tempColumns.push({
                        "id": obj.value,
                        "label": obj.label,
                        accessor: elem => (obj.data.field_type === 'fileUpload' && elem[obj.value]) ? <div className='link-text' onClick={() => window.open(elem[obj.value])}>Link</div> : elem[obj.value]?.toString() || '-',
                        "sort": "asc",
                        "field_type": obj.type
                    })
                })

                setcolumns(tempColumns);
                let tempRecords: any = [];
                response.data.forEach(obj => {
                    let tempRow: any = [];
                    console.log(obj, tempHeaders)
                    obj.fields.forEach(subObj => {
                        let temp = tempHeaders.filter(elem => elem.value === subObj.id);
                        if (temp.length > 0) {
                            tempRow[temp[0].value] = subObj.field_selected_value;
                        } else {
                            tempRow[temp[0].value] = "-";
                        }
                    });
                    tempRecords.push(tempRow);
                })
                setrecords(tempRecords || []);
            } else {
                setresponseData([]);
                setrecords([]);
                props.onClose('forms');
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    const onAction = async (type, data: any = null) => {
        switch (type) {
            case 1:
                try {
                    dispatch(setLoading(true));
                    SetselectedIndex(data.index)
                    let response = await getFormVariables(props.formData.id);
                    if (response.data && response.data.length > 0) {
                        response.data.forEach((obj) => {
                            let selectedRecord = responseData[data.index].fields.filter(elem => elem.id === obj.id);
                            if (selectedRecord.length > 0) {
                                if (obj.field_type === 'dateField') {
                                    obj.selectedValue = new Date(selectedRecord[0].field_selected_value);
                                } else if (obj.field_type === 'dropDown') {
                                    obj.selectedValue = {
                                        label: selectedRecord[0].field_selected_value,
                                        value: selectedRecord[0].field_selected_value
                                    };
                                } else {
                                    obj.selectedValue = selectedRecord[0].field_selected_value;
                                }
                            }
                            obj.field_category = obj.field_category || 'empty';
                        });
                        let temp: any = _.groupBy(response.data, "field_category");
                        if (temp.empty) {
                            let data = cloneDeep(temp.empty);
                            delete temp.empty;
                            temp.empty = data;
                        }
                        setformVariables(temp);
                        setaction('view');
                    }
                    dispatch(setLoading(false));
                } catch (e: any) {
                    dispatch(setLoading(false));
                }
                break;
            case 2:
                navigate('/dataEntry', { state: { data: props.formData, projectData: props.projectData, selectedValues: responseData[data.index].fields, id: responseData[data.index].id } });
                break;
            case 6:
                setaction("group")
                break;
            case 9:
                setaction("analysis");
                break;
            default:
                setaction(type);
                break;
        }
    }


    const downloadTemplate = async () => {
        try {
            dispatch(setLoading(true));
            let response = await downloadFieldTemplate(props.formData.id);
            // await delay(2000);
            console.log(response)

            //const blob: any = new Blob([response],{type: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'});
            var blob: any = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // let link = document.createElement("a");
            // let fileName = props.formData.form_name;
            let fileName = "userInputData.xlsx";
            saveAs(blob, fileName)
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    const downloadData = async () => {
        try {
            dispatch(setLoading(true));
            let response = await downloadFormData(props.formData.id)
            // await delay(2000);
            const blob: any = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            let link = document.createElement("a");
            // let fileName = props.formData.form_name;
            let fileName;
            //if(response.head['content-type'] === "text/csv"){
            fileName = props.formData.form_name + ".csv"
            // }else{
            //     fileName = props.formData.form_name
            // }            
            if (link.download !== undefined) {
                let url = window.URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                // setTimeout(() => URL.revokeObjectURL(link.href), 10000 );
                document.body.removeChild(link);
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }


    function onShowSizeChange(e) {
        fetchData(props.formData.id, e, pageSize);
    }

    const handleSizeChange = (current, pagesize) => {
        console.log(current);
        SetPageSize(pagesize);
        fetchData(props.formData.id, current, pagesize);
    };

    const onClose = () => {
        setaction("");
    }

    return (
        <>
            {
                action == "group" &&
                <GroupVariableTable data={projectData} variables={columns} {...props} onClose={() => onClose()} />
            }
            {
                action == "analysis" &&
                <DataAnalysisTable data={projectData} {...props} onClose={() => onClose()}/>
                // <ConfigureAnalysis data={projectData} {...props} onClose={() => onClose()} />
            }
            {!action &&
                <div className='view-form-data'>
                    <Row className='m-0 align-items-center actions-header'>
                        <Col className='p-0'>
                            <div className='actions-text'>{props.formData.form_name}</div>
                        </Col>
                        <Col className='p-0 row m-0 justify-content-end align-items-center'>
                            <div className='icon-container'>
                                <FileExcelFilled onClick={() => downloadTemplate()} />
                            </div>
                            <div className='icon-container'>
                                <i className='icon icon-download' onClick={() => downloadData()} />
                            </div>
                            <i className='icon icon-close' onClick={() => props.onClose(isCallApi ? 'forms' : '')} />
                        </Col>
                    </Row>
                    <div className='p-4'>
                        {/* changed isEdit to default false props in CustomTable.formData.survey_form */}
                        <CustomTable header="Records" data={records} tableProps={columns} isFilter={true} isEdit={false} isView={false} isDelete={false} isCreate={false} isGroup={true} onAction={(type, data = null) => onAction(type, data)} ></CustomTable>
                        <div className='viewdata-pagination'><Pagination onChange={(e) => onShowSizeChange(e)} total={props.formPageCount} onShowSizeChange={handleSizeChange} /></div>
                    </div>
                </div>
            }
        </>
    )
}


