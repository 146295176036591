import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Col, Row } from "react-bootstrap";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import { useEffect } from "react";

export default function ConfigSelectorCard(props) {
    const { configChartData,removeItem,editItem,index } = props;
    
    useEffect(() => {
        console.log(props)
    }, [])

    return (
        <>
            <div className="config-card-container">
                <Card className="card-config">
                    <CardHeader>
                        <Row>
                            <Col md={7}>
                                <span id="config-name">{"Config-"+index}</span>
                            </Col>
                            <Col md={5}>
                                <EditOutlined id="edit" onClick={() => editItem(configChartData?.id)}/>
                                <DeleteOutlined id="delete" onClick={() => removeItem(configChartData?.id)}/>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div className="outcome-variable">{configChartData?.outcomeVar[0].value}</div>
                        <div className="exp-variable">
                            {
                            configChartData && configChartData?.expoVariable.map((item, index) => {
                                return <span id="variable">{item.label}</span>
                            })
                            }
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col md={9}>{configChartData && configChartData?.charttype?.label}</Col>
                            {/* <div className="chart-variable">{configChartData && configChartData?.charttype?.map(item =>
                               <span id="variable">{item.label}</span>
                            )}</div></Col> */}
                            
                            
                            {/* <Col md={3}>
                                <FieldItem
                                    values={[{ label: "", value: "" }]}
                                    type={FIELD_TYPES.CHECK_BOX}
                                />
                            </Col> */}
                        </Row>
                    </CardFooter>
                </Card>
            </div>
        </>
    )
}