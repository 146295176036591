
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

const MultiLineChart = (props) => {
  const chartRef = useRef(null);
  const {chartstyle,data,yrange,chartdatastyle,title,titlecolor,width,height,yaxislabelstyle,xaxislabelstyle,gridcolor,gridstatus,yaxisstyle,xaxisstyle,xaxistext,yaxistext} = props;


  useEffect(() => {
    // Set up dimensions and margins
    const margin = { top: 20, right: 30, bottom: 30, left: 50 };
    const width = 600 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    // Create SVG container
    const svg = d3.select(chartRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('fill', 'none')
      .attr('transform', `translate(${margin.left+100},${margin.left})`);

    // Create scales
    const xScale = d3.scaleLinear().range([0, width]);
    const yScale = d3.scaleLinear().range([height, 0]);

    // Define line function
    const line = d3.line()
      .x(d => xScale(d.x))
      .y(d => yScale(d.y));

    // Extract unique keys from the data
    const keys = Object.keys(data[0]).filter(key => key !== 'x');

    // Set domain for x and y scales
    //add yrange value to increase the y axis scale
    xScale.domain(d3.extent(data, d => d.x));
    yScale.domain([0, d3.max(data, d => d3.max(keys, key => d[key] + yrange))]);

    // Add X axis
    svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(xScale));

    // Add Y axis
    svg.append('g')
      .call(d3.axisLeft(yScale));

          // Add title
          svg.append('text')
          .attr('y', margin.left/2)
          .attr('x', 0 - height / 5 + margin.left * 3 )
          .attr('dy','-3em')
          .attr('text-anchor', 'start')
          .style('font-size', '16px')
          .style('fill','#000')
          .style('text-decoration', 'underline')
          .text('Line Chart with Dots and Values');


      // Add title
      svg.append('text')
        .attr('x', width / 2)
        .attr('y', 0 - margin.top / 2)
        .attr('text-anchor', 'middle')
        .style('font-size', '16px')
        .style('text-decoration', 'underline')
        .text('Line Chart with Dots and Values');
      // Add X axis
      svg.append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(xScale))
        .append('text') // X-axis label
        .attr('x', width / 2)
        .attr('y', margin.bottom - 5)
        .attr('dy',xaxisstyle.dy)
        .attr('fill', xaxisstyle.color)
        .style('text-anchor', 'middle')
        .style('font-size', xaxisstyle.fontsize)
        .text(xaxistext);

      // Add Y axis
      svg.append('g')
        .call(d3.axisLeft(yScale))
        .append('text') // Y-axis label
        .attr('transform', 'rotate(-90)')
        .attr('y', 0 - margin.left)
        .attr('x', 0 - height / 2)
        .attr('dy', yaxisstyle.dy)
        .attr('fill', yaxisstyle.color)
        .style('text-anchor', 'middle')
        .style('font-size', yaxisstyle.fontsize)
        .text(yaxistext);


    // Draw gridlines
    svg
    .append('g')
    .attr('class', 'grid')
    .style('color',gridcolor)
    .attr('transform', `translate(2,${height})`)
    .style("stroke-dasharray", "5 5")
    .style("visibility",gridstatus)
    .call(d3.axisBottom(xScale).tickSize(-height).tickFormat(''));

      svg
        .append('g')
        .attr('class', 'grid')
        .attr("transform", `translate(${1})`)   
        .style("stroke-dasharray", "5 5")
        .style("color",gridcolor)
        .style("visibility",gridstatus)
        .call(d3.axisLeft(yScale).tickSize(-width).tickFormat(''));


      const diagonalLine = d3.line()
      .x(d => xScale(d.x))
      .y(d => yScale(d.x));
      
    // Add lines
    keys.forEach(key => {
      svg.append('path')
        .data([data])
        .attr('class', 'line')
        .attr('d', line.y(d => yScale(d[key])).defined(d => !isNaN(d[key])))
        .style('stroke', () => d3.schemeCategory10[keys.indexOf(key)]); 
        
        
        // Add dots with values
        svg.selectAll(`.${key}-dot`)
          .data(data)
          .enter().append('circle')
          .attr('class', `${key}-dot dot`)
          .attr('cx', d => xScale(d.x))
          .attr('cy', d => yScale(d[key]))
          .attr('r', 4)
          .style('fill', () => d3.schemeCategory10[keys.indexOf(key)])
          .append('title')
          .text(d => `Value: ${d[key]}`);

          
        // Add values near dots
        svg.selectAll(`.${key}-dot-label`)
          .data(data)
          .enter().append('text')
          .attr('class', `${key}-dot-label dot-label`)
          .attr('x', d => xScale(d.x))
          .attr('y', d => yScale(d[key]) - 10)
          .style('fill', 'black')
          .style('font-size', '10px')
          .style('text-anchor', 'middle')
          .text(d => d[key]);

    });

  
      // Add diagonal line
      svg.append('path')
        .data([data])
        .attr('class', 'diagonal-line')
        .attr('d', diagonalLine.y(d => yScale(d.x)))
        .style('stroke', 'black')
        .style('stroke-dasharray', '5,5')
        .style('fill', 'none');



  }, [data]);

  return <>
          <svg ref={chartRef}></svg>
         </>
};

MultiLineChart.prototype={
  data:PropTypes.oneOfType([PropTypes.array,PropTypes.object]),
  width:PropTypes.number,
  heigth:PropTypes.number,
  yrange:PropTypes.number,
  chartstyle:PropTypes.object,
  chartdatastyle:PropTypes.object,
  chartdots:PropTypes.object,
  gridcolor:PropTypes.string,
  gridstatus:PropTypes.string,
  yaxistext:PropTypes.string,
  yaxislabelstyle:PropTypes.object,
  yaxisstyle:PropTypes.object,
  xaxistext:PropTypes.string,
  xaxislabelstyle:PropTypes.object,
  xaxisstyle:PropTypes.object,
  title:PropTypes.string,
  titlecolor:PropTypes.object
}

MultiLineChart.defaultProps ={
  data:[
    { x: 1, line1: 5, line2: 20 },
    { x: 2, line1: 16, line2: 15 },
    { x: 3, line1: 20, line2: 30 },
    { x: 4, line1: 12, line2: 50 }],
  width:"600", 
  height:"400", 
  yrange:0,
  chartstyle:{color:"#000",hover:"#ccc"},
  chartdatastyle:{fontsize:"16px",fontweight:"500",color:"#000"},
  chartdots:{color:"#000",radius:0},
  gridcolor:"#ccc",
  gridstatus:"hidden",
  yaxistext:"Y Label",
  yaxislabelstyle:{fontsize:"14px",fontweight:"400",color:"red"},
  yaxisstyle:{fontsize:"14px",fontweight:"600",color:"#000"},
  xaxistext:"X Label",
  xaxislabelstyle:{fontsize:"14px",fontweight:"400",color:"red"},
  xaxisstyle:{fontsize:"14px",fontweight:"600",color:"#000"},
  title:"Bar Chart",
  titlecolor:{fontsize:"24px",fontweight:"600",color:"#000"}
}

export default MultiLineChart;
