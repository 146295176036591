import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CurrentUser, setLoading } from "../../store/slices/auth";
import { expectedDateFormat } from "../../utils/Utils";
import CustomTable from "../../widgets/table";
import { getProfileDetails } from "../profile/apiController";
import { getPieactive } from "./apiController";
import Tabs from "../sampleSize/Tabs";
import { Row, Col } from "react-bootstrap";
import { ArrowLeftOutlined } from "@ant-design/icons";


export default function RegisteredEvents(props) {

  const [forms, setforms] = useState<any | null>([]);
  const [profile, SetProfile] = useState<any[]>([]);
  const [projectData, setprojectData] = useState<any | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabData = [
    {
      title: "Project Details",
      id: 1,
      tab: "project details"
    },
    {
      title: "Study Design",
      id: 2,
      tab: "study design"
    },
    {
      title: "Data Collection",
      id: 3,
      tab: "Data collection"

    },
    {
      title: "Data Analysis",
      id: 4,
      tab: "Data analysis"
    },
    {
      title: "People",
      id: 5,
      tab: "people"
    },
    {
      title: "Registered Events",
      id: 6,
      tab: "register events"
    },
    {
			title: "Mentors",
			id: 7,
			tab: "mentors"
		}
  ];
  useEffect(() => {
    if (location.state) {
      let stateData: any = location.state;
      setprojectData(stateData);
      fetchInitalData(stateData);
    } else {
      navigate('/project');
    }
  }, [])



  /**
   * fetch intial data to be loaded
   * call to get submission list pieActive api
   */
  const fetchInitalData = async (projectInfo) => {
    try {
      let userData = localStorage.getItem("coguide_user");
      if (userData) {
        dispatch(setLoading(true));
        let currentUser: CurrentUser = JSON.parse(userData);
        const getActiveData = await getPieactive(currentUser.UserNumber);
        if (getActiveData?.data) {
          setforms(getActiveData && getActiveData?.data.filter(event => event.project_id == projectInfo.TopicID));
        }
        dispatch(setLoading(false));
      }
    } catch (e: any) {
      dispatch(setLoading(false));
      throw e
    }
  }

  /**
   * COlumns for table data
   */
  let columns = [
    {
      id: "schedule_name",
      label: "Schedule Name",
      accessor: "project_event_name",
      sort: "asc",
    },
    {
      id: "org_name",
      label: "Organisation Name",
      accessor: "institutename",
      sort: "asc",
    }, {
      id: "invite_expiry",
      label: "Last Submission Date",
      "accessor": (row: any) => {
        return expectedDateFormat(row.expiry_date, 'DD MMM YYYY')
      },
      sort: "asc",
    },
    {
      id: "registration_status",
      label: "Status",
      accessor: "registration_status",
      sort: "asc",
    },
    {
      id: "view",
      label: "View",
      width: "50",
      className: "text-center",
      Cell: ({ row, data, index }) => {
        const { original } = row;
        return (
          <i
            className="icon icon-eye"
            onClick={() => onAction(original)}
          ></i>
        );
      },
    }
  ];

  /**
   * on view action redirect to information page
   */
  const onAction = (data) => {
    console.log(data)
    navigate('/documentsubmission', { state: { data: data, projectdata: projectData } })
  }

  return (
    <>
      <Row className="page-header m-0">
        <Col className="p-0 project-title" md={12}>
          <ArrowLeftOutlined style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }} onClick={() => navigate("/project")} />
          {projectData?.TopicDesc}
        </Col>
        <Col className="p-0"></Col>
      </Row>
      <Tabs data={tabData} selectedTab={5} projectData={projectData} />


      <div className="p-3">
        <CustomTable
          header="Records"
          data={forms && forms || []}
          tableProps={columns}
          onAction={(data = null) => onAction(data)}
        ></CustomTable>

      </div>
    </>
  )
}