import React, { useState } from "react";
import { useTimer } from "../../CustomHooks/timer.hooks";
import SuccessBadgeComponent from "../success.badge.component";

export default function OTPComponent({ headerName, type, loginId, component, callback, disabled = false }) {
    const timerTime = useTimer(180);
    const [currentState, setcurrentState] = useState("input");

    const handleChangeOtp = (value) => {
        if (value.length === 6) {
            callback('reset', value)
            setcurrentState("verify");
        }else{
            //callback('reset', value)
        }
    };
    return (
        <div className="otp-reset-wrapper">
            <div className="otp-reset">
                {/* <div className="fotgot-pwd-header">
                    <i className="icon icon-back_arrow" onClick={() => callback()}></i>
                    <div className="headerName">{headerName}</div>
                </div> */}
                <p> Verify account by OTP sent to Phone/Email</p>
                <div className="loginId mb-1 pb-1">
                    <div>{loginId}
                        <i className="icon icon-edit" onClick={() => callback('changeNumber',loginId)}></i>
                    </div>
                </div>
                {/* {currentState === "input" ? ( */}
                    <React.Fragment>
                        <div className="opt-field-wrapper">
                            <div className="otp-field">
                                
                                <input
                                    maxLength={6}
                                    disabled={disabled}
                                    type="text"
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => handleChangeOtp(e.target.value)}
                                    placeholder="000000"
                                    required
                                />
                                {timerTime <=0 && <div className="resend" onClick={()=>callback('resend')}>Resend</div>}
                                {timerTime > 0 && (
                                    <div className="resend">
                                        <i className="icon icon-timer "></i>
                                        <span>{timerTime}</span>
                                    </div>
                                )}
                            </div>
                            {/* <div className="otp-subtext">
                                This OTP will be valid till 300 secs
                            </div> */}
                        </div>
                        <div className="otp-timer">

                        </div>
                    </React.Fragment>
                {/* ) : (
                    ""
                    // <SuccessBadgeComponent message="verified" />
                )} */}
            </div>
        </div>
    );
}
