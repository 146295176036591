export const FormElements = {
    mobileNumber: {
        name: "mobileNumber",
        placeholder: "Phone Number",
        label: "Phone",
        isMandatory: true,
        icon: "phone",
        regex: /^[0-9]*$/,
        minLength: 10,
        maxLength: 10,
        messages: {
            regex: 'Invalid format',
            minLength: 'Phone number should be 10 digits'
        }
    },
    email: {
        name: "email",
        placeholder: "Email",
        label: "Email",
        isMandatory: true,
        icon: "email",
        regex: /\S+@\S+\.\S+/,
        messages: {
            regex: 'Email is not valid',
        }
    },
    displayName: {
        name: "displayName",
        placeholder: "Name",
        label: "Display Name",
        isMandatory: true,
        icon: "profile"
    },
    password: {
        name: "password",
        placeholder: "Password",
        label: "Password",
        isMandatory: true
    },
    confirmPassword: {
        name: "confirmPassword",
        placeholder: "Confirm Password",
        label: "confirmPassword",
        isMandatory: true
    }
};