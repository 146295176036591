import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';


const GroupedBarChart = ({ barstyle, data, bardatastyle, title, titlecolor, width, height, yaxislabelstyle, xaxislabelstyle, gridcolor, gridstatus, yaxisstyle, xaxisstyle, xaxistext, yaxistext }) => {
  const svgRef = useRef(null);
  const [hide, SetHide] = useState(bardatastyle.visible)

  useEffect(() => {
    console.log(data)
    if (!data || data.length === 0) return;
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    let topValue = 'hidden'

    const svg = d3
      .select(svgRef.current)
      .append('svg')
      .attr('width', width )
      .attr('height', height)
      .append('g')
      .style("overflow", "visible")
      .attr('transform', `translate(${width / 5},${height / 8})`);
    ;

    // Remove previous elements
    svg.selectAll('*').remove();

    //create scale
    const x0 = d3.scaleBand()
      .domain(data.map(d => d.group))
      .rangeRound([0, innerWidth])
      .paddingInner(0.1);

    const x1 = d3.scaleBand()
      .domain(data[0].values.map(d => d.name))
      .rangeRound([0, x0.bandwidth()])
      .padding(0.05);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, d => d3.max(d.values, d => d.value))])
      .rangeRound([innerHeight, 0]);

    const color = d3.scaleOrdinal()
      .range(d3.schemeCategory10);

    const xAxis = d3.axisBottom(x0);

    const yAxis = d3.axisLeft(y);



    //add grids
    svg
      .append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(0,0)`)
      .style("stroke-dasharray", "5 5")
      .style("visibility", gridstatus)
      .style("color", gridcolor)
      .call(d3.axisLeft(y).tickSize(-width).tickFormat(''));


    svg.append('g')
      .selectAll('g')
      .data(data)
      .enter().append('g')
      .attr('class', '.bar-group')
      .attr('transform', d => `translate(${x0(d.group)},0)`)
      .selectAll('rect')
      .data(d => d.values)
      .enter().append('rect')
      .attr('x', d => x1(d.name))
      .attr('y', d => y(d.value))
      .attr('width', x1.bandwidth())
      .attr('height', d => innerHeight - y(d.value))
      .attr('fill', d => color(d.name))
      .attr('data-category', (d) => d.name)
      .on("mouseenter", function (d) {
        d3.select(d.currentTarget)
          .transition()
          .duration(100)
          .attr('opacity', 0.6);

        // Show tooltip on mouseover
        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip.html(`${d.target.__data__.name}: ${d.target.__data__.value}`)
          .style('left', `${d.pageX}px`)
          .style('top', `${d.pageY - 28}px`);

      })
      .on("mouseleave", function (d) {
        d3.select(d.currentTarget)
          .transition()
          .duration(200)
          .attr('opacity', 1)
        //remove tooltip
        tooltip.transition().duration(500).style('opacity', 0);
      });

    // Create legends
    const legend = svg
      .selectAll('.legend')
      .data(color.domain())
      .enter()
      .append('g')
      .attr('class', 'legend')
      .attr('transform', (d, i) => `translate(0,${i * 20})`)
      .on('click', toggleLegend);



    legend
      .append('rect')
      .attr('x', width-20)
      .attr('width', 18)
      .attr('height', 18)
      .style('fill', color);

    legend
      .append('text')
      .attr('x', width)
      .attr('dy','0.70em')
      .style('text-anchor', 'start')
      .text((d) => d);


    // Add text labels on top of bars
    svg
      .selectAll('text.top-value')
      .data(data)
      .attr('class', 'top-value')
      .enter()
      .append('g')
      .attr('transform', d => `translate(${x0(d.group)},${margin.left})`)
      .selectAll('text')
      .data(d => d.values)
      .enter().append('text')
      .attr('x', d => x1(d.name) + x1.bandwidth() / 2)
      .attr('y', d => y(d.value) - 5) // Adjust the vertical position
      .text(d => d.value)
      .attr('text-anchor', 'middle')
      .style('fill', bardatastyle.color)
      .style('font-weight', bardatastyle.fontweight)
      .style('font-size', bardatastyle.fontsize)
      .style('visibility', bardatastyle.visible);

    //add axis
    svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${innerHeight})`)
      .call(xAxis)
      .style('font-weight', xaxislabelstyle.fontweight)
      .style('font-size', xaxislabelstyle.fontsize);

    svg.append('g')
      .attr('class', 'y-axis')
      .call(yAxis)
      .style('font-weight', yaxislabelstyle.fontweight)
      .style('font-size', yaxislabelstyle.fontsize);

    //title of the chart
    svg.append('text')
      .attr('class', 'title')
      .attr('x', width / 2)
      .attr('y', -margin.left)
      .attr('text-anchor', 'middle')
      .style('font-size', titlecolor.fontsize)
      .style('font-weight', titlecolor.fontweight)
      .style('fill', titlecolor.color)
      .text(title)



    //add x axis label
    svg.append('text')
      .attr("class", "x label")
      .attr("text-anchor", "middle")
      .attr("x", width / 3)
      .attr("y", height - 2)
      .attr("dy", xaxisstyle.dy)
      .attr("dx", width / 8 - margin.left)
      .style('fill', xaxisstyle.color)
      .style('font-weight', xaxisstyle.fontweight)
      .style('font-size', xaxisstyle.fontsize)
      .text(xaxistext);

    //add y axis label
    svg.append("text")
      .attr("class", "y label")
      .attr('transform', `translate(20)`)
      .attr("text-anchor", "middle")
      .attr("y", -margin.left - margin.left)
      .attr("dy", "2em")
      .attr("x", -height / 2)
      .attr("transform", "rotate(-90)")
      .style('fill', yaxisstyle.color)
      .style('font-weight', yaxisstyle.fontweight)
      .style('font-size', yaxisstyle.fontsize)
      .text(yaxistext);


    // Create tooltip
    const tooltip = d3
      .select(svgRef.current)
      .append('div')
      .style('position', 'absolute')
      .style('background', '#f4f4f4')
      .style('padding', '5px')
      .style('border', '1px solid #ccc')
      .style('border-radius', '5px')
      .style('color', "red")
      .style('opacity', 0);

    // Function to toggle legend visibility
    function toggleLegend(d) {
      const groupName = d.target.__data__;
      const selectedLegend = svg.selectAll('.legend').filter((legendName) => legendName === groupName);

      // Toggle legend
      selectedLegend.classed('disabled', !selectedLegend.classed('disabled'));

      // Toggle corresponding bars visibility
      const bars = svg.selectAll('rect').filter((d) => d.name === groupName);

      if (selectedLegend.classed('disabled')) {
        bars.style('display', 'none');
        SetHide('hidden')
      } else {
        SetHide('visible')
        bars.style('display', null); // Setting to null restores the default display property
      }

    }

    return () => {
      d3.select(svgRef.current).selectAll('*').remove();
    };

  }, [data, width, height, hide]);

  return (
    <div ref={svgRef}></div>
  );
};

GroupedBarChart.prototype = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  width: PropTypes.number,
  heigth: PropTypes.number,
  barstyle: PropTypes.object,
  bardatastyle: PropTypes.object,
  gridcolor: PropTypes.string,
  gridstatus: PropTypes.string,
  yaxistext: PropTypes.string,
  yaxislabelstyle: PropTypes.object,
  yaxisstyle: PropTypes.object,
  xaxistext: PropTypes.string,
  xaxislabelstyle: PropTypes.object,
  xaxisstyle: PropTypes.object,
  title: PropTypes.string,
  titlecolor: PropTypes.object
}

GroupedBarChart.defaultProps = {
  data: [
    { group: 'Group A', values: [{ name: 'Category 1', value: 10 }, { name: 'Category 2', value: 20 }, { name: 'Category 3', value: 30 }] },
    { group: 'Group B', values: [{ name: 'Category 1', value: 15 }, { name: 'Category 2', value: 25 }, { name: 'Category 3', value: 20 }] }],
  width: "600",
  height: "400",
  barstyle: { color: "#000", hover: "#ccc" },
  bardatastyle: { fontsize: "16px", fontweight: "500", color: "#000" },
  gridcolor: "#ccc",
  gridstatus: "hidden",
  yaxistext: "Y Label",
  yaxislabelstyle: { fontsize: "14px", fontweight: "400", color: "red" },
  yaxisstyle: { fontsize: "14px", fontweight: "600", color: "#000" },
  xaxistext: "X Label",
  xaxislabelstyle: { fontsize: "14px", fontweight: "400", color: "red" },
  xaxisstyle: { fontsize: "14px", fontweight: "600", color: "#000" },
  title: "Bar Chart",
  titlecolor: { fontsize: "24px", fontweight: "600", color: "#000" }
}
export default GroupedBarChart;
