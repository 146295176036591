import { Pagination } from 'antd';
import _, { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row, Spinner, Toast } from 'reactstrap'
import { setLoading } from '../../store/slices/auth';
import { CustomButton, showToaster, toasterTypes } from '../../widgets'
import ModalPopup from '../../widgets/modal';
import CustomTable from '../../widgets/table';
import { deleteRecord, downloadFieldTemplate, downloadFormData, getFormRecords, getFormVariables, postgenerateLink, uploadRecord } from './apiController';
import CreateForm from './CreateForm';
import ViewForm from './ViewForm';
import { FileExcelFilled, FileExcelOutlined, FileExclamationOutlined } from '@ant-design/icons';
// import {exportAsExcelFile} from '../../utils/Excel';
import { saveAs } from 'file-saver';
import moment from 'moment';
import UidGenerateLink from './UidGenerateModal';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { environment } from '../../environments/environment';


export default function ViewFormData(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [action, setaction] = useState('');
    const [records, setrecords] = useState([]);
    const [columns, setcolumns] = useState([]);
    const [selectedRecords, SetSelectedRecords] = useState<any>("");
    const [selectedRow, setselectedRow] = useState<any | null>(null);
    const [responseData, setresponseData] = useState<any | []>([]);
    const [formVariables, setformVariables] = useState<any | []>([]);
    const [isCallApi, setisCallApi] = useState(false);
    const [pageSize, SetPageSize] = useState(30);
    const [selectedIndex, SetselectedIndex] = useState<any>(null);
    const [uploadStatus, SetUploadStatus] = useState(false)

    useEffect(() => {
        fetchData(props.formData.id, 1, pageSize);
    }, []);

    const fetchData = async (id, page, size) => {
        try {
            dispatch(setLoading(true));
            let count = props.formPageCount / page;
            if (count < 10) {
                count = props.formPageCount - (page - 1) * 10;
            }
            //Math.trunc(count)
            let response = await getFormRecords(id, page, size);
            if (response.data && response.data.length > 0) {
                let tempHeaders: any = [];
                setresponseData(response.data);
                response.data.forEach((obj, index) => {
                    obj.fields.forEach(subObj => {
                        if (index > 0) {
                            let check = tempHeaders.filter(elem => elem.value === subObj.id);
                            if (check.length === 0) {
                                tempHeaders.push({
                                    label: subObj.field_name,
                                    value: subObj.id,
                                    data: subObj
                                });
                            }
                        } else {
                            tempHeaders.push({
                                label: subObj.field_name,
                                value: subObj.id,
                                data: subObj
                            });
                        }
                    });
                });
                tempHeaders.push({
                    label: "Duration",
                    value: "duration",
                    data: ""
                });


                let tempColumns: any = [];
                // tempColumns.push({
                //     "id": "select",
                //     "label": ({ getToggleAllRowsSelectedProps }) => <input type="checkbox" {getToggleAllRowsSelectedProps()} />,
                //     accessor: elem => elem,
                //     "sort": "asc",
                //     Cell: ({ value }) => <input type="checkbox" {...getToggleRowSelectedProps()} />
                // })
                tempHeaders.forEach(obj => {
                    console.log(obj.value)
                    tempColumns.push({
                        "id": obj.value,
                        "label": obj.label,
                        accessor: elem => (obj.data.field_type === 'fileUpload' && elem[obj.value]) ? <div className='link-text' onClick={() => window.open(elem[obj.value])}>Link</div> : obj.value == 'select' ? <input type='checkbox' onChange={() => console.log(elem[obj.value])} /> : elem[obj.value]?.toString() || '-',
                    })
                })


                setcolumns(tempColumns);
                let tempRecords: any = [];
                response.data.forEach(obj => {
                    let tempRow: any = [];
                    // tempRow['select'] = false;
                    obj.fields.forEach(subObj => {
                        let temp = tempHeaders.filter(elem => elem.value === subObj.id);
                        if (temp.length > 0) {
                            tempRow[temp[0].value] = subObj.field_selected_value;
                        } else {
                            tempRow[temp[0].value] = "-";
                        }
                    });
                    let ms = moment.utc(moment(obj.end_time, "YYYY-MM-DD HH:mm:ss").diff(moment(obj.start_time, "YYYY-MM-DD HH:mm:ss"))).format("HH:mm:ss")
                    let duration = moment.duration(ms);
                    tempRow['duration'] = (duration.hours() == 0 ? "" : duration.hours() + " Hrs") + (duration.minutes() == 0 ? "" : duration.minutes() + " Min ") + (duration.seconds() == 0 ? "" : duration.seconds() + " Sec");
                    tempRecords.push(tempRow);
                })
                setrecords(tempRecords || []);
            } else {
                setresponseData([]);
                setrecords([]);
                props.onClose('forms');
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }

        console.log(records)
    }

    const onAction = async (type, data: any = null) => {
        switch (type) {
            case 1:
                try {
                    dispatch(setLoading(true));
                    SetselectedIndex(data.index)
                    let response = await getFormVariables(props.formData.id);
                    if (response.data && response.data.length > 0) {
                        response.data.forEach((obj) => {
                            let selectedRecord = responseData[data.index].fields.filter(elem => elem.id === obj.id);
                            if (selectedRecord.length > 0) {
                                if (obj.field_type === 'dateField') {
                                    obj.selectedValue = new Date(selectedRecord[0].field_selected_value);
                                } else if (obj.field_type === 'dropDown') {
                                    obj.selectedValue = {
                                        label: selectedRecord[0].field_selected_value,
                                        value: selectedRecord[0].field_selected_value
                                    };
                                } else {
                                    obj.selectedValue = selectedRecord[0].field_selected_value;
                                }
                            }
                            obj.field_category = obj.field_category || 'empty';
                        });
                        let temp: any = _.groupBy(response.data, "field_category");
                        if (temp.empty) {
                            let data = cloneDeep(temp.empty);
                            delete temp.empty;
                            temp.empty = data;
                        }
                        setformVariables(temp);
                        setaction('view');
                    }
                    dispatch(setLoading(false));
                } catch (e: any) {
                    dispatch(setLoading(false));
                }
                break;
            case 2:
                console.log(responseData[data.index].fields)
                navigate('/dataEntry', { state: { data: props.formData, projectData: props.projectData, selectedValues: responseData[data.index].fields, id: responseData[data.index].id } });
                break;
            case 3:
                setselectedRow(responseData[data.index]);
                setaction('delete');
                break;
            case 11:
                if (data == "all") {
                    SetSelectedRecords("all")
                } else {
                    SetSelectedRecords(Object.keys(data.selectedRowIds));
                }
                break;
            default:
                setaction(type);
                break;
        }
    }

    /**
     * modify data on click modify button clicked
     */
    const onModify = () => {
        navigate('/dataEntry', { state: { data: props.formData, projectData: props.projectData, selectedValues: responseData[selectedIndex].fields, id: responseData[selectedIndex].id } });
    }

    /**
     * partial modify the data
     */
    const onPartialModify = () => {
        props.formData.isPartialModify = true;
        console.log(props)
        navigate('/dataEntry', { state: { data: props.formData, projectData: props.projectData, selectedValues: responseData[selectedIndex].fields, id: responseData[selectedIndex].id } });
    }

    const onAdd = () => {
        let data: any = {
            created_on: props.formData.created_on,
            form_name: props.formData.form_name,
            id: props.formData.id,
            total_responses: props.formData.total_responses,
            updated_on: props.formData.updated_on
        };
        data.formsList = props.forms;
        data.projectData = props.projectData;
        navigate('/dataEntry', { state: { data, projectData: props.projectData } });
    }

    const onDelete = async () => {
        try {
            dispatch(setLoading(true));
            let response = await deleteRecord(selectedRow?.id);
            if (response.success) {
                setselectedRow(null);
                setaction('');
                showToaster(toasterTypes.SUCCESS, response.message);
                fetchData(props.formData.id, 1, pageSize);
                setisCallApi(true);
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));


    const downloadTemplate = async () => {
        try {
            dispatch(setLoading(true));
            let response = await downloadFieldTemplate(props.formData.id);
            // await delay(2000);

            //const blob: any = new Blob([response],{type: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'});
            var blob: any = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // let link = document.createElement("a");
            // let fileName = props.formData.form_name;
            let fileName = "userInputData.xlsx";
            saveAs(blob, fileName)
            // if (link.download !== undefined) {
            //     let url = window.URL.createObjectURL(blob);
            //     link.setAttribute("href", url);
            //     link.setAttribute("download", fileName);
            //     document.body.appendChild(link);
            //     link.click();
            //     // setTimeout(() => URL.revokeObjectURL(link.href), 10000 );
            //     document.body.removeChild(link);

            // }
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    const downloadData = async () => {
        try {
            dispatch(setLoading(true));
            let response = await downloadFormData(props.formData.id)
            // await delay(2000);
            const blob: any = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            let link = document.createElement("a");
            // let fileName = props.formData.form_name;
            let fileName;
            //if(response.head['content-type'] === "text/csv"){
            fileName = props.formData.form_name + ".csv"
            // }else{
            //     fileName = props.formData.form_name
            // }            
            if (link.download !== undefined) {
                let url = window.URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                // setTimeout(() => URL.revokeObjectURL(link.href), 10000 );
                document.body.removeChild(link);
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }



    const uploadFile = () => {
        let elem = document.getElementById("fileInput");
        elem?.click();
    }

    /**
     * on select file call upload api
     * @param e 
     * Note : Old code to uplaod data
     */
    const onFileSelect1 = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        formData.append('form_id', props.formData.id);
        try {
            dispatch(setLoading(true));
            let response = await uploadRecord(formData);

            if (response.success) {
                setisCallApi(true);
                showToaster(toasterTypes.SUCCESS, response.message);
                fetchData(props.formData.id, 1, pageSize);
            }

            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    /**
     * uplaod data on OK response start event listener
     * @param e 
     */
    const onFileSelect = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        formData.append('form_id', props.formData.id);
        try {
            const response = await fetchEventSource(environment.profileUrl + 'form/responses/upload', {
                method: "POST",
                body: formData,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('coguide_token')}`,
                },
                onopen: async (res) => {
                    if (res && res?.status == 200) {
                        SetUploadStatus(true)
                        console.log("started....")
                    }
                },
                onmessage: async (res) => {
                    let response = JSON.parse(JSON.stringify(res))
                    response = JSON.parse(JSON.stringify(response.data))
                    response = JSON.parse(response)
                    console.log(response)
                    if (response.status == "failed") {
                        // Create a temporary anchor element for downloading file
                        const link = document.createElement('a');
                        link.href = response.report_file;
                        document.body.appendChild(link);
                        // Trigger the download
                        link.click();
                        // Cleanup
                        document.body.removeChild(link);
                        showToaster(toasterTypes.ERROR, "Upload failed, please check downloads for report")
                        SetUploadStatus(false)

                    } else if (response.status == "successful") {
                        SetUploadStatus(false)
                        setisCallApi(true);
                        fetchData(props.formData.id, 1, pageSize);
                        //showToaster(toasterTypes.SUCCESS, "Data uploaded successfully")
                    }

                },
                onclose: () =>{
                    SetUploadStatus(false)
                },
                onerror: (e) => {
                    console.log(e)
                    showToaster(toasterTypes.ERROR, "Something went wrong")
                    SetUploadStatus(false)
                }
            });

        } catch (error) {
            console.error('Error fetching initial data:', error);
        }
    };


    /**
     * @param e 
     */
    function onShowSizeChange(e) {
        fetchData(props.formData.id, e, pageSize);
    }

    const handleSizeChange = (current, pagesize) => {
        console.log(current);
        SetPageSize(pagesize);
        fetchData(props.formData.id, current, pagesize);

    };

    /**
     * create array of ids if multiselected
     * if selected all passing empty array as input
     * @param data 
     * API call
     */
    const generateLink = async (data) => {
        let idArray: any = [];
        selectedRecords.map(item => {
            idArray.push(responseData[item].id)
        })
        if (records.length === selectedRecords.length) {
            try {
                dispatch(setLoading(true));
                let response = await postgenerateLink(props.formData.id, data.value, []);
                if (response) {
                    setaction("")
                    showToaster(toasterTypes.SUCCESS, "Emails sent with Links")
                }
                dispatch(setLoading(false));
            } catch (err: any) {
                dispatch(setLoading(false));
            }
        } else {
            try {
                dispatch(setLoading(true));
                let response = await postgenerateLink(props.formData.id, data.value, idArray);
                if (response) {
                    setaction("")
                    showToaster(toasterTypes.SUCCESS, "Emails sent with Links")
                }
                dispatch(setLoading(false));
            } catch (err: any) {
                dispatch(setLoading(false));
            }
        }
    }

    useEffect(() =>{

    },[uploadStatus])

    return (
        action === 'view' ?
            <ViewForm {...props} projectData={props.projectData} variables={formVariables} formData={props.formData} modify={onModify} partialModify={onPartialModify} onClose={() => setaction('')} /> :
            <div className='view-form-data'>
                {action === 'editForm' &&
                    <CreateForm {...props} formData={props.formData} isEdit={true} projectData={props.projectData} groups={props.groups} group={props.groups[props.selectedGroup]} onClose={() => setaction('')} onFormCreation={() => props.onClose('forms')} />
                }
                {
                    action === 'uid' &&
                    <UidGenerateLink {...props} projectData={props.projectData} variables={formVariables} formData={props.formData} modify={onModify} formList={props?.forms} onClose={() => setaction('')} onGenerate={generateLink} />
                }
                {action === 'delete' &&
                    <ModalPopup
                        modalType='delete'
                        modalText="Are you sure, you want to delete?"
                        actionText="This action cannot be undone"
                        closeModal={() => { setaction(''); setselectedRow(null); }}
                        onAction={() => onDelete()}
                    />
                }
                <Row className='m-0 align-items-center actions-header'>
                    <Col className='p-0'>
                        <div className='actions-text'>{props.formData.form_name}</div>
                    </Col>
                    <Col className='p-0 row m-0 justify-content-end align-items-center'>
                        {!props.formData.survey_form && <CustomButton type="alert-primary" onClick={() => onAdd()} className="w-100" text="Add Data"></CustomButton>}
                        {!props.formData.survey_form &&
                            <div className='icon-container'>
                                {
                                uploadStatus ?
                                        <Spinner type='grow' color='primary'> </Spinner>                                         
                                        :
                                        <> <input type='file' value='' id="fileInput" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className='hide-input' onChange={(e) => { onFileSelect(e); }} />
                                            <i className='icon icon-upload' onClick={() => uploadFile()} />
                                        </>
                                }

                            </div>
                        }
                        {props?.formData?.form_use_category == "UID form" ?
                            <div className='icon-container' onClick={() => { selectedRecords.length ? setaction('uid') : showToaster(toasterTypes.ERROR, "Please select records to generate UID") }}>
                                UID
                            </div>
                            : ""}
                        <div className='icon-container'>
                            <FileExcelFilled onClick={() => downloadTemplate()} />
                            {/* <i className='icon icon-download' onClick={() => downloadTemplate()} /> */}
                        </div>
                        <div className='icon-container'>
                            <i className='icon icon-download' onClick={() => downloadData()} />
                        </div>
                        <div className='icon-container'>
                            <i className='icon icon-edit' onClick={() => setaction('editForm')} />
                        </div>
                        <i className='icon icon-close' onClick={() => props.onClose(isCallApi ? 'forms' : '')} />
                    </Col>
                </Row>
                <div className='p-4'>
                    {/* changed isEdit to default false props in CustomTable.formData.survey_form */}
                    <CustomTable header="Records" data={records} tableProps={columns} isSort={true} isSelect={true} isFilter={true} isEdit={false} isView={true} isDelete={true} isCreate={false} onAction={(type, data = null) => onAction(type, data)} ></CustomTable>
                    <div className='viewdata-pagination'><Pagination onChange={(e) => onShowSizeChange(e)} total={props.formPageCount} onShowSizeChange={handleSizeChange} /></div>
                </div>
            </div>
    )
}


