import { FIELD_TYPES } from "../../widgets/fields";

export const CreateFormElements = {
    name: {
        name: "name",
        placeholder: "Enter",
        label: "Form Name",
        isMandatory: true
    },
    description: {
        name: "description",
        placeholder: "Enter",
        label: "Form Description",
        isMandatory: true
    },
    type: {
        name: "type",
        placeholder: "Select",
        label: "Form Type",
        type: FIELD_TYPES.DROP_DOWN,
        values: [{label: 'Root', value: 'root'}, {label: 'Level 1', value: 'level1'}, {label: 'Level 2', value: 'level2'}, {label: 'Level 3', value: 'level3'}, {label: 'Level 4', value: 'level4'}, {label: 'Level 5', value: 'level5'}],
        isMandatory: true
    },
    form_usage_type: {
        name: "form_usage_type",
        placeholder: "Select",
        label: "Form usage type",
        type: FIELD_TYPES.DROP_DOWN,
        values: [{label: 'Default', value: 'Default'},{label: 'UID form', value: 'UID form'}],
        isMandatory: false
    },
    group: {
        name: "group",
        placeholder: "Select",
        label: "Form Group",
        type: FIELD_TYPES.DROP_DOWN,
        isMandatory: true
    },
    surveyType: {
        name: "surveyType",
        placeholder: "Select",
        label: "Survey Form",
        type: FIELD_TYPES.DROP_DOWN,
        values: [{label: 'Yes', value: true}, {label: 'No', value: false}],
        isMandatory: true
    },
    role: {
        name: "role",
        placeholder: "Select",
        label: "",
        type: FIELD_TYPES.DROP_DOWN,
        isMandatory: true,
        menuPlacement: 'top'
    },
    user: {
        name: "user",
        placeholder: "Select",
        label: "",
        type: FIELD_TYPES.DROP_DOWN,
        menuPlacement: 'top'
    },
    startdate: {
        name: "startdate",
        placeholder: "Start Date",
        label: "Start Date",
        type: FIELD_TYPES.DATE_TIME_PICKER,
        isMandatory:false
    },
    enddate: {
        name: "enddate",
        placeholder: "End Date",
        label: "End Date",
        type: FIELD_TYPES.DATE_TIME_PICKER,
        isMandatory:false
    },
    accessType: {
        name: "accessType",
        placeholder: "",
        label: "",
        type: FIELD_TYPES.RADIO_BUTTON,
        values: [{label: 'Read', value: 'r'}, {label: 'Write', value: 'w'},{label: 'Forbidden', value: 'f'}],
        isMandatory: true,
        isListed: true
    },
    searchField: {
        name: "searchField",
        placeholder: "Search",
        label: "",
        type: FIELD_TYPES.SEARCH,
        isMandatory: true
    },
    categoryName: {
        name: "categoryName",
        placeholder: "Category Name",
        label: ""
    },
    categoryNameEdit: {
        name: "categoryNameEdit",
        placeholder: "Category Name",
        label: ""
    },
    variables: {
        name: "variables",
        placeholder: "Add Variables",
        label: "",
        type: FIELD_TYPES.MULTI_TEXT,
        isMandatory: true
    },
    strictmode: {
        name: "strict_mode",
        placeholder: "Strict Mode",
        label: "Strict Mode",
        type: FIELD_TYPES.BOOLEAN,
        isMandatory: false
    }
};

export const CreateVariableElements = {
    fieldName: {
        name: "fieldName",
        placeholder: "Enter",
        label: "Variable Name",
        isMandatory: true
    },
    fieldDescription: {
        name: "fieldDescription",
        placeholder: "Enter",
        label: "Variable Description",
        isMandatory: true
    },
    fieldType: {
        name: "fieldType",
        placeholder: "Select",
        label: "Type",
        type: FIELD_TYPES.DROP_DOWN,
        isMandatory: true,
        isListed: true
    },
    useType: {
        name: "useType",
        placeholder: "Select",
        label: "Use Type",
        type: FIELD_TYPES.MULTI_SELECT,
        isMandatory: true,
        isListed: true
    },
    validation: {
        name: "validation",
        placeholder: "Select",
        label: "Validation",
        type: FIELD_TYPES.MULTI_SELECT,
        isListed: true
    },
    validationpopup: {
        name: "validationpopup",
        placeholder: "Select",
        label: "Validation",
        type: FIELD_TYPES.RADIO_BUTTON,
        isListed: true,
        isMandatory:true
    },
    visibility: {
        name: "visibility",
        placeholder: "Select",
        label: "Visibility",
        type: FIELD_TYPES.MULTI_SELECT,
        isListed: true
    },
    timeSeries: {
        name: "timeSeries",
        placeholder: "Select",
        label: "Time Series",
        type: FIELD_TYPES.DROP_DOWN,
        values: [
            {label: 'Yes', value: 'yes'},
            {label: 'No', value: 'no'}
        ],
        isMandatory: true,
        isListed: true,
        menuPlacement: 'top'
    },
    role: {
        name: "role",
        placeholder: "Select",
        label: "",
        type: FIELD_TYPES.DROP_DOWN,
        isMandatory: true,
        menuPlacement: 'top'
    },
    user: {
        name: "user",
        placeholder: "Select",
        label: "",
        type: FIELD_TYPES.DROP_DOWN,
        menuPlacement: 'top'
    },
    accessType: {
        name: "accessType",
        placeholder: "",
        label: "",
        type: FIELD_TYPES.RADIO_BUTTON,
        values: [{label: 'Read', value: 'r'}, {label: 'Write', value: 'w'},{label: 'Forbidden', value: 'f'}],
        isMandatory: true,
        isListed: true
    },
    timeStamp: {
        name: "timeStamp",
        placeholder: "HH : MM : SS AA",
        label: "Time Stamp",
        type: FIELD_TYPES.DATE_PICKER,
        isMandatory: true
    },
    prePost: {
        name: "prePost",
        placeholder: "Select",
        label: "Pre / Post",
        type: FIELD_TYPES.DROP_DOWN,
        values: [{label: 'Before', value: 'before'}, {label: 'After', value: 'after'}],
        isMandatory: true,
        menuPlacement: 'top'
    },
    value: {
        name: "value",
        placeholder: "Enter",
        label: "Value",
        inputType: 'number',
        isMandatory: true
    },
    duration: {
        name: "duration",
        placeholder: "Select",
        label: "Duration",
        type: FIELD_TYPES.DROP_DOWN,
        values: [{label: 'Years', value: 'Y'},{label: 'Months', value: 'm'},{label: 'Weeks', value: 'wk'},{label: 'Days', value: 'd'},{label: 'Hours', value: 'hr'}, {label: 'Minutes', value: 'min'}, {label: 'Seconds', value: 'sec'}],
        isMandatory: true,
        menuPlacement: 'top'
    },
    option: {
        name: "option",
        placeholder: "Enter",
        label: "Option",
        isMandatory: true
    },
    actions: {
        name: "actions",
        placeholder: "Operation",
        label: "",
        type: FIELD_TYPES.DROP_DOWN,
        values: [
            {label: '>', value: 'greater_than'},
            {label: '>=', value: 'greater_than_equals_to'},
            {label: '<', value: 'less_than'},
            {label: '<=', value: 'less_than_equals_to'},
            {label: '==', value: 'equals_to'},
            {label: 'Contains', value: 'contains'},
            {label: 'Does Not Contains', value: 'does_not_contains'}
        ]
    },
    actionsvariables: {
        name: "actionsvariables",
        placeholder: "Action",
        label: "",
        type: FIELD_TYPES.DROP_DOWN,
        values: [           
            {label: 'Skip', value: 'skip_to'},
            {label: 'End', value: 'end'}
        ]
    },
     
    variables: {
        name: "variables",
        placeholder: "Variable Name",
        label: "",
        type: FIELD_TYPES.DROP_DOWN
    },
    searchVariable: {
        name: "searchVariable",
        placeholder: "Add Variable",
        label: "",
        type: FIELD_TYPES.DROP_DOWN
    },
    minValue: {
        name: "minValue",
        placeholder: "Enter",
        label: "Min",
        inputType: 'number',
        isMandatory: true
    },
    maxValue: {
        name: "maxValue",
        placeholder: "Enter",
        label: "Max",
        inputType: 'number',
        isMandatory: true
    }
}


export const CreateCriteriaElements = {
    name: {
      name: "name",
      placeholder: "Enter Name",
      label: "Criteria Name",
      isMandatory: true,
    },
    source: {
      name: "source",
      placeholder: "Select",
      label: "Source From",
      type: FIELD_TYPES.DROP_DOWN,
      isMandatory:true
    },
    destination: {
      name: "destination",
      placeholder: "Select",
      label: "Destination From",
      type: FIELD_TYPES.DROP_DOWN,
      isMandatory:true
    },
    variable:{
        name: "variable",
        placeholder: "Select",
        label: "Variables",
        type: FIELD_TYPES.DROP_DOWN,
        isMandatory:true
      },
    operator:{
        name: "operator",
        placeholder: "Select",
        label: "Operator",
        type: FIELD_TYPES.DROP_DOWN,
        values:[{label:"==",value:"=="},
                {label:"!=",value:"!="},
                {label:"<=",value:"<="},
                {label:"=<",value:"=<"}],
        isMandatory:true
      },
    uservalue:{
        name: "uservalue",
        placeholder: "Select",
        label: "User Value",
        type: FIELD_TYPES.DROP_DOWN,
        isMandatory:true
      },
      logicoperator:{
        name: "logicoperator",
        placeholder: "Select",
        label: "Logical Operator",
        type: FIELD_TYPES.DROP_DOWN,
         values:[{label:"AND",value:"AND"},
                {label:"OR",value:"OR"}],
        isMandatory:true
      },
  };


export const SearchPeopleForm ={
    searchby: {name: "searchvariables",
    placeholder: "Search",
    label: "",
    type: FIELD_TYPES.DROP_DOWN,
    values: [           
        {label: 'All', value: 'all'},
        {label: 'Mentors', value: 'mentors'}
    ]
},
}

export const ProjectList = {
    fieldType: {
        name: "project",
        placeholder: "Select",
        label: "Select Project",
        type: FIELD_TYPES.DROP_DOWN,
        isMandatory: true,
        isListed: true
    }
}


export const DocTypeList = {
    fieldType: {
        name: "doctypelist",
        placeholder: "Select",
        label: "Select DocType",
        type: FIELD_TYPES.DROP_DOWN,
        isMandatory: true,
        isListed: true
    }
}

export const ReasonFormElements = {
    reason: {
        name: "reason",
        placeholder: "Enter",
        label: "Modify Reason",
        isMandatory: true
    }
}


