import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { setLogOut } from '../../../store/slices/auth';

export default function SideMenu(props: any) {
    // Navigation
    const navigate = useNavigate();
    const menus = [
        {
        name: 'Dashboard',
        icon: 'icon icon-dashboard',
        path: 'dashboard'
    },
    {
        name: 'Profile',
        icon: 'icon icon-mentor',
        path: 'profile'
    },{
        name: 'Projects',
        icon: 'icon icon-project',
        path: 'project'
    },{
        name: 'Learning',
        icon: 'icon icon-learning',
        path: 'learning'
    },{
        name: 'Subscription',
        icon: 'icon icon-subscription',
        path: 'subscriptions'
    },{
        name: 'Topics Library',
        icon: 'icon icon-topics',
        path: 'topics'
    },{
        name: 'Event Invites',
        icon: 'icon icon-topics',
        path: 'submissions'
    },{
        name: 'Journal Search',
        icon: 'icon icon-journals',
        path: 'journal'
    }
    // ,{
    //     name: 'Events',
    //     icon: 'icon icon-events',
    //     path: 'events'
    // }
    ,{
        name: 'Logout',
        icon: 'icon icon-logout',
        path: 'login'
    }];

      const mobilemenus = [
    //     {
    //     name: 'Dashboard',
    //     icon: 'icon icon-dashboard',
    //     path: 'dashboard'
    // },
    {
        name: 'Profile',
        icon: 'icon icon-mentor',
        path: 'profilemobile'
    },{
        name: 'Projects',
        icon: 'icon icon-project',
        path: 'projectmobile'
    },{
        name: 'Learning',
        icon: 'icon icon-learning',
        path: 'learning'
    }
    // ,{
    //     name: 'Events',
    //     icon: 'icon icon-events',
    //     path: 'events'
    // }
     ,{
        name: 'Event Invites',
        icon: 'icon icon-events',
        path: 'submissions'
    },
    {
        name: 'Logout',
        icon: 'icon icon-logout',
        path: 'login'
    }
    ];

    const [currentRoute, setCurrentRoute] = useState(window.innerWidth > 700 ? 'profile' : 'projectmobile');
    const dispatch = useDispatch();
    const handleroute = (objelm) => {
        if(objelm.path === 'login') {
            localStorage.removeItem("coguide_token");
			localStorage.removeItem("coguide_expire");
            localStorage.removeItem('coguide_user');
            dispatch(setLogOut());
        }
        navigate('/'+objelm.path);
        setCurrentRoute(objelm.path);
        console.log(objelm.path)
    }

    useEffect(()=>{
        navigate('/'+currentRoute);
    },[])
    return (
        <>  
        {window.innerWidth > 700 ?
        
    <div className='side-menu col-2'>
            <div className="menu-logo" >
                <img src={`${process.env.PUBLIC_URL}/images/coguide_main_logo.png`} alt={'logo'} />
            </div>
            {menus.map((obj, index) => (
                <div className={`menu-items ${obj.path === currentRoute ? "active": ""}`} key={index} onClick={() => handleroute(obj)}>
                    <div className='icon-div'>
                        <i className={obj.icon}></i>
                    </div>
                    <div className='menu-text' >{obj.name}</div>
                </div>
            ))}
            {/* <div className='' onClick={() => navigate('/login')}>logout</div> */}
        </div> 
        
        : 

        <div className='bottom-nav'>                
            {mobilemenus.map((obj, index) => (
                <div className={`menu-items ${obj.path === currentRoute ? "active": ""}`} key={index} onClick={() => handleroute(obj)}>
                    <div className='icon-div'>
                        <i className={obj.icon}></i>
                    </div>
                    {/* <div className='menu-text' >{obj.name}</div> */}
                </div>
            ))}
        </div>
        }

        </>
    )
}
