import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

const BoxPlot = ({ chartstyle,data,chartdots,chartdatastyle,title,titlecolor,width,height,yaxislabelstyle,xaxislabelstyle,gridcolor,gridstatus,yaxisstyle,xaxisstyle,xaxistext,yaxistext }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    // Clear previous content
   d3.select(svgRef.current).selectAll('*').remove();

 // Create tooltip
 const tooltip = d3
 .select(svgRef.current)
 .append('div')
 .style('position', 'absolute')
 .style('background', '#f4f4f4')
 .style('padding', '5px')
 .style('border', '1px solid #ccc')
 .style('border-radius', '5px')
 .style('opacity', 0);

    // Set up dimensions
    const margin = { top: 20, right: 20, bottom: 50, left: 50 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Create SVG container
    const svg = d3
    .select(svgRef.current)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .style("overflow", "visible")
    .attr('transform', `translate(${margin.left * 6},${margin.top + margin.top})`)
   ;

    // Create scales
    const xScale = d3.scaleBand()
      .domain(data.map((d, i) => i))
      .range([margin.left, innerWidth + margin.left])
      .padding(0.1);

    const yScale = d3.scaleLinear()
      .domain([0, d3.max(data, group => d3.max(group))])
      .range([innerHeight + margin.top, margin.top]);

    // Draw boxplots
    svg.selectAll('g')
      .data(data)
      .enter()
      .append('g')
      .attr('transform', (d, i) => `translate(${xScale(i)},0)`)
      .each(function (group, i) {
        const boxHeight = Math.abs(yScale(d3.quantile(group, 0.25)) - yScale(d3.quantile(group, 0.75)));

        //box
        d3.select(this)
          .append('rect')
          .attr('x', 0)
          .attr('y', yScale(d3.quantile(group, 0.75)))
          .attr('width', xScale.bandwidth())
          .attr('height', boxHeight)
          .attr('border','1px solid')
          .attr('fill', chartstyle.color);

   

        //median line
        d3.select(this)
          .append('line')
          .attr('x1', xScale.bandwidth()/10-10)
          .attr('y1', yScale(d3.quantile(group, 0.5)))
          .attr('x2', xScale.bandwidth())
          .attr('y2', yScale(d3.quantile(group, 0.5)))
          .attr('stroke', 'black');

        //upper line whisker
        d3.select(this)
          .append('line')
          .attr('x1', xScale.bandwidth() / 2)
          .attr('y1', yScale(d3.quantile(group, 0.75)))
          .attr('x2', xScale.bandwidth() / 2)
          .attr('y2', yScale(d3.max(group)))
          .attr('stroke', 'black');

          //lower whisker
        d3.select(this)
          .append('line')
          .attr('x1', xScale.bandwidth() / 2)
          .attr('y1', yScale(d3.quantile(group, 0.25)))
          .attr('x2', xScale.bandwidth() / 2)
          .attr('y2', yScale(d3.min(group)))
          .attr('stroke', 'black');

          //Add fence
          d3.select(this)
        .append('line') // Upper fence
        .attr('x1', xScale.bandwidth() / 2 - 10)
        .attr('y1', yScale(d3.max(group)))
        .attr('x2', xScale.bandwidth() / 2 + 10)
        .attr('y2', yScale(d3.max(group)))
        .attr('stroke', 'red')
        .attr('stroke-dash', '4');

          d3.select(this)
          .append('line') // Lower fence
          .attr('x1', xScale.bandwidth() / 2 - 10)
          .attr('y1', yScale(d3.min(group)))
          .attr('x2', xScale.bandwidth() / 2 + 10)
          .attr('y2', yScale(d3.min(group)))
          .attr('stroke', 'red')
          .attr('stroke-dash', '3');


       // Scatter plot for individual data points
    d3.select(this)
    .selectAll('circle')
    .data(group)
    .enter()
    .append('circle')
    .attr('cx', xScale.bandwidth() / 2)
    .attr('cy', d => yScale(d))
    .attr('r', chartdots.radius) // Adjust the radius as needed
    .attr('fill', chartdots.color);
    // .on("mouseover",function(d){
     
    //     tooltip.transition().duration(900).style('opacity', 0.9);
    //     tooltip
    //       .html(`<strong>Value:</strong> ${d.target.__data__}`)
    //       .style('left', `${d.pageX}px`)
    //       .style('top', `${d.pageY - 28}px`);

    //   })
    //   .on("mouseout",function(d){
    //     console.log("ddd")

    //       //remove tooltip
    //       tooltip.transition().duration(500).style('opacity', 0);
    //     });

             
      });


    // Add axes
    const xAxis = d3.axisBottom(xScale);
    const yAxis = d3.axisLeft(yScale);

    svg.append('g')
      .attr('transform', `translate(0,${innerHeight + margin.top})`)
      .attr('style',`  white-space: nowrap; overflow: hidden;  text-overflow: ellipsis; color:${xaxislabelstyle.color};
      textfont-weight: ${xaxislabelstyle.fontweight}; font-size:${xaxislabelstyle.fontsize}`)
      .call(xAxis);

    svg.append('g')
      .attr('transform', `translate(${margin.left},0)`)
      .attr('style',`  white-space: nowrap; overflow: hidden;  text-overflow: ellipsis; color:${yaxislabelstyle.color};
      textfont-weight: ${yaxislabelstyle.fontweight}; font-size:${yaxislabelstyle.fontsize}`)
      .call(yAxis);

      //add x axis label
      svg.append('text')
      .attr("class", "x label")
      .attr("text-anchor", "middle")
      .attr("x", width/2)
      .attr("y", height - 2)
      .attr("dy", xaxisstyle.dy)
      .attr("dx",width/10 - margin.left - margin.left)
      .style('fill',xaxisstyle.color)
      .style('font-weight',xaxisstyle.fontweight)
      .style('font-size',xaxisstyle.fontsize)
      .text(xaxistext);
 
      //add y axis label
      svg.append("text")
      .attr("class", "y label")
      .attr('transform', `translate(20)`)
      .attr("text-anchor", "end")
      .attr("y", 6)
      .attr("dy", yaxisstyle.dy)
      .attr("x",-height/3)
      .attr("transform", "rotate(-90)")
      .style('fill',yaxisstyle.color)
      .style('font-weight',yaxisstyle.fontweight)
      .style('font-size',yaxisstyle.fontsize)
      .text(yaxistext);

    //title of the chart
     svg.append('text')
     .attr('class', 'title')
     .attr('x', width / 2)
     .attr('y', -margin.top)
     .attr('text-anchor', 'middle')
     .style('font-size',titlecolor.fontsize)
     .style('font-weight',titlecolor.fontweight)
     .style('fill',titlecolor.color)
     .text(title)

  }, [data]);

  return <div ref={svgRef}></div>;
};

BoxPlot.prototype={
  data:PropTypes.oneOfType([PropTypes.array,PropTypes.object]),
  width:PropTypes.number,
  heigth:PropTypes.number,
  chartstyle:PropTypes.object,
  chartdatastyle:PropTypes.object,
  chartdots:PropTypes.object,
  gridcolor:PropTypes.string,
  gridstatus:PropTypes.string,
  yaxistext:PropTypes.string,
  yaxislabelstyle:PropTypes.object,
  yaxisstyle:PropTypes.object,
  xaxistext:PropTypes.string,
  xaxislabelstyle:PropTypes.object,
  xaxisstyle:PropTypes.object,
  title:PropTypes.string,
  titlecolor:PropTypes.object
}

BoxPlot.defaultProps ={
  data:[
    [1, 2, 3, 4, 5,6,7,8,9,12,13],
    [2, 3, 4, 5, 6,12,14,15,16,17]],
  width:"600", 
  height:"400", 
  chartstyle:{color:"#000",hover:"#ccc"},
  chartdatastyle:{fontsize:"16px",fontweight:"500",color:"#000"},
  chartdots:{color:"#000",radius:0},
  gridcolor:"#ccc",
  gridstatus:"hidden",
  yaxistext:"Y Label",
  yaxislabelstyle:{fontsize:"14px",fontweight:"400",color:"red"},
  yaxisstyle:{fontsize:"14px",fontweight:"600",color:"#000"},
  xaxistext:"X Label",
  xaxislabelstyle:{fontsize:"14px",fontweight:"400",color:"red"},
  xaxisstyle:{fontsize:"14px",fontweight:"600",color:"#000"},
  title:"Bar Chart",
  titlecolor:{fontsize:"24px",fontweight:"600",color:"#000"}
}

export default BoxPlot;
