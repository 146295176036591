import AjaxUtil from "../../axios/axios";

/**
 * get submissin list data
 * @param {*} id 
 * @returns 
 */
export const getPieactive = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`pieactive/${id}/20/1`, {}, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}


/**
 * get the data with respective project event id
 */
 export const getProjectEventDetail = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`schedule/${id}`, {}, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * register event api
 */
 export const RegisterEvent = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`registerproject/${data.scheduleId}/${data.topicid}/${data.pieactiveid}`, {}, false, { method: 'post', showError: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get registered projects
 */
 export const getRegisteredProjects = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`registeredproject/${id}`, {}, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}


/**
 * get doctypes data
 */
export const getDoctypes = async() =>{    
    try {
        return await AjaxUtil.sendRequest(`doctypes`, {}, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * Upload file
 */
 export const uploadSelectedFile = async(type,data) => {
    try {
        return await AjaxUtil.sendRequest(`rmsupload/${type}`, data, false, { method: 'post', showError: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * update document
 */
 export const uploadDocument = async(data,id) => {
    try {
        return await AjaxUtil.sendRequest(`updatedoc/${id}`, data, false, { method: 'put', showError: true} );
    } catch (error) {
        throw error;
    }
}
