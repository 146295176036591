import AjaxUtil from '../../axios/axios';

/**
 * get user types 
*/
export const getUserTypes = async() => {
    try {
        return await AjaxUtil.sendRequest(`types`, {}, false, { method: 'get', showError:true, isProfile:true});
    } catch (error) {
        throw error;
    }
}

/**
 * get users list to invite
 * list of users exist on database
 */
 export const getUsersList = async(search_key,pageno,perpage) => {
    try {
        return await AjaxUtil.sendRequest(`search?search_key=${search_key}&per_page=${perpage}&page_no=${pageno}`, {}, false, { method: 'get', showError:true, isProfile:true});
    } catch (error) {
        throw error;
    }
 }

 /**
  * send user invite
  */
  export const sendUserInvite = async(data) => {
    data.project_id = data.project_id?.projectid || data.project_id;
    try {
        return await AjaxUtil.sendRequest(`invite`, data, false, { method: 'post', showError:true, isProfile:true});
    } catch (error) {
        throw error;
    }
  }

 /**
 * get project team list 
 */
 export const getProjectTeamList = async(projectid,pageno,perpage,searchkey,status,role) => {
    try {
        if(projectid.type == "invitee"){
            status = "accepted"
            return await AjaxUtil.sendRequest(`invites?page_no=${pageno}&per_page=${perpage}&project_id=${projectid.projectid}&status=${status}&role=${role}`, {}, false, { method: 'get', showError:true, isProfile:true});     
        }else{
            return await AjaxUtil.sendRequest(`project/team?project_id=${projectid.projectid}&page_no=${pageno}&per_page=${perpage}&search_key=${searchkey}&status=${status}&role=${role}`, {}, false, { method: 'get', showError:true, isProfile:true});
        }
    } catch (error) {
        throw error;
    }
 }


  /**
  * send user message
  */
   export const sendMessage = async(messageTxt,fromId,toId,projectId) => {
    let data = {
        "message": messageTxt,
        "to_user_id": toId,
        "project_id": projectId
    };
    try {
        return await AjaxUtil.sendRequest(`message`, data, false, { method: 'post', showError:true, isProfile:true});
    } catch (error) {
        throw error;
    }
  }


 /**
 * get chat history 
 */
 export const getChatHistory = async(projectid,pageno,perpage,chat_with) => {
    console.log(projectid)
    try {
        return await AjaxUtil.sendRequest(`messages?project_id=${projectid}&page_no=${pageno}&per_page=${perpage}&chat_with=${chat_with}`, {}, false, { method: 'get', showError:true, isProfile:true});
    } catch (error) {
        throw error;
    }
 }

 /**
  * get invites
  */
 export const getInvitesReceived = async(pageno,perpage) =>{
    try {
        return await AjaxUtil.sendRequest(`invites?page_no=${pageno}&per_page=${perpage}&status=sent`, {}, false, { method: 'get', showError:true, isProfile:true});     
    } catch (error) {
        throw(error)
    }
 }


 /**
  * update invite status
  */
  export const updateInvitesStatus = async(id,data) =>{
    try {
        return await AjaxUtil.sendRequest(`invite/`+id, data, false, { method: 'put', showError:true, isProfile:true});     
    } catch (error) {
        throw(error)
    }
 }