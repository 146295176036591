import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { setLoading } from '../../store/slices/auth';
import { showToaster, toasterTypes } from '../../widgets';
import { FIELD_TYPES } from '../../widgets/fields';
import ModalPopup from '../../widgets/modal';
import { getNextQuestion, getSurveyFormData, submitForm, validateForm } from './apiController';
import Authenticators from './Authenticators';
import SurveyQuestions from './SurveyQuestions';

export default function SurveyForm() {
    var greetText='';
    if (new Date().getHours() < 12) {
        greetText = 'Good Morning';
    } else if (new Date().getHours() < 18) {
        greetText = 'Good Afternoon';
    } else {
        greetText = 'Good Evening';
    }
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setformData] = useState<any | null>(null);
    const [surveyData, setsurveyData] = useState<any | null>(null);
    const [isSurvey, setisSurvey] = useState(false);
    const [page, setpage] = useState(0);
    const [formElements, setformElements] = useState<any | {}>({});
    const [isSubmitted, setisSubmitted] = useState(false);
    const [authenticatorData, setauthenticatorData] = useState([]);
    const [isSubmit, setisSubmit] = useState(false);
    useEffect(() => {
        if (location.state) {
            let tempState: any = location.state;
            if (tempState.includes('formId')) {
                const params = new URLSearchParams(tempState);
                fetchFormData(params.get('formId'));
            } else {
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }, []);

    const getFieldType = (type) => {
        switch (type) {
            case 'textArea':
                return 'TEXT_AREA';
            case 'dropDown':
                return 'DROP_DOWN';  
            case 'radioButton':
                return 'RADIO_BUTTON';
            case 'checkBox':
                return 'CHECK_BOX';
            case 'dateField':
                return 'DATEPICKER_NEW';
            case 'fileUpload':
                return 'FILE_UPLOAD';
            case 'boolean':
                return 'BOOLEAN';
            default:
                return 'TEXT';
        }
    }

    const fetchFormData = async(formId) => {
        try {
            dispatch(setLoading(true));
            let response = await getSurveyFormData(formId);
            if(!response.data.form_fields) {
                showToaster(toasterTypes.ERROR, 'Form Configuration Issue')
            } else if(response.data.form_type !== 'root' && response.data.form_fields[0].field_use_type === 'Authenticator') {
                setformData(response?.data || null);
                let temp: any = cloneDeep(formElements);
                response.data.form_fields.forEach(obj => {
                    obj.field_selected_value = obj.field_type === 'checkbox' ? [] : obj.field_type === 'boolean' ? false : ''
                    temp[obj.id] = {
                        name: obj.id,
                        placeholder: obj.field_type === 'textBox' || obj.field_type === 'textArea' ? "Enter" : "Select",
                        label: obj.field_name,
                        type: FIELD_TYPES[getFieldType(obj.field_type)],
                        isMandatory: true
                    }
                    if(obj.field_type === 'number') {
                        temp[obj.id].regex = /^[0-9]*$/;
                    } else if(obj.field_type === 'radioButton' || obj.field_type === 'checkBox' || obj.field_type === 'dropDown') {
                        temp[obj.id].isListed = true;
                        let tempArray: any = [];
                        obj.field_value.forEach((tempObj, index) => {
                            tempArray.push({
                                label: tempObj,
                                value: index
                            });
                        });
                        temp[obj.id].field_value = tempArray;
                    }
                });
                setpage(1);
                setformElements(temp);
                setisSurvey(false);
            } else {
                setisSurvey(true);
                setpage(0);
                setsurveyData(response?.data || []);
            }
            dispatch(setLoading(false));
        } catch(e: any) {
            dispatch(setLoading(false));
        }
    }

    const fetchSurveyData = async(formId, data) => {
        try {
            dispatch(setLoading(true));
            let fieldId: any = '';
            let answer: any = '';
            data.forEach(elem => {
                console.log(elem)
                fieldId = fieldId + elem.field_id + ',';
                answer = answer + elem.value + ',';
            });
            fieldId = fieldId.substring(0, fieldId.length - 1);
            answer = answer.substring(0, answer.length - 1);
            let response = await getNextQuestion(formId, fieldId, answer);
            let temp: any = {
                ...formData,
                form_fields: response?.data || []
            }
            setpage(0);
            setsurveyData(temp || null);
            setisSurvey(true);
            dispatch(setLoading(false));
        } catch(e: any) {
            if(e?.response?.data?.data === null) {
                setisSubmit(true);
            }
            dispatch(setLoading(false));
        }
    }

    const submitSurvey = async() => {
		try {
            dispatch(setLoading(true));
			let fieldsArray: any = [];
			let tempAuthData: any = cloneDeep(authenticatorData);
			tempAuthData.forEach(obj => {
				if(obj.field_type === 'dropDown') {
					obj.field_selected_value = obj.field_selected_value.value
				}
				fieldsArray.push({
					id: obj.id || obj.fieldId,
					field_selected_value: obj.field_selected_value
				})
			});
            let tempState: any = location.state;
            if (tempState.includes('formId')) {
                const params = new URLSearchParams(tempState);
                let reqObj = {
                    "form_id": params.get('formId'),
                    "version_id": Date.now(), 
                    "fields": fieldsArray
                }
                let response = await submitForm(reqObj);
                if(response.success) {
                    showToaster(toasterTypes.SUCCESS, response.message);
                    setisSubmit(false);
                    setisSubmitted(true);
                }
            }
            dispatch(setLoading(false));
        } catch(e: any) {
            dispatch(setLoading(false));
        }
	}

    async function takeSurvey(data) {
        let tempState: any = location.state;
        if (tempState.includes('formId')) {
            const params = new URLSearchParams(tempState);
            try {
                dispatch(setLoading(true));
                let reqObj: any = [];
                data.forEach(element => {
                    reqObj.push({
                        "field_id": element.id,
                        "value": element.field_selected_value
                    })
                });
                let response = await validateForm(data[0].source_form_id, reqObj);
                if(response.success) {
                    setauthenticatorData(data);
                    fetchSurveyData(params.get('formId'), reqObj);
                }
                dispatch(setLoading(false));
            } catch(e: any) {
                dispatch(setLoading(false));
            }
        }
    }

    return (
        (formData?.form_fields.length > 0 || surveyData?.form_fields.length > 0) ?
            <>
                {isSubmit &&
                    <ModalPopup
                        modalType='delete'
                        modalText="There are no more actions as per the answers submitted."
                        actionText="Do you want to submit the form?"
                        closeModal={() => setisSubmit(false) }
                        onAction={() => submitSurvey()}
                    />
                }
                {isSubmitted ?
                    <div className='survey-form'>
                        <Row className='m-0 mb-3'>
                            <Col className='p-0' lg={6} md={12} xs={12}>
                                <div className='welcome-text'>Welcome</div>
                                <div className='survey-title'>{formData.form_name}</div>
                                <div className='survey-description'>{formData.form_desc}</div>
                            </Col>
                            <Col className='p-0' lg={6} md={12} xs={12}>
                                <img className='survey-tool' src={`${process.env.PUBLIC_URL}/images/survey_tools.svg`} alt='logo' />
                            </Col>
                        </Row>
                        <div className='questions-container row flex-column m-0 justify-content-center align-items-center pt-0'>
                            <img src={`${process.env.PUBLIC_URL}/images/awesome.svg`} alt="awesome" className="awesome-img" />
                            <div className='completed-text1'>Successfully submitted your form.</div>
                        </div>
                    </div> : isSurvey ?
                    <SurveyQuestions page={page} formData={surveyData} authenticatorData={authenticatorData} onSubmit={() => setisSurvey(false)} /> :
                    <Authenticators formData={formData} greetText={greetText} formElements={formElements} takeSurvey={(data) => takeSurvey(data)} />
                }
            </> :
        null
    )
}
