import React from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

interface ToasterProps {
  autoClose?: number;
  hideProgressBar?: boolean;
  closeOnClick?: boolean;
  pauseOnHover?: boolean;
  draggable?: boolean;
  type?: toasterTypes;
  position?: toasterPositions;
}

enum toasterTypes {
  INFO,
  SUCCESS,
  WARNING,
  ERROR,
  DEFAULT,
  DARK,
}

enum toasterPositions {
  "TOP-RIGHT" = "top-right",
  "TOP-LEFT" = "top-left",
  "TOP-CENTER" = "top-center",
  "BOTTOM-RIGHT" = "bottom-right",
  "BOTTOM-LEFT" = "bottom-left",
  "BOTTOM-CENTER" = "bottom-center",
}

const showToaster = (type: toasterTypes, message: string) => {
  switch (type) {
    case toasterTypes.INFO:
      toast.info(message);
      break;
    case toasterTypes.SUCCESS:
      toast.success(message);
      break;
    case toasterTypes.WARNING:
      toast.warn(message);
      break;
    case toasterTypes.ERROR:
      toast.error(message);
      break;
    case toasterTypes.DARK:
      toast.dark(message);
      break;
    default:
      toast(message);
      break;
  }
};
const Toaster: React.FunctionComponent<ToasterProps> = (props) => {
  return (
    <div className="custom-toast">
      <ToastContainer
        position={props.position}
        autoClose={props.autoClose}
        hideProgressBar={props.hideProgressBar}
        newestOnTop={false}
        closeOnClick={props.closeOnClick}
        rtl={false}
        pauseOnFocusLoss
        draggable={props.draggable}
        pauseOnHover={props.pauseOnHover}
      />
    </div>
  );
};

Toaster.defaultProps = {
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  type: toasterTypes.ERROR,
  position: toasterPositions["TOP-LEFT"],
};

export { Toaster, toasterPositions, toasterTypes, showToaster };
