// ScatterPlot.js
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const ScatterPlot = ({ chartstyle,data,chartdatastyle,title,titlecolor,width,height,yaxislabelstyle,xaxislabelstyle,gridcolor,gridstatus,yaxisstyle,xaxisstyle,xaxistext,yaxistext }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const width = 600 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    const svg = d3
    .select(svgRef.current)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .style("overflow", "visible")
    .style('fill',chartstyle.color)
    .attr('transform', `translate(${margin.left * 6},${margin.top + margin.top})`)
   ;
    // Create scales
    //added + 10 to yaxis to change the axis scale values
    const xScale = d3.scaleLinear().domain([0, d3.max(data, d => d.x+10)]).range([0, width]);
    const yScale = d3.scaleLinear().domain([0, d3.max(data, d => d.y+10)]).range([height, 0]);

    // Create axes
    const xAxis = d3.axisBottom(xScale);
    const yAxis = d3.axisLeft(yScale);

    const line = svg.append('line')
    .attr('stroke', chartstyle.linecolor)
    .style("stroke-dasharray", "5 5")
    .attr('stroke-width', 2);

    const linehorizontal = svg.append('line')
    .attr('stroke', chartstyle.linecolor)
    .style("stroke-dasharray", "5 5")
    .attr('stroke-width', 2);

    // Draw gridlines
    svg
    .append('g')
    .attr('class', 'grid')
    .style('color',gridcolor)
    .attr('transform', `translate(2,${height})`)
    .style("stroke-dasharray", "5 5")
    .style("visibility",gridstatus)
    .call(d3.axisBottom(xScale).tickSize(-height).tickFormat(''));

    svg
      .append('g')
      .attr('class', 'grid')
      .attr("transform", `translate(${1})`)   
      .style("stroke-dasharray", "5 5")
      .style("color",gridcolor)
      .style("visibility",gridstatus)
      .call(d3.axisLeft(yScale).tickSize(-width).tickFormat(''));


    // Create circles
    svg.selectAll('circle')
      .data(data)
      .enter().append('circle')
      .attr('cx', d => xScale(d.x))
      .attr('cy', d => yScale(d.y))
      .attr('r', chartstyle.dotradius)
      .attr('fill',chartstyle.color)
      .on("mouseenter",function(d){
        d3.select(d.currentTarget)
        .style("fill", chartstyle.hover)
          .transition()
          .duration(400)
          .attr('opacity', 0.6);

      
       // Show tooltip on mouseover
       tooltip.transition().duration(500).style('opacity', 0.9);
       tooltip.html(`${xaxistext} : ${d.target.__data__.x}<br>${yaxistext} : ${d.target.__data__.y}`)
         .style('left', `${d.pageX}px`)
         .style('top', `${d.pageY - 28}px`);
           
         const [x, y] = d3.pointer(d);


         // Update line coordinates to follow the mouse
        // Update line position
        line.attr('x1', x)
        .attr('y1', 0)
        .attr('x2', x)
        .attr('y2', height)
        .style('display', 'block'); 
  
        linehorizontal.attr('x1', 0)
        .attr('y1', y)
        .attr('x2', width)
        .attr('y2', y)
        .style('display', 'block');


        })
        .on("mouseout",function(d){
          d3.select(d.currentTarget)
          .style("fill", chartstyle.color)
            .transition()
            .duration(200)
            .attr('opacity',1)

             //remove tooltip
             tooltip.transition().duration(500).style('opacity', 0);
            
             
          });


           // Create tooltip
           const tooltip = d3
           .select(svgRef.current)
           .append('div')
           .style('position', 'absolute')
           .style('background', '#f4f4f4')
           .style('padding', '5px')
           .style('border', '1px solid #ccc')
           .style('border-radius', '5px')
           .style('opacity', 0);
  

    //add x axis label
    svg.append('text')
    .attr("class", "x label")
    .attr("text-anchor", "middle")
    .attr("x", width/2)
    .attr("y", height - 2)
    .attr("dy", xaxisstyle.dy)
    .attr("dx",width/10 - margin.left - margin.left)
    .style('fill',xaxisstyle.color)
    .style('font-weight',xaxisstyle.fontweight)
    .style('font-size',xaxisstyle.fontsize)
    .text(xaxistext);

    //add y axis label
    svg.append("text")
    .attr("class", "y label")
    .attr('transform', `translate(20)`)
    .attr("text-anchor", "end")
    .attr("y", 6)
    .attr("dy", "-6em")
    .attr("x",-height/3)
    .attr("transform", "rotate(-90)")
    .style('fill',yaxisstyle.color)
    .style('font-weight',yaxisstyle.fontweight)
    .style('font-size',yaxisstyle.fontsize)
    .text(yaxistext);


    svg.append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(xAxis);

    svg.append('g')
      .call(yAxis);

       //title of the chart
     svg.append('text')
     .attr('class', 'title')
     .attr('x', width / 2)
     .attr('y', -margin.top)
     .attr('text-anchor', 'middle')
     .style('font-size',titlecolor.fontsize)
     .style('font-weight',titlecolor.fontweight)
     .style('text-decoration', 'underline')
     .style('fill',titlecolor.color)
     .text(title)

     return () => {
        d3.select(svgRef.current).selectAll('*').remove();
      };
      
  }, [data]);

  return (
    <div ref={svgRef}></div>
  );
};

export default ScatterPlot;
